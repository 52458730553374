import React from "react";
import { useStyles } from "../styles";
import camerasIcon from "../../../assets/icons/camerasIcon.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import imageIcon from '../../../assets/icons/image.svg';
import { CircularProgress } from '@mui/material';

interface ICameraTile {
  name: string;
  room: string;
  id: number;
  live: boolean;
  image: any;
}

function CameraTile({
  name,
  room,
  id,
  live,
  image,
}: ICameraTile): JSX.Element {
  const { t } = useTranslation("common");
  const classes = useStyles();
  return (
    <Link to={`camera/${id}/liveView`} className={classes.Tile}>
      {live &&
      <div className={classes.StatusTile}>
        <span className={classes.RedDot}></span>
        {t("homepage.live")}
      </div>
      }

      <div className={classes.CameraViewWrapper}>
        {image &&
        <img src={`data:image/jpeg;base64,${image}`} alt="camera view mock" />
        }
        {!image &&
        <div className={classes.CameraNoImage}>
          <CircularProgress size={80}/>
        </div>
        }
      </div>

      <div className={classes.CameraViewDescWrapper}>
        <div className={classes.CameraViewIcon}>
          <img src={camerasIcon} alt="camera icon" />
        </div>
        <p className={classes.CameraViewDesc}>{name}</p>
      </div>
    </Link>
  );
}

export default CameraTile;
