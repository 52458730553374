import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
    InstanceIcon: {
        width: '40px',
        margin: '0 auto !important',
        borderRadius: '20px!important',
        '&:hover': {
            borderRadius: '8px!important'
        },
    },
    InstanceName: {
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '99px',
        paddingLeft: '3px',
        whiteSpace: 'nowrap',
    },
}));