import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import {useAppSelector} from "../../redux/hooks";

const ProtectedRoute = ({ children}:any) => {
  const { currentInstance } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();

  const getToken = () => {
    const token = localStorage.getItem("aiss-token");
    return !!token
  };
  const isAuth = getToken();

  return !isAuth ? <Navigate to="/login" /> :
      currentInstance ? children : (pathname.includes('instances') || pathname.includes('settings')) ?
          children : <Navigate to="instances" />;
};

export default ProtectedRoute;
