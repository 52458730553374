import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../../components/SettingsPageHeader";
import ConfirmButton from "../../../../components/ConfirmButton";
import UsersList from "../../../../components/List/UsersList";
import { AddUserModal } from "./Modals/AddUserModal";
import { CircularProgress } from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {SCOPES} from "../../../../components/PermissionsGate/permission-maps";
import PermissionsGate from "../../../../components/PermissionsGate";
import {fetchGroupUsersList} from "../../../../redux/reducers/groupUsers";

export function SettingsUsers(): JSX.Element {
  const { t } = useTranslation("common");
  const [isActiveAddUserModal, setIsActiveAddUserModal] = useState(false);
  const usersList = useAppSelector((state) => state.groupUsers.data);
  const {currentInstance} = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
      if (currentInstance?.id) {
          dispatch(fetchGroupUsersList(currentInstance?.id));
      }
  }, [currentInstance]);

  return (
    <>
      <PermissionsGate scopes={[SCOPES.admin]}>
        <div style={{ position: "absolute", right: "40px" }}>
          <ConfirmButton
            handleOnClick={setIsActiveAddUserModal}
            confirmButtonText={t("users.add")}
          />
        </div>
      </PermissionsGate>
      {usersList && usersList.length !== 0}
      <SettingsPageHeader
        title={
          usersList && usersList.length !== 0
            ? t("settings.users")
            : t("settings.noUsers")
        }
        description={
          usersList && usersList.length !== 0
            ? t("settings.description.users")
            : t("settings.description.noUsers")
        }
      />
      {usersList ? (
        usersList.length !== 0 ? (
          <UsersList
            tableContent={usersList}
            confirmButtonText={t("users.edit")}
          />
        ) : null
      ) : (
        <CircularProgress />
      )}

      {isActiveAddUserModal && (
        <AddUserModal groupID={currentInstance?.id} setIsModalActive={setIsActiveAddUserModal} />
      )}
    </>
  );
}
