
export type DailyStars = {
  hour: string,
  stars: number,
}

export type HourlyChart = {
  hour: number,
  value: number,
}

type Series = {
  label: string,
  data: DailyStars[]
}

export const cameraChartData3: Series[] = [
  {
    label: 'Krytyczne',
    data: [
      {
        hour: "1.09",
        stars: 3
      },
      {
        hour: "2.09",
        stars: 3
      },
      {
        hour: "3.09",
        stars: 3
      },
      {
        hour: "4.09",
        stars: 2
      },
      {
        hour: "5.09",
        stars: 2
      },
      {
        hour: "6.09",
        stars: 6
      },
      {
        hour: "7.09",
        stars: 0
      }
    ]
  },
  {
    label: 'Podstawowe',
    data: [
      {
        hour: "1.09",
        stars: 6
      },
      {
        hour: "2.09",
        stars: 2
      },
      {
        hour: "3.09",
        stars: 2
      },
      {
        hour: "4.09",
        stars: 1
      },
      {
        hour: "5.09",
        stars: 2
      },
      {
        hour: "6.09",
        stars: 1
      },
      {
        hour: "7.09",
        stars: 2
      }
    ]
  }
]
