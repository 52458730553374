import React, { useEffect, useState } from 'react';
import { useStyles } from "./style";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';

import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import SettingsGroup from "../../components/SettingsGroup";
import logoLong from "../../assets/logo/logo-aiss-long.png";
import ConfirmButton from "../../components/ConfirmButton";
import { useLocation } from "react-router-dom";
import CustomAlert from "../../components/CustomAlert";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {resetPassword} from "../../redux/reducers/auth";

interface IFormValues {
  password: string;
  confirmPassword: string;
  token: string | null;
}

interface ComponentProps extends FormikProps<IFormValues> {
  isLoading: boolean;
}

export function ResetPassword(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendingSuccess, setSendingSuccess] = useState<boolean>(false);
  const [sendingError, setSendingError] = useState<boolean>(false);
  const search = useLocation().search;

  return (
    <div className={classes.LogInPageWrapper}>
      <Box className={classes.ResetPasswordModalWrapper}>
        <Box className={classes.LogoLongWrap}>
          <img className={classes.LogoLong} src={logoLong} alt="logo" />
        </Box>
        <h3 className={classes.LogInTitle}>
          {t("resetPassword.restPassword")}
        </h3>
        <p className={classes.LogInDescription}>
          {t("settings.passwordResetModalDescription")}
        </p>

          {sendingSuccess &&
              <CustomAlert severity="success" title={t("logIn.success")}>
                  {t("resetPassword.resetPasswordSuccess")}
                  <div>
                      <a href="/login">{t("logIn.login")}</a>
                  </div>
              </CustomAlert>
          }
          {sendingError &&
              <CustomAlert severity="error" title={t("logIn.error")}>
                  {t("logIn.loginErrorUnknown")}
              </CustomAlert>
          }

        <Formik<IFormValues>
          initialValues={{
            password: "",
            confirmPassword: "",
            token: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .min(8, t("resetPassword.passwordLength"))
              .required(t("logIn.requiredValidation")),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], t("resetPassword.passwordMatch"))
              .required(t("logIn.requiredValidation")),
          })}
          onSubmit={async (values) => {
            setIsLoading(true)
            values.token = new URLSearchParams(search).get('token');
            const response = await dispatch(resetPassword(values));
            // @ts-ignore
            if(response) {
              setSendingSuccess(true)
              setSendingError(false)
            } else {
              setSendingError(true)
              setSendingSuccess(false)
            }
            setIsLoading(false)
          }}
          component={(props) => <SettingsProfileForm {...props} isLoading={isLoading} />}
        />
      </Box>
    </div>
  );
}

let SettingsProfileForm: (props: ComponentProps) => JSX.Element = ({
                                                                     handleSubmit,
                                                                     values,
                                                                     errors,
                                                                     touched,
                                                                     handleChange,
                                                                     handleBlur,
                                                                     isLoading
                                                                             }) => {
  const { t } = useTranslation("common");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const classes = useStyles();
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <SettingsGroup title={t("settings.newPassword")} noBorder>
        <OutlinedInput
          fullWidth
          name="password"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("settings.newPassword")}
          error={!!errors.password && touched.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!errors.password &&
        <FormHelperText id="password-helper-text">
          {t("settings.newPasswordHelpText")}
        </FormHelperText>
        }
        {touched.password && errors.password &&
        <div className={classes.InputError}>
          {errors.password}
        </div>
        }
      </SettingsGroup>

      <SettingsGroup title={t("settings.confirmNewPassword")} noBorder>
        <OutlinedInput
          fullWidth
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("settings.confirmNewPassword")}
          error={!!errors.confirmPassword && touched.confirmPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {touched.confirmPassword && errors.confirmPassword &&
        <div className={classes.InputError}>
          {errors.confirmPassword}
        </div>
        }
      </SettingsGroup>

      <Box style={{ marginTop: '20px', textAlign: 'right'}}>
        <ConfirmButton
        confirmButtonText={t("settings.reset")}
        width="150px"
        height="50px"
        loading={isLoading}
      />
      </Box>
    </form>
  );
};
