import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Tile } from "../../../components/Tile";
import { Outlet } from "react-router-dom";
import { RoundedButton } from "../../../components/RoundedButton/RoundedButton";
import arrowButton from "../../../assets/icons/arrowIcon.png";
import ConfirmButton from "../../../components/ConfirmButton";
import { InstancesNav } from "./InstancesNav";
import InstancesSelect from "../../../components/InstancesSelect";
import {useAppSelector} from "../../../redux/hooks";

export function Instance(): JSX.Element {
  const [chosenInstance, setChosenInstance] = useState(null);
  const groupsList = useAppSelector((state) => state.groups.data) || []

  useEffect(() => {
    groupsList &&
      groupsList.map((instance: any) => {
        if (window.location.pathname.includes(instance.id)) {
          setChosenInstance(instance);
        }
      });
  }, [window.location.pathname, groupsList]);

  return (
    <Box sx={{ p: 2, pt: 4 }}>
      <Grid style={{ display: "flex" }} item xs={12}>
        <Box>
          <RoundedButton icon={arrowButton} />
        </Box>
        {groupsList ? (
          <InstancesSelect
            chosenInstance={chosenInstance}
            groupsList={groupsList}
            disabledMenu={false}
          />
        ) : (
          <CircularProgress />
        )}
        <div style={{ position: "absolute", right: "40px" }}>
          <ConfirmButton
            handleOnClick={() => console.log("add")}
            confirmButtonText="Zaloguj się do instancji"
          />
        </div>
      </Grid>
      <Box>
        <Box>
          <InstancesNav />
        </Box>
        <Box>
          <Tile>
            <Box sx={{ p: 1 }}>
              <Outlet />
            </Box>
          </Tile>
        </Box>
      </Box>
    </Box>
  );
}
