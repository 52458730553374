import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
  SettingsWrap: {
    display: 'flex',
  },
  SettingsNav: {
    width: '250px',
  },
  SettingsContent: {
    flex: '1',
    marginLeft: '20px',
  },
  SettingsImageWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  SettingsImage: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  },
  SettingsImageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0 10px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  ButtonWrap: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  LogsWrapper: {
    backgroundColor: '#141414',
    minHeight: '65vh',
    borderRadius: '10px',
    padding: '20px',
  },
  Log: {
    color: '#fff',
    marginBottom: '20px',
    fontSize: '16px',
  },
  UserAvatar: {
    marginRight: '20px',
    height: '50px',
    width: '50px',
    borderRadius: '10px',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  },
  CellName: {
    color: "#636F7D",
    fontSize: "14px",
    marginRight: "5px",
    marginTop: "2px",
  },
  RemoveBTN: {
    backgroundColor: '#DFE4EA',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10px',
  },
  TableCell: {
    backgroundColor: "#F6F8FA",

    ' &:nth-child(1)': {
      borderTopLeftRadius: '18px',
      borderBottomLeftRadius: '18px',
      height: '100%',

    },
    ' &:last-child': {
      borderTopRightRadius: '18px',
      borderBottomRightRadius: '18px',
    },
  },
  AlertName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    '& img': {
      paddingRight: '10px',
    }
  },
  Pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  }
}));
