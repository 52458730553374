import React from 'react';
import { useStyles } from './styles';

interface IToggle {
  text: string,
  id?: string,
  isActive: boolean,
  onClick: any,
}

function Toggle({text, isActive, onClick, id}: IToggle) {
  const classes = useStyles();
  return (
    <div className={classes.ToggleWrap}>
      <input type="checkbox" id={id} onClick={onClick}/>
      <span className={isActive ? classes.Active : classes.InActive}/>
      <div>{text}</div>
    </div>
  );
}

export default Toggle;
