import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../styles';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { editCameraRoi, fetchCameraImage } from '../../../../redux/reducers/camera';
import { Alert } from '@mui/lab';
import { Button, LinearProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/system';
import { CanvasDrawing } from '../../../../components/CanvasDrawing';
import { CanvasEditing } from '../../../../components/CanvasEditing';

export interface IAddInstanceModal {
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  currentContour: number[][] | null;
  setCurrentContour: Dispatch<SetStateAction<number[][] | null>>;
}

export function DangerZoneDrawing({
                                    setIsModalActive,
                                    currentContour,
                                    setCurrentContour
                                  }: IAddInstanceModal): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // states
  const [cameraImage, setCameraImage] = useState<string>('');
  const [areaContour, setAreaContour] = useState<{x: number, y: number}[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState<{height: number | undefined, width: number | undefined}>({height: undefined, width: undefined});

  const camera = useAppSelector(state => state.camera.current);

  const cameraImageRef = useRef<HTMLImageElement>(null);
  const canvasEditingRef = useRef(null);

  const { t } = useTranslation("common");

  useEffect(() => {
    async function getImage() {
      const image = await dispatch(fetchCameraImage(0))
      setCameraImage(image)
    }
    getImage()
  }, [dispatch])

  useEffect(() => {
    if(cameraImage) {
      let image = new Image();
      image.src = `data:image/png;base64, ${cameraImage}`;
      image.onload = () => {
        setImageSize({
          height: image.height,
          width: image.width,
        })
      }

    }
  }, [cameraImage, cameraImageRef])

  useEffect(() => {
    if(currentContour) {
      const mapPoints = currentContour.map((data: number[]) => {return {x: data[0], y: data[1]}})
      setAreaContour(mapPoints)
    } else {
      setAreaContour(null)
    }
  }, [currentContour])

  const saveArea = async function() {
    setIsLoading(true)

    // @ts-ignore
    const contours = canvasEditingRef.current!.getContours();
    const points = contours.getObjects()[0].points;
    const mapPoints = points.map((point: {x: number, y: number}) => { return [point.x, point.y]})

    await dispatch(editCameraRoi(camera, mapPoints))
    setCurrentContour(mapPoints)
    setIsLoading(false)
    setIsModalActive(false)
  }

  const handleCanvasEdit = () => {
    if(canvasEditingRef){
      // @ts-ignore
      canvasEditingRef.current!.edit();
    }
  }
  return (
    <div className={classes.drawingWrap}>
      <div className={classes.drawingWrapBackground}>
        <div className={classes.cameraImageWrap}>
          {cameraImage &&
            <>
              <div className={classes.cameraCanvasWrap} style={{"display": `${areaContour ? 'none' : 'block'}`}}>
                <CanvasDrawing imageSize={imageSize} pointsLimit={4} updateAreaContour={setAreaContour}/>
              </div>
              <div className={classes.cameraCanvasWrap} style={{"display": `${areaContour ? 'block' : 'none'}`}}>
                <CanvasEditing ref={canvasEditingRef} imageSize={imageSize} contours={areaContour} />
              </div>
              <img ref={cameraImageRef} src={`data:image/jpeg;base64,${cameraImage}`} alt="camera" />
            </>
          }
          {!cameraImage &&
          <div className={classes.cameraImageLoading}>
            <div>
              {t("safetyAreas.imageLoading")}
            </div>
            <Box sx={{ width: '30%' }}>
              <LinearProgress />
            </Box>
          </div>
          }
        </div>
        <div className={classes.drawingToolbar}>
          <Button color="secondary" onClick={() => setIsModalActive(false)}>{t("safetyAreas.return")}</Button>
          <div>
            {areaContour &&
            <Button variant="outlined" color="secondary" onClick={() => handleCanvasEdit()}>{t("safetyAreas.editArea")}</Button>
            }
            {!areaContour &&
            <Alert severity="info" variant="filled">{t("safetyAreas.drawingInstruction")}</Alert>
            }
          </div>
          <LoadingButton loading={isLoading} disabled={!areaContour} variant="contained" color="success" onClick={() => saveArea()}>{t("safetyAreas.save")}</LoadingButton>
        </div>
      </div>
    </div>
  );
}
