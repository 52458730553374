import React from "react";
import { useStyles } from "./styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";

export interface IConfirmButton {
  confirmButtonBackgroundColor?: string;
  confirmButtonColor?: string;
  confirmButtonText: string;
  handleOnClick?: any;
  dark?: boolean;
  height?: string;
  width?: string;
  loading?: boolean;
  button?: boolean;
  disabled?: boolean;
}
function ConfirmButton({
  confirmButtonColor,
  confirmButtonText,
  confirmButtonBackgroundColor,
  handleOnClick,
  dark,
  button,
  width,
  height,
  loading,
  disabled,
}: IConfirmButton) {
  const classes = useStyles();

  return (
    <LoadingButton
      onClick={() => (handleOnClick ? handleOnClick(true) : "")}
      loading={loading}
      disabled={disabled}
      loadingPosition="center"
      loadingIndicator={<CircularProgress size={22} />}
      variant="contained"
      type={button ? "button" : "submit"}
      style={{
        width: width ? width : "",
        height: height ? height : "",
        color: confirmButtonColor,
        backgroundColor:
          confirmButtonBackgroundColor === "danger"
            ? "#FA6363"
            : dark
            ? "#F6F8FA"
            : confirmButtonBackgroundColor === "grey"
            ? "#DFE4EA"
            : "#2FB299",
      }}
      className={classes.ConfirmBTN}
    >
      {loading ? "" : confirmButtonText}
    </LoadingButton>
  );
}

export default ConfirmButton;
