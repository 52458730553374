import { Box } from "@mui/material";
import React from "react";
import { CamerasListMock } from "../../../mocks/CamerasListMock";
import { Rooms } from "../../../mocks/Rooms";
import CameraTile from "../CameraTile";
import { useStyles } from "../styles";
import { useAppSelector } from '../../../redux/hooks';

function CamerasListTiles(): JSX.Element {
  const classes = useStyles();
  const cameras = useAppSelector(state => state.camera.list)
  return (
    <Box sx={{ p: 2, pt: 4 }}>
      <div>

        <div className={classes.TilesWrapper}>
          {cameras && cameras.map((row: any) => (
            <CameraTile
              name={row.name}
              room={row.room}
              id={row.id}
              live={row.live_feed_enabled}
              image={row.image}
              key={row.id}
            />
          ))}
        </div>

        {/*// TODO - display cameras per room when BE ready*/}
        {/*{Rooms.map((item: any) => (*/}
        {/*  <div className={classes.TilesWrapper} key={item.id}>*/}
        {/*    <br/>*/}
        {/*    {item.name}*/}

        {/*    <div className={classes.TilesWrapper}>*/}
        {/*      {cameras && cameras.map((row: any) => (*/}
        {/*        <CameraTile*/}
        {/*          name={row.label}*/}
        {/*          room={row.room}*/}
        {/*          id={row.id}*/}
        {/*          danger={row.danger}*/}
        {/*          image={row.image}*/}
        {/*          key={row.id}*/}
        {/*        />*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*))}*/}
      </div>
    </Box>
  );
}

export default CamerasListTiles;
