export const ROLES = {
  user: "USER",
  instanceAdmin: "INSTANCE_ADMIN",
  globalAdmin: "GLOBAL_ADMIN",
};

export const SCOPES = {
  user: "user",
  instanceAdmin: "instance-admin",
  globalAdmin: "global-admin",
  admin: "admin",
};

export const PERMISSIONS = {
  [ROLES.user]: [SCOPES.user],
  [ROLES.instanceAdmin]: [SCOPES.instanceAdmin, SCOPES.admin],
  [ROLES.globalAdmin]: [SCOPES.globalAdmin, SCOPES.admin],
};
