import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  List: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 2px',
    '& a': {
      textDecoration: 'none',
      display: 'block',
      padding: '6px 12px',
      margin: '2px 0',
      fontSize: '18px',
      color: '#000',
      borderRadius: '4px',
      '&.active': {
        backgroundColor: '#F6F8FA',
      }
    }
  }
}));
