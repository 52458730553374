import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {useStyles} from "./styles";
import {useNavigate} from "react-router-dom";
import { Box, Button } from '@mui/material';
import warningIcon from "../../assets/icons/warning.svg";
import alertIcon from "../../assets/icons/alert.png";
import {toast} from "react-toastify";
import ConfirmButton from "../ConfirmButton";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {REACT_APP_BACKEND_URL} from "../../common/config";
import {getToken} from "../../common/helpers";
import MockWrapper from "../MockWrapper";
import alertSound from '../../assets/sound/alert.mp3';

// @ts-ignore
const Msg = ({alert, closeToast}) => {
    console.log(alert)
    const { currentInstance} = useAppSelector((state) => state.user);
    const {t} = useTranslation("common");
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleRedirectButton = () => {
        //if alert comes from instance different then the one that user is currently logged into change current instance and redirect user to cam view
        if (alert.instanceId !== currentInstance?.id) {
            //TO DO fix payload for saveCurrentInstance when BE is ready
            //dispatch(saveCurrentInstance({id: 14, label: 'słoniki'}));
            //dispatch(saveUserRole(getRole(userProfileData, currentUserGroups, alert.instanceId)));
        }
        navigate(`/camera/${alert.camera}/liveView`);
    }

    return (
      <Box className={classes.AlertWrap}>

          {/*TO DO show instance name if it's different from the one that user is currently logged into*/}

          {/*{alert.instance_id !== currentInstance?.id &&
            <Box className={classes.AlertInstance}>
                <div>{alert.instance_name}</div>
            </Box>
        }*/}
          <Box className={alert.color === 'critical' ? classes.AlertIcon : classes.AlertIconWarning}>
              <img src={alert.color === 'critical' ? alertIcon : warningIcon} alt="alert icon"/>
          </Box>
          <Box className={classes.AlertContent}>
              <Box className={classes.AlertHeader}>
                  <div>{t("camera.dangerDetected")} </div>
              </Box>
              <Box className={alert.color === 'critical' ? classes.AlertNameCritical : alert.color === 'warning' ? classes.AlertName : classes.AlertNameInfo}>{alert.type}</Box>

              <Box className={classes.AlertButtonsWrap}>
                  <Button
                    variant="text"
                    onClick={() => closeToast()}
                  >
                      {t("toastInfo.dismiss")}
                  </Button>
                  <ConfirmButton
                    confirmButtonText={t("toastInfo.checkCamView")}
                    confirmButtonColor="#000"
                    dark={false}
                    handleOnClick={() => handleRedirectButton()}
                  />
              </Box>
          </Box>
      </Box>
)
}

const AlertNotification = () => {
    const { latestAlert } = useAppSelector((state) => state.alerts);
    const [currentAlertId, setCurrentAlertId] = useState<number>(1);
    const playSounds = useAppSelector((state) => state.user.playSounds);

    const audio = new Audio(alertSound);

    const mockLatestAlert = latestAlert ? {
        ...latestAlert,
        color: 'warning',
    } : null;

    useEffect(() => {
        if (mockLatestAlert) {
            toast(<Msg alert={mockLatestAlert} closeToast={undefined}/>, {
                position: "bottom-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: false,
                toastId: currentAlertId,
                className: `alert-style ${mockLatestAlert.color}`,
            });
            if(currentAlertId > 3) {
                // remove old toast after some time to show on UI that something changed
                setTimeout(() => {
                    toast.dismiss(currentAlertId - 3)
                }, 500)
            }
            if (playSounds) {
                audio.muted = true;
                audio.muted = false;
                audio.play();
            }
            setCurrentAlertId(currentAlertId + 1)

        }
        },[latestAlert])

    return null
};

export default AlertNotification;
