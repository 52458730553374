import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../../pages/Home";
import { Settings } from "../../pages/Settings";
import { Alerts } from "../../pages/Alerts";
import { Cameras } from "../../pages/Cameras";
import { SettingsProfile } from "../../pages/Settings/Pages/profile";
import { SettingsPassword } from "../../pages/Settings/Pages/password";
import { SettingsUsers } from "../../pages/Settings/Pages/Users/users";
import { SettingsReports } from "../../pages/Settings/Pages/reports";
import { SettingsLogs } from "../../pages/Settings/Pages/logs";
import { SettingsCameras } from "../../pages/Settings/Pages/Cameras/cameras";
import { SettingsPayments } from "../../pages/Settings/Pages/payments";
import { SettingsPaymentsHistory } from "../../pages/Settings/Pages/paymentsHistory";
import { Instances } from "../../pages/Instances";
import { InstancesUsers } from "../../pages/Instances/Pages/users";
import { Instance } from "../../pages/Instances/Pages/instance";
import { InstancesInfo } from "../../pages/Instances/Pages/information";
import { InstancesCameras } from "../../pages/Instances/Pages/cameras";
import { LogIn } from "../../pages/LogIn/LogIn";
import { ForgotPassword } from "../../pages/LogIn/ForgotPassword";
import { ResetPassword } from "../../pages/LogIn/ResetPassword";
import ProtectedRoute from "../ProtectedRoute";
import AppLayout from "../AppLayout";
import { RecordDetails } from "../../pages/Cameras/tabs/RecordingDetails";
import { Camera } from "../../pages/Cameras/CameraDetails";
import { SettingsSystem } from '../../pages/Settings/Pages/system';
import NotFoundPage from "../../pages/NotFound";
import AlertsList from '../List/AlertsList';
import { AlertsStats } from '../../pages/Alerts/AlertsStats';
import LiveView from '../../pages/Cameras/tabs/LiveView';
import Recordings from '../../pages/Cameras/tabs/Recordings';
import DangerZones from '../../pages/Cameras/tabs/DangerZones';
import Heatmap from '../../pages/Cameras/tabs/Heatmap';

const Routing = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />}/>
      <Route path="login" element={<LogIn />} />
      <Route path="login/forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />

      <Route element={<ProtectedRoute children={<AppLayout />} />}>
        <Route path="/" element={<ProtectedRoute children={<Home />} />} />
        <Route
          path="/cameras"
          element={<ProtectedRoute children={<Cameras />} />}
        />

        <Route
          path="camera/:id"
          element={<ProtectedRoute children={<Camera />} />}
        >
          <Route
            path="liveView"
            element={<ProtectedRoute children={<LiveView />} />}
          />
          <Route
            path="recordings"
            element={<ProtectedRoute children={<Recordings />} />}
          />
          <Route
            path="dangerArea"
            element={<ProtectedRoute children={<DangerZones />} />}
          />
          <Route
              path="heatmap"
              element={<ProtectedRoute children={<Heatmap />} />}
          />
          <Route
            path="recordings/:recordingId"
            element={<ProtectedRoute children={<RecordDetails />} />}
          />
        </Route>

        <Route
          path="/alerts"
          element={<ProtectedRoute children={<Alerts />} />}
        >
          <Route
            path="list"
            element={<ProtectedRoute children={<AlertsList />} />}
          />
          <Route
            path="stats"
            element={<ProtectedRoute children={<AlertsStats />} />}
          />
        </Route>

        <Route
          path="/settings"
          element={<ProtectedRoute children={<Settings />} />}
        >
          <Route
            path="profile"
            element={<ProtectedRoute children={<SettingsProfile />} />}
          />
          <Route
            path="system"
            element={<ProtectedRoute children={<SettingsSystem />} />}
          />
          <Route
            path="password"
            element={<ProtectedRoute children={<SettingsPassword />} />}
          />
          <Route
            path="users"
            element={<ProtectedRoute children={<SettingsUsers />} />}
          />
          <Route
            path="reports"
            element={<ProtectedRoute children={<SettingsReports />} />}
          />
          <Route
            path="logs"
            element={<ProtectedRoute children={<SettingsLogs />} />}
          />
          <Route
            path="cameras"
            element={<ProtectedRoute children={<SettingsCameras />} />}
          />
          <Route
            path="payments"
            element={<ProtectedRoute children={<SettingsPayments />} />}
          />
          <Route
            path="payments-history"
            element={<ProtectedRoute children={<SettingsPaymentsHistory />} />}
          />
          <Route
            path="instance"
            element={<ProtectedRoute children={<InstancesInfo />} />}
          />
        </Route>
        <Route
            path="instances"
            element={<ProtectedRoute children={<Instances/>}/>}>
        </Route>
        <Route
          path="instance/:id"
          element={<ProtectedRoute children={<Instance />} />}
        >
          <Route
            path="users"
            element={<ProtectedRoute children={<InstancesUsers />} />}
          />
          <Route
            path="cameras"
            element={<ProtectedRoute children={<InstancesCameras />} />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default Routing;
