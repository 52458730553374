import { makeStyles } from '@mui/styles';

// @ts-ignore
export const useStyles = makeStyles(() => ({
    ConfirmBTN: {
        // backgroundColor: '#2FB299 !important',
        borderRadius: '50px !important',
        boxShadow: 'none !important',
        color: '#fff !important',
        textTransform: 'inherit !important',
        minWidth: '100px',
        padding: '0 8px',
        height: '30px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px !important',
    }
}));
