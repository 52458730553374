import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { CameraNav } from "./components/CameraNav";
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchCameraBirdseyeUrl,
  fetchCameraFeedUrl,
  fetchCameras,
  setCurrentCamera
} from '../../redux/reducers/camera';

type ContextType = {
  camera: any | null;
  cameraFeedUrl: string;
  cameraBirdseyeUrl: string;
  loading: boolean;
};

export function Camera(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [camera, setCamera] = useState<Object | null>(null);
  const [cameraFeedUrl, setCameraFeedUrl] = useState<string>('');
  const [cameraBirdseyeUrl, setCameraBirdseyeUrl] = useState<string>('');
  const cameras = useAppSelector(state => state.camera.list);

  let {id} = useParams();

  useEffect(() => {

    dispatch(setCurrentCamera(id));

    const fetchUrl = async () => {
      setLoading(true)
      const feedUrl = await dispatch(fetchCameraFeedUrl(0));
      setCameraFeedUrl(feedUrl)
      const birdseyeUrl = await dispatch(fetchCameraBirdseyeUrl(0));
      setCameraBirdseyeUrl(birdseyeUrl)
      setLoading(false)
    }

    const fetchAllCameras = async () => {
      const allCameras = await dispatch(fetchCameras())
      allCameras.map((camera: any) => {
        if (window.location.pathname.includes(camera.id)) {
          setCamera(camera);
        }
      });
    }

    if(!cameras) {
      fetchAllCameras()
    } else {
      cameras.map((camera: any) => {
        if (window.location.pathname.includes(camera.id)) {
          setCamera(camera);
        }
      });
    }

    fetchUrl();

  }, [window.location.pathname]);

  return (
    <Box className={classes.CameraWrap}>
      <CameraNav camera={camera} setCamera={setCamera} />
      <Box style={{ margin: "20px" }}>
        <Box>
          <Outlet context={{ camera, cameraFeedUrl, cameraBirdseyeUrl, loading }} />
        </Box>
      </Box>
    </Box>
  );
}

export function useCamera() {
  return useOutletContext<ContextType>();
}
