import { Box } from "@mui/system";
import React, { useEffect, useState } from 'react';
import { useStyles } from "../styles";
import { useTranslation } from "react-i18next";
import clock from "../../../assets/icons/clock.png";
import warning from "../../../assets/icons/warning.png";
import { Link, useParams } from 'react-router-dom';
import MockWrapper from '../../../components/MockWrapper';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CircularProgress, Pagination } from '@mui/material';
import { format } from 'date-fns';
import imageIcon from '../../../assets/icons/image.svg';
import { fetchAlertsBetween, fetchAlertsList } from '../../../redux/reducers/alerts';

function Recordings() {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const rowsPerPage = 20;
  const alerts = useAppSelector(state => state.alerts?.list?.notifications)
  const totalAlerts = useAppSelector(state => state.alerts?.list?.total_count)
  const [highestId, setHighestId] = useState<number | null>(null);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchAlertsList())
      setLoading(false);
    }
    fetch()
  }, [dispatch])

  const classes = useStyles();
  const {id} = useParams();
  const filteredAlerts = alerts ? alerts.filter(alert => alert.camera_id === Number(id)) : []

  useEffect(() => {
    if(!highestId){
      setHighestId(alerts ? alerts[0].id : null)
    }
  }, [alerts])

  const handleChangePage = (event: any, newPage: number) => {
    if(highestId) {
      const to =  highestId - ((newPage - 1) * rowsPerPage);
      const from = highestId - (newPage * rowsPerPage);
      dispatch(fetchAlertsBetween(from, to))
      setCurrentPage(newPage);
    }
  };

  return (
    <MockWrapper>
      <Box className={classes.RecordingsContainer}>
        { loading &&
          <div className={classes.ListLoader}>
            <CircularProgress size={60}/>
          </div>
        }
        {!loading && filteredAlerts.map((record) => (
          <Link to={`${record.id}`} className={classes.LinkToVideoWrapper} key={record.id}>
            <Box key={record.id} className={classes.RecordingWrapper}>
              <div className={classes.ImageWrapper}>
                {record.image &&
                  <>
                    <img className={classes.Image} src={`data:image/jpeg;base64,${record.image}`} alt="recording" />
                  </>
                }
                {!record.image &&
                <img src={imageIcon} alt="empty" />
                }
              </div>
              <div className={classes.DangerDescriptionLine}>
                <img src={warning} alt="warning icon" />
                <span
                  style={{
                    color: " #637081",
                    display: "inline-block",
                  }}
                >
                {t("camera.danger")}:&nbsp;
              </span>
                {record.metric}
              </div>
              <div className={classes.DangerDescriptionLine}>
                <img src={clock} alt="clock icon" /> {format(new Date(record.created_at), 'dd.MM.yyyy, HH:mm')}
              </div>
            </Box>
          </Link>
        ))}
      </Box>
      {!loading &&
      <Pagination
        count={totalAlerts ? Math.ceil(totalAlerts / rowsPerPage) : 0}
        page={currentPage}
        onChange={handleChangePage}
        showFirstButton
        showLastButton
        className={classes.Pagination}
      />
      }
    </MockWrapper>

  );
}

export default Recordings;
