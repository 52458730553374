import { Box } from "@mui/material";
import Navigation from "../Navigation";
import { Outlet } from 'react-router-dom';

export default function Index(): JSX.Element {
  return (
    <Box sx={{ display: "flex" }}>
      <Navigation />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet/>
      </Box>
    </Box>
  );
}
