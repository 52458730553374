import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../../components/SettingsPageHeader";
import ConfirmButton from "../../../../components/ConfirmButton";
import CamerasList from "../../../../components/List/CamerasList";
import { RemoveCameraModal } from "./Modals/RemoveCameraModal";
import { EditCameraModal } from "./Modals/EditCameraModal";
import { AddCameraModal } from "./Modals/AddCameraModal";
import MockWrapper from '../../../../components/MockWrapper';
import { CalibrateCamera } from './Modals/CalibrateCamera';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { fetchCameras, setCurrentCamera } from '../../../../redux/reducers/camera';
import { useStyles } from '../../styles';

export function SettingsCameras(): JSX.Element {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [isActiveRemoveCameraModal, setIsActiveRemoveCameraModal] =
    useState(false);
  const [isActiveEditCameraModal, setIsActiveEditCameraModal] = useState(false);
  const [isActiveAddCameraModal, setIsActiveAddCameraModal] = useState(false);
  const [isCalibratingActive, setIsCalibratingActive] = useState(false);
  const cameras = useAppSelector(state => state.camera.list);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true)
      await dispatch(fetchCameras())
      setIsLoading(false)
    }

    if(!cameras) {
      fetchList()
    }
  }, [])

  const openEditModal = (id: number) => {
    setIsActiveEditCameraModal(true)
    dispatch(setCurrentCamera(id))
  }

  return (
    <Box>
      <MockWrapper>
        <div style={{ position: "absolute", right: "40px" }}>
          <ConfirmButton
            handleOnClick={setIsActiveAddCameraModal}
            confirmButtonText={t("users.add")}
          />
        </div>
        <SettingsPageHeader
          title={t("settings.cameras")}
          description={t("cameras.camerasDescription")}
        />
        {isLoading &&
          <Box className={classes.Loader}>
            <CircularProgress size={80} color="secondary"/>
          </Box>
        }
        {!isLoading &&
        <CamerasList
          handleConfirmButton={openEditModal}
          handleRemoveButton={setIsActiveRemoveCameraModal}
          tableContent={cameras}
          confirmButtonText={t("users.edit")}
          dark={false}
        />
        }

        {isActiveRemoveCameraModal && (
          <RemoveCameraModal setIsModalActive={setIsActiveRemoveCameraModal} />
        )}

        {isActiveEditCameraModal && (
          <EditCameraModal setIsModalActive={setIsActiveEditCameraModal} setIsCalibratingActive={setIsCalibratingActive} />
        )}

        {isActiveAddCameraModal && (
          <AddCameraModal setIsModalActive={setIsActiveAddCameraModal} />
        )}

        {isCalibratingActive && (
          <CalibrateCamera setIsModalActive={setIsCalibratingActive} />
        )}
      </MockWrapper>
    </Box>
  );
}
