import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  CameraWrap: {
    display: 'flex',
    flexDirection: 'column'
  },
  CameraNav: {
    display: 'flex',
    '& button': {
      marginRight: '5px',
      position: 'relative',
      top: '1px',
    }
  },
  ButtonWrap: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '1010px',
    width: '100%',
  },
  LogsWrapper: {
    backgroundColor: '#141414',
    minHeight: '65vh',
    borderRadius: '10px',
    padding: '20px',
  },
  Log: {
    color: '#fff',
    marginBottom: '20px',
    fontSize: '16px',
  },
  UserAvatar: {
    marginRight: '20px',
  },
  RemoveBTN: {
    backgroundColor: '#DFE4EA',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10px',
  },
  TableCell: {
    backgroundColor: "#F6F8FA",
    ' &:nth-child(1)': {
      borderTopLeftRadius: '18px',
      borderBottomLeftRadius: '18px',
      height: '100%',

    },
    ' &:nth-child(5)': {
      borderTopRightRadius: '18px',
      borderBottomRightRadius: '18px',
    },
  },
  SettingsNestedInput: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  SettingsNestedInputLabel: {
    width: '250px',
    fontSize: '18px',
  },
  RemoveUserWrapper: {
    border: '1px solid #BCCADA',
    borderRadius: '6px',
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '30px',
    flexDirection: 'column'

  },
  EditUserWrapper: {
    borderBottom: '1px dashed #BCCADA',
    paddingBottom: '20px',
    width: '100%',
    display: 'flex',
  },
  RadioWrap: {
    display: 'flex',
    marginBottom: '20px',
  },
  RadioInputWrap: {
    width: '100%',
  },
  RadioCurrent: {
    opacity: '.6',
  },
  RadioName: {
    fontSize: '18px',
  },
  PaymentCard: {
    border: '1px solid #2FB299',
    padding: '20px 10px',
    backgroundColor: '#CADEE0',
    borderRadius: '8px',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  PaymentCardText: {
    flex: '1',
    marginLeft: '20px',
  },
  PaymentCardImage: {
    backgroundColor: '#fff',
    padding: '5px 5px 0',
  },
  PaymentCardName: {
    fontWeight: '500',
    fontSize: '20px',
  },
  PaymentCardDate: {
    fontSize: '14px',
  },
  PaymentCardIcon: {
    position: 'relative',
    top: '4px',
  },
  PaymentCardAdd: {
    marginTop: '10px',
    cursor: 'pointer',
    opacity: '.5',
    transition: '.2s',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px',
    },
    '&:hover': {
      opacity: '1',
    }
  },
  Tabs: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      width: '100%',
      backgroundColor: '#DFE4EA',
      position: 'relative',
      bottom: '2px',
    },
    '& .MuiButtonBase-root': {
      textTransform: 'none',

    },
    '& .MuiButtonBase-root.Mui-selected': {
      color: '#000',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#00FFAA',
      height: '2px',
      zIndex: '10000000',
    }
  },
  RecordingsContainer: {
  display: 'flex',
  flexWrap: 'wrap',
  },
  RecordingWrapper: {
    backgroundColor: '#DFE4EA',
    width: '100%',
    borderRadius: '8px',
    margin: '0 10px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '10px',
    position: 'relative',
    transition: '.2s',
    '&:hover': {
      backgroundColor: '#cfd5db'
    }

  },
  DangerDescriptionLine: {
  color: '#000',
  fontSize: '14px',
  marginTop: '5px',
  display: 'flex',
  alignItem: 'center',
  width: '100%',
  '& img': {
   marginRight: '10px'
  }
  },
  LinkToVideoWrapper: {
    textDecoration: 'none',
    width: 'calc(100%/3 - 20px)',
    maxWidth: '420px',
    margin: '0 10px 20px',
    '@media (max-width: 1200px)': {
      width: 'calc(100%/2 - 20px)',
    },

  },
  RecordArrow: {
    position: 'absolute',
    top: '100px',
    alignSelf: 'center'
  },
  CameraDetailsLinks: {
    borderBottom: '1px solid #DFE4EA',
    margin: '40px 0 0px',
    paddingBottom: '10px ',
    '& a': {
      textDecoration: 'none',
      marginLeft: '-5px',
      padding: '0 30px',
      color: "#636F7D",
      '&.active': {
        borderBottom: '1px solid #00FFAA', color: '#000',   paddingBottom: '10px ',
      },
    }
  },
  TileWrap: {
    height: '100%',
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: '#DFE4EA',
    display: 'flex',
    flexDirection: 'column',
  },
  TileHeader: {
    margin: '5px 0 18px',
    display: 'flex',
    justifyContent: 'space-between',
    '& h3': {
      fontWeight: '400',
    }
  },
  VideoWrap: {
    backgroundColor: '#000',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  AreaTile: {
    backgroundColor: '#F6F8FA',
    padding: '10px 10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: '.2s',
    margin: '6px 0',
    '&:hover': {
      backgroundColor: '#cfd5db',
    }
  },
  AreaTileHeader: {
    display: 'flex',
  },
  AreaTileHeaderName: {
    flex: '1',
    marginLeft: '20px',
    '& > span': {
      color: '#9294a2',
      fontSize: '14px',
    }
  },
  AreaTileHeaderOptions: {
    display: 'flex',
  },
  AreaTileHeaderIcon: {
    display: 'flex',
    textAlign: 'center',
    marginLeft: '5px',
    '& img': {
      width: '36px',
      height: 'auto',
    }
  },
  AreaTileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
    '& > div': {
      display: 'flex',
    },
    '& span': {
      color: '#9294a2',
      marginRight: '6px',
    }
  },
  AreaNoAreas: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C1CDDA',
    borderRadius: '8px',
    textAlign: 'center',
  },
  drawingWrap: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    minHeight: '100vh',
    zIndex: '2000000000000',
    backgroundColor: '#DFE4EA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  drawingWrapBackground: {
    '&:before': {
      content: '""',
      display: 'block',
      height: '100vh',
      width: '100vw',
      backgroundColor: '#DFE4EA',
      zIndex: 'auto',
      position: 'fixed',
      top: '0',
      left: '0'
    }
  },
  drawingLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 30000000000000000000000000000,
    backgroundColor: 'rgba(255,255,255,.7)',
    height: '100vh',
    width: '100vw',
  },
  borderFormWrap: {
    border: '1px solid #BCCADA',
    borderRadius: '6px',
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '30px',
    flexDirection: 'column'
  },
  borderFormHeader: {
    borderBottom: '1px dashed #BCCADA',
    paddingBottom: '20px',
    width: '100%',
    fontSize: '18px',
  },
  cameraImageWrap: {
    maxWidth: '100vw',
    maxHeight: '85vh',
    position: 'relative',
    overflow: 'scroll',
  },
  drawingToolbar: {
    marginTop: "10px",
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1)',
    position: 'relative',
    zIndex: '2',
  },
  cameraCanvasWrap: {
    position: 'absolute',
    height: '200px',
    width: '600px',
    '& >  .canvas-container': {
      '& >  .upper-canvas': {
        pointerEvents: 'none',
      }
    }
  },
  areaAddingActions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      cursor: 'pointer',
    }
  },
  areaAddingActionsDelete: {
    color: '#818D98',
    flex: 1,
    textAlign: 'right',
    marginRight: '20px',
  },
  areaRemoving: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& div': {
      marginLeft: '10px',
    }
  },
  cameraImageLoading: {
    width: '1000px',
    height: '600px',
    backgroundColor: 'rgba(0,0,0,0.1)',
    marginBottom: '20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      margin: '20px 0',
    }
  },
  cameraHeatmapLoading: {
    minHeight: '500px',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgb(0,0,0)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    '& > div': {
      margin: '20px 0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    }
  },
  collapsedFormGroup: {
    padding: '0 10px',
    backgroundColor: '#DFE4EA',
    borderRadius: '8px',
  },
  ToggleSwitch: {
    display: 'flex',
    backgroundColor: '#C1CDDA',
    borderRadius: '5px',
    padding: '5px',
    marginBottom: '6px',
    '& > div': {
      flex: 1,
      textAlign: 'center',
      color: '#636F7D',
      padding: '5px 0',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: '.2s',
    },
    '& > div:hover': {
      color: '#000',
    },
    '& $FilterActive': {
      color: '#000',
    },
  },
  FilterActive: {
    backgroundColor: '#F6F8FA',
  },
  areaActionsWrap: {
    '& > button:last-child': {
      marginLeft: '6px',
    },
    '& > .MuiButton-root': {
      borderRadius: '40px',
      height: '30px',
    }
  },
  heatmapWrap: {
    position: 'relative',
  },
  heatmapImage: {
    minHeight: '500px',
    '& img': {
      width: '100%',
    }
  },
  heatmap: {
    position: 'absolute',
    top: 0,
    width: '100%',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      display: 'block',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.85)',
      position: 'absolute',
      top: '0',
      left: '0',
    }
  },
  heatmapBlurWrap: {
    filter: 'blur(18px)',
    display: 'flex',
    flexWrap: 'wrap',
  },
  heatmapRowWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterHeader: {
    '& h3': {
      fontSize: '15px',
      fontWeight: '400',
    }
  },
  filterButtonWrap: {
    marginTop: '20px',
  },
  colorScale: {
    display: 'flex',
    position: "absolute",
    right: '12px',
    top: '30px',
    '& div': {
      width: 'fit-content',
      padding: '3px 7px',
      marginLeft: '8px',
    }
  },
  GraphanaTile: {
    padding: '10px 10px 4px',
    backgroundColor: '#DFE4EA',
    borderRadius: '8px',
    marginBottom: '10px',
  },
  Pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  },
  ImageWrapper: {
    height: '200px',
    width: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '10px',
    backgroundColor: 'rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: '100px',
      width: '100%',
    },
    '@media (max-width: 1500px)': {
      height: '200px',
    },
  },

  Image: {
    height: 'auto !important'
  },

  ListLoader: {
    width: '100%',
    height: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  RecordDetailsContainer: {
    display: 'flex',
    margin: '20px',
    marginTop: '25px'

  },
  RecordDetailsWrapper: {
    width: '30%',
    minWidth: '300px',

  },
  Record: {
    width: '70vw',
    backgroundColor: '#DFE4EA',
    marginLeft: '10px',
    borderRadius: '8px',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px'
  },
  RecordImage: {
    marginTop: '10px',
    width: '70vw',
    backgroundColor: '#DFE4EA',
    marginLeft: '10px',
    borderRadius: '8px',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  zoomImageWrapIn: {
    '& img': {
      cursor: 'zoom-out'
    },
    '&:before': {
      content: '""',
      position: 'fixed',
      zIndex: '2300000000000',
      top: 0,
      left: 0,
      display: 'block',
      height: '200vh',
      width: '200vw',
      backgroundColor: 'rgba(0,0,0,0.9)',
    },
  },
  zoomImageWrapOut: {
    '& img': {
      maxHeight: '480px',
      cursor: 'zoom-in'
    },
  },
  fullScreen: {
    position: 'fixed',
    zIndex: '23000000000000',
    maxHeight: '100vh !important',
    maxWidth: '100vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'zoom-out',
  },
  BackToList: {
    backgroundColor: '#DFE4EA',
    borderRadius: '8px',
    paddingLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '60px',
    cursor: 'pointer',
    '& p': {
      textAlign: 'center',
      fontSize: '20px',
      display: 'inline-block',
      marginLeft: '60px'

    },
    '& a': {
      textDecoration: 'none',
      color: '#000',
      display: 'inline',

    }
  },
  RecordDetails: {
    backgroundColor: '#DFE4EA',
    height: '430px',
    marginTop: '10px',
    borderRadius: '10px',
    padding: '10px'
  },
  RecordDetailsTitle: {
    fontSize: '20px',
    color: '#000000',
    fontWeight: 'normal'
  },
  DetailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px'
  },
  DetailTitle: {
    color: '#6D7A88',
    fontSize: '14px'
  },
  DetailDescription: {
    fontSize: '20px',
    color: '#000'
  },

  RecordingsSmallList: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 10px 50px'  }

}));
