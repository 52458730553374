import React, { useEffect, useState } from "react";
import AlertLink from "../AlertLink";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import MockWrapper from '../MockWrapper';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { format } from 'date-fns';
import { fetchAlertsList } from '../../redux/reducers/alerts';

interface ICameraAlerts {
  pathname: string;
  camera?: any;
}

function CameraAlerts({ pathname, camera }: ICameraAlerts) {
  const classes = useStyles();
  const alertsList = useAppSelector(state => state.alerts?.list?.notifications);
  const dispatch = useAppDispatch();

  const [thisCameraFilter, setThisCameraFilter] = useState<boolean>(pathname !== 'dashboard');
  const [alerts, setAlerts] = useState<any>([]);
  const { t } = useTranslation("common");
  const {id} = useParams();
  const cameraId = Number(id);

  useEffect(() => {
    if(!alertsList) {
      dispatch(fetchAlertsList())
    }
  }, [])

  useEffect(() => {
    if (thisCameraFilter) {
      if(cameraId !== null) {
        setAlerts(alertsList ? alertsList.filter((item) => item.camera_id === cameraId).slice(0,5) : []);
      }
    } else {
      setAlerts(alertsList ? alertsList.slice(0, 5) : []);
    }
  }, [thisCameraFilter, cameraId, alertsList]);

  return (
      <Box className={classes.TileWrap}>
        <h3 className={classes.Title}>{t("camera.alerts")}</h3>
        {pathname !== "dashboard" ? (
          <Box className={classes.ToggleSwitch}>
            <div
              className={thisCameraFilter ? classes.FilterActive : ""}
              onClick={() => setThisCameraFilter(true)}
            >
              {t("camera.thisCamera")}
            </div>
            <div
              className={!thisCameraFilter ? classes.FilterActive : ""}
              onClick={() => setThisCameraFilter(false)}
            >
              {t("camera.allCameras")}
            </div>
          </Box>
        ) : null}
        <Box className={classes.AlertsWrap}>
          {alerts.map((alert: any, index: number) => (
            <AlertLink
              alertId={alert.id}
              cameraId={alert.camera_id}
              pathname={pathname}
              time={format(new Date(alert.created_at), 'HH:mm')}
              name={alert.alert_name}
              key={alert.id}
              cameraName={alert.camera_name}
              type={alert.metric}
            />
          ))}
        </Box>
      </Box>
  );
};

export default CameraAlerts;
