import { Box } from "@mui/system";
import React, { useEffect, useState } from 'react';
import { RoundedButton } from "../../../components/RoundedButton/RoundedButton";
import arrowButton from "../../../assets/icons/arrowIcon.png";
import { useStyles } from "../styles";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from 'react-router-dom';
import MockWrapper from '../../../components/MockWrapper';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAlert } from '../../../redux/reducers/alerts';
import { format } from 'date-fns';

export function RecordDetails(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const currentAlert = useAppSelector(state => state.alerts.currentAlert);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const {id, recordingId} = useParams();

  useEffect(() => {
    if(recordingId) {
      dispatch(fetchAlert(recordingId))
    }
  }, [dispatch, recordingId])

  return (
    <>
      <MockWrapper>
        <div className={classes.RecordDetailsContainer}>
          <div className={classes.RecordDetailsWrapper}>
            <Box onClick={() => navigate(`/camera/${id}/recordings`)} className={classes.BackToList}>
              <RoundedButton white icon={arrowButton} />
              <p>{t("recordDetails.backToList")}</p>
            </Box>
            <Box className={classes.RecordDetails}>
              <h3 className={classes.RecordDetailsTitle}>
                {t("recordDetails.dangerInfo")}
              </h3>
              <div className={classes.DetailWrapper}>
                <div className={classes.DetailTitle}>
                  {t("recordDetails.date")}
                </div>
                <div className={classes.DetailDescription}>
                  {currentAlert ? format(new Date(currentAlert.created_at), 'dd.MM.yyyy, HH:mm') : '-'}
                </div>
              </div>

              <div className={classes.DetailWrapper}>
                <div className={classes.DetailTitle}>
                  {t("recordDetails.camera")}
                </div>
                <div className={classes.DetailDescription}>
                  {currentAlert?.camera_name}
                </div>
              </div>



              <div className={classes.DetailWrapper}>
                <div className={classes.DetailTitle}>
                  {t("recordDetails.dangerType")}
                </div>
                <div className={classes.DetailDescription}>
                  {currentAlert?.metric}
                </div>
              </div>

              <div className={classes.DetailWrapper}>
                <div className={classes.DetailTitle}>
                  {t("recordDetails.peopleCount")}
                </div>
                <div className={classes.DetailDescription}>
                  {currentAlert?.current_value.map((value: any) => <div>{value}</div>)}
                </div>
              </div>

            </Box>
          </div>
          <div>

            <div className={classes.Record}>
              {(currentAlert && currentAlert.video_path) ? (
                <ReactPlayer
                  playing={true}
                  width="auto"
                  height="100%"
                  url={"https://swagger.safetysolutions.app" + currentAlert.video_path}
                  controls={true}
                />
              ) : (
                <div>Brak nagrania</div>
              )
              }

            </div>
            <div className={classes.RecordImage}>
              {(currentAlert && currentAlert.image) ? (
                <div className={fullScreen ? classes.zoomImageWrapIn : classes.zoomImageWrapOut}>
                  <img src={`data:image/jpeg;base64,${currentAlert.image}`} className={fullScreen ? classes.fullScreen : ''} onClick={() => setFullScreen(!fullScreen)} alt="recording" />
                </div>
              ) : (
                <div>Brak zdjęcia dla tego zdarzenia</div>
              )
              }

            </div>
          </div>

        </div>


      </MockWrapper>
    </>
  );
}
