import React from 'react';
import {
  Box,
} from '@mui/material';
import { useStyles } from './styles';

export function Tile({ children }: any) {
  const classes = useStyles();
  return (
    <Box className={classes.Tile}>{children}</Box>
  );
}
