import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsPageHeader from '../../../components/SettingsPageHeader';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useStyles } from '../styles';
import SettingsGroup from '../../../components/SettingsGroup';
import ConfirmButton from '../../../components/ConfirmButton';
import visa from '../../../assets/images/visa.png';
import checked from '../../../assets/images/checked.png';
import AddIcon from '@mui/icons-material/Add';
import Modal from '../../../components/Modal';
import MockWrapper from '../../../components/MockWrapper';

interface IFormValues {
  newContactEmail: boolean;
  newContactEmailValue: string;
  paymentMethod: any;
  firstName: string;
  lastName: string;
  companyName: string;
  address: string;
  postalCode: string;
  city: string;
  nip: string;
}

export function SettingsPayments(): JSX.Element {
  const { t } = useTranslation("common");
  return (
    <Box>
      <MockWrapper>
        <SettingsPageHeader title={t("settings.payments")} description={t("settings.paymentsDescription")} />
        <Formik<IFormValues>
          initialValues={{
            newContactEmail: false,
            newContactEmailValue: "",
            paymentMethod: "",
            firstName: "",
            lastName: "",
            companyName: "",
            address: "",
            postalCode: "",
            city: "",
            nip: "",
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            companyName: Yup.string().required("Required"),
            address: Yup.string().required("Required"),
            postalCode: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            nip: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            console.log(values);
          }}
          component={SettingsPaymentsForm}
        />
      </MockWrapper>
    </Box>
);
}

let SettingsPaymentsForm:
  (props: FormikProps<IFormValues>) =>
    JSX.Element = ({
                     handleSubmit,
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                   }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  return (
    <form onSubmit={handleSubmit}>

      <SettingsGroup title={t("settings.contactEmail")} description={t("settings.contactEmailDescription")}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="newContactEmail"
          value={values.newContactEmail}
          onChange={handleChange}
        >
          <div className={classes.RadioWrap}>
            <FormControlLabel value={false} control={<Radio />} label=""/>
            <div>
              <div className={classes.RadioName}>
                {t("settings.sendToMyEmail")}
              </div>
              <span className={classes.RadioCurrent}>anna@orlen.pl</span>
            </div>
          </div>
          <div className={classes.RadioWrap}>
            <FormControlLabel value={true} control={<Radio />} label=""/>
            <div className={classes.RadioInputWrap}>
              <div className={classes.RadioName}>{t("settings.sendToDifferentEmail")}</div>
              <div>
                <TextField
                  fullWidth
                  hiddenLabel
                  name="newContactEmailValue"
                  value={values.newContactEmailValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
        </RadioGroup>
      </SettingsGroup>

      <SettingsGroup title={t("settings.paymentMethod")}>
        <div className={classes.PaymentCard}>
          <div className={classes.PaymentCardImage}>
            <img src={visa} alt=""/>
          </div>
          <div className={classes.PaymentCardText}>
            <div className={classes.PaymentCardName}>Visa kończąca się na 1234</div>
            <div className={classes.PaymentCardDate}>Data ważności 12/22</div>
          </div>
          <div className={classes.PaymentCardIcon}>
            <img src={checked} alt=""/>
          </div>
        </div>
        <div className={classes.PaymentCardAdd} onClick={() => setIsModalActive(true)}><AddIcon/> {t("settings.addPaymentMethod")}</div>
      </SettingsGroup>

      <SettingsGroup title={t("settings.invoiceData")}>
        <Grid container spacing={1}>
         <Grid item xs={6}>
           <TextField
             fullWidth
             hiddenLabel
             name="firstName"
             value={values.firstName}
             onChange={handleChange}
             onBlur={handleBlur}
             placeholder={t("settings.firstName")}
             error={!!errors.firstName && touched.firstName}
             helperText={touched.firstName ? errors.firstName : null}
           />
         </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              hiddenLabel
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("settings.lastName")}
              error={!!errors.lastName && touched.lastName}
              helperText={touched.lastName ? errors.lastName : null}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("settings.companyName")}
              error={!!errors.companyName && touched.companyName}
              helperText={touched.companyName ? errors.companyName : null}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("settings.address")}
              error={!!errors.address && touched.address}
              helperText={touched.address ? errors.address : null}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              hiddenLabel
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("settings.postalCode")}
              error={!!errors.postalCode && touched.postalCode}
              helperText={touched.postalCode ? errors.postalCode : null}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              hiddenLabel
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("settings.city")}
              error={!!errors.city && touched.city}
              helperText={touched.city ? errors.city : null}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              name="nip"
              value={values.nip}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("settings.nip")}
              error={!!errors.nip && touched.nip}
              helperText={touched.nip ? errors.nip : null}
            />
          </Grid>
        </Grid>
      </SettingsGroup>

      <Box className={classes.ButtonWrap}>
        <ConfirmButton confirmButtonText={t("settings.save")} />
      </Box>
      {isModalActive &&
      <Modal
        title={t("settings.payments")}
        description=""
        confirmButtonText="Wyślij"
        isModalActive={isModalActive}
        setIsModalActive={() => setIsModalActive(!isModalActive)}
      >
       Tutaj będzie integracja płatności
      </Modal>
      }
    </form>
  );
};

