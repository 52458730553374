import React from 'react';
import InstancesSelect from "../InstancesSelect";
import {IconButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import instanceIcon from "../../assets/icons/home.png";
import PermissionsGate from "../PermissionsGate";
import {SCOPES} from "../PermissionsGate/permission-maps";
import {Logout} from "@mui/icons-material";
import {saveCurrentInstance, saveUserRole} from "../../redux/reducers/user";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useNavigate} from "react-router-dom";
import {getRole} from "../../hooks/useAuth";

export interface InstancesButton {
    currentInstance: any,
    open: any,
    userRole: string,
    userGroups: any[],
}

const InstanceButton = ({currentInstance, open, userRole, userGroups}: InstancesButton) => {
    const { groups: currentUserGroups, data: userProfileData } = useAppSelector((state) => state.user);
    const [anchorElInstanceIcon, setAnchorElInstanceIcon] = React.useState<null | HTMLElement>(null);
    const openInstanceMenu = Boolean(anchorElInstanceIcon);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const {t} = useTranslation("common");

    const handleInstanceIconClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorElInstanceIcon) {
            setAnchorElInstanceIcon(event.currentTarget);
        } else {
            setAnchorElInstanceIcon(null)
        }
    };

    const handleInstanceChange = (instance: any) => {
        navigate(`/`);
        dispatch(saveUserRole(getRole(userProfileData, currentUserGroups, instance?.id)));
        dispatch(saveCurrentInstance(instance));
    }

    const handleInstanceClose = () => {
        setAnchorElInstanceIcon(null);
    };

    const handleInstanceLogout = () => {
        navigate(`/instances`);
        dispatch(saveCurrentInstance(null));
    }

    return (
        <>
            {open ?
                <InstancesSelect
                    chosenInstance={currentInstance}
                    groupsList={userGroups}
                    disabledMenu={userRole === "GLOBAL_ADMIN"}
                /> :
                <>
                    <span  className={classes.InstanceName}>{currentInstance.name}</span>
                    <IconButton
                        className={classes.InstanceIcon}
                        onClick={handleInstanceIconClick}
                        aria-controls={openInstanceMenu ? "instance-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openInstanceMenu ? "true" : undefined}
                    >
                        <img src={instanceIcon} alt='home'/>
                        <Menu
                            anchorEl={anchorElInstanceIcon}
                            id="instance-menu"
                            open={openInstanceMenu}
                            onClose={handleInstanceClose}
                            onClick={handleInstanceClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <PermissionsGate scopes={[SCOPES.globalAdmin]}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    <div onClick={handleInstanceLogout}>
                                        {t("navigation.logout")}
                                    </div>
                                </MenuItem>
                            </PermissionsGate>
                            <PermissionsGate scopes={[SCOPES.instanceAdmin, SCOPES.user]}>
                                {userGroups.map(group =>
                                    <MenuItem onClick={() => handleInstanceChange(group)}>
                                        {group.name}
                                    </MenuItem>
                                )}
                            </PermissionsGate>
                        </Menu>
                    </IconButton>
                </>
            }
        </>
    )
};

export default InstanceButton;