import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { fabric } from 'fabric';

export interface IAddInstanceModal {
  imageSize: any;
  contours: any;
}

export const CanvasEditing = forwardRef(({
                                           imageSize,
                                           contours,
                                         }: IAddInstanceModal, ref): JSX.Element => {

  const [areaContour, setAreaContour] = useState<any>(null);
  const [canvas, setCanvas] = useState<any>(null);

  useEffect(() => {
    if(imageSize) {
      setCanvas(initCanvas());
    }
  }, [imageSize]);

  useEffect(() => {
    if(canvas) {
      if(areaContour) {
        const polygon = new fabric.Polygon(areaContour, {
          fill: 'rgba(216,27,96,0.5)',
          strokeWidth: 4,
          stroke: '#d81b60',
          scaleX: 1,
          scaleY: 1,
          objectCaching: false,
          transparentCorners: false,
          cornerColor: 'white',
        });
        canvas.add(polygon);

        const allObjects = canvas.getObjects();

        allObjects.forEach((object: any) => {
          object.selectable = false
          object.lockMovementX = true;
          object.lockMovementY = true;
        });
      }
    }
  }, [canvas, areaContour]);


  useEffect(() => {
    if(contours) {
      setAreaContour(contours)
    } else {
      setAreaContour(null)
    }
  }, [contours])

  const initCanvas = () => (
    new fabric.Canvas('canvas', {
      height: imageSize.height,
      width: imageSize.width,
      selection: false,
    })
  )

  // @ts-ignore
  const polygonPositionHandler = function (dim, finalMatrix, fabricObject) {
    // @ts-ignore
    var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
      // @ts-ignore
      y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
    return fabric.util.transformPoint(
      // @ts-ignore
      { x: x, y: y },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    );
  }

  // @ts-ignore
  const getObjectSizeWithStroke = function(object) {
    var stroke = new fabric.Point(
      object.strokeUniform ? 1 / object.scaleX : 1,
      object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
  }

  // @ts-ignore
  const actionHandler = function(eventData, transform, x, y) {
    var polygon = transform.target,
      currentControl = polygon.controls[polygon.__corner],
      mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
      polygonBaseSize = getObjectSizeWithStroke(polygon),
      size = polygon._getTransformedDimensions(0, 0),
      finalPointPosition = {
        x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
        y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
      };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
  }


  // @ts-ignore
  const anchorWrapper = function(anchorIndex, fn) {
    // @ts-ignore
    return function(eventData, transform, x, y) {
      var fabricObject = transform.target,
        // @ts-ignore
        absolutePoint = fabric.util.transformPoint({
          x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
          // @ts-ignore
          y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
        }, fabricObject.calcTransformMatrix()),
        actionPerformed = fn(eventData, transform, x, y),
        newDim = fabricObject._setPositionDimensions({}),
        polygonBaseSize = getObjectSizeWithStroke(fabricObject),
        newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
        newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    }
  }

  useImperativeHandle(ref, () => ({
    edit: Edit,
    getContours: getContours,
  }));

  const getContours = () => {
   return canvas;
  }

  const Edit = function() {
    var poly = canvas.getObjects()[0];
    canvas.setActiveObject(poly);
    poly.edit = !poly.edit;
    if (poly.edit) {
      var lastControl = poly.points.length - 1;
      poly.cornerStyle = 'square';
      poly.cornerColor = 'white';
      // @ts-ignore
      poly.controls = poly.points.reduce(function(acc, point, index) {
        acc['p' + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
          actionName: 'modifyPolygon',
          // @ts-ignore
          pointIndex: index
        });
        return acc;
      }, { });
    } else {
      poly.cornerColor = 'white';
      poly.cornerStyle = 'rect';
      poly.controls = fabric.Object.prototype.controls;
    }
    poly.hasBorders = !poly.edit;
    canvas.requestRenderAll();
  }

  return (
    <canvas id="canvas" />
  );
})
