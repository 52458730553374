import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../components/SettingsPageHeader";
import { Payments } from "../../../mocks/Payments";
import PaymentsList from "../../../components/List/PaymentsList";
import MockWrapper from '../../../components/MockWrapper';

export function SettingsPaymentsHistory(): JSX.Element {
  const { t } = useTranslation("common");
  return (
    <Box>
      <MockWrapper>
        <SettingsPageHeader
          title={t("settings.paymentsHistory")}
          description={t("settings.description.paymentsHistory")}
        />
        <PaymentsList tableContent={Payments} />
      </MockWrapper>
    </Box>
  );
}
