import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {useStyles} from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ConfirmButton from "../../ConfirmButton";
import {CircularProgress, Pagination, TableHead} from "@mui/material";
import {Box} from "@mui/system";
import fireIcon from "../../../assets/icons/fire.svg";
import alertIcon from "../../../assets/icons/alert.png";
import { useAlert } from '../../../pages/Alerts';
import { useNavigate } from 'react-router-dom';
import {format} from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAlertsBetween } from '../../../redux/reducers/alerts';

function AlertsList() {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const {t} = useTranslation("common");
    const [currentPage, setCurrentPage] = useState(1);
    const [highestId, setHighestId] = useState<number | null>(null);

    const recordsOnPage = 20;
    const navigate = useNavigate();
    const {alerts} = useAlert();
    const total_count = useAppSelector(state => state.alerts?.list?.total_count);

    useEffect(() => {
        if(!highestId){
            setHighestId(alerts?.notifications[0].id)
        }
    }, [alerts])

    const handleChangePage = (event: any, newPage: number) => {
        console.log(highestId)
        if(highestId) {
            const to =  highestId - ((newPage - 1) * recordsOnPage);
            const from = highestId - (newPage * recordsOnPage);
            dispatch(fetchAlertsBetween(from, to))
            setCurrentPage(newPage);
        }
    };

    const totalPages = Math.ceil((total_count ? total_count / recordsOnPage : 0));

    return (
        <Box sx={{p: 2, pt: 0}}>
            {alerts ? (
                <TableContainer>
                    <Table
                        style={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 10px",
                            width: "98%",
                            margin: "0 auto",
                        }}
                        sx={{minWidth: 650}}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("alerts.name")}</TableCell>
                                <TableCell align="left">{t("alerts.camera")}</TableCell>
                                <TableCell align="left">{t("alerts.type")}</TableCell>
                                <TableCell align="left">{t("alerts.date")}</TableCell>
                                <TableCell align="left">{t("alerts.time")}</TableCell>
                                <TableCell align="left"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alerts.notifications.map((cell: any) => (
                                <TableRow
                                    key={cell.id}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell
                                        style={{backgroundColor: "#DFE4EA"}}
                                        className={classes.TableCell}>
                                        <div className={classes.AlertName}>
                                            <img src={cell.type === 'fire' ? fireIcon : alertIcon} alt="alert icon" />
                                            {cell.alert_name}
                                        </div>
                                    </TableCell>
                                    <TableCell
                                      style={{backgroundColor: "#DFE4EA"}}
                                      className={classes.TableCell}
                                      align="left"
                                    >
                                        {cell.metric}
                                    </TableCell>
                                    <TableCell
                                        style={{backgroundColor: "#DFE4EA"}}
                                        className={classes.TableCell}
                                        align="left"
                                    >
                                        {cell.camera_name}
                                    </TableCell>
                                    <TableCell
                                        style={{backgroundColor: "#DFE4EA"}}
                                        className={classes.TableCell}
                                        align="left"
                                    >
                                        {format(new Date(cell.created_at), 'yyyy-MM-dd')}
                                    </TableCell>
                                    <TableCell
                                        style={{backgroundColor: "#DFE4EA"}}
                                        className={classes.TableCell}
                                        align="left"
                                    >
                                        {format(new Date(cell.created_at), 'HH:mm')}
                                    </TableCell>
                                    <TableCell
                                        style={{backgroundColor: "#DFE4EA"}}
                                        className={classes.TableCell}
                                        align="right"
                                    >
                                        <div
                                            style={{display: "flex", justifyContent: "flex-end"}}
                                        >
                                            <ConfirmButton
                                                confirmButtonText={t("alerts.see")}
                                                confirmButtonColor="#000"
                                                dark={false}
                                                handleOnClick={() =>
                                                  navigate(`/camera/${cell.camera_id}/recordings/${cell.id}`, { replace: true })
                                                }
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            className={classes.Pagination}
                        />
                </TableContainer>
            ) : (
                <CircularProgress/>
            )}
        </Box>
    );
}

export default AlertsList;
