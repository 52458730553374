import React from 'react';
import { Alert, AlertTitle } from "@mui/material";

export interface ICustomAlert {
    severity: any,
    title: any,
    children: any,
}

const CustomAlert = ({severity, title, children}: ICustomAlert) => {
    return (
        <Alert severity={severity} sx={{marginBottom: 2}}>
            <AlertTitle>{title}</AlertTitle>
            {children}
        </Alert>
    );
};

export default CustomAlert;