import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    Wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin:'100px 0',
        justifyContent: 'center',
        '& img': {
            height: '500px',
        }
    },
    LinkReset: {
        textDecoration: 'none',
        color: '#000',
        marginTop: '20px',
    },
    Contribution: {
        position: 'absolute',
        bottom: 0,
        right: 5,
        fontSize: '12px',
        color: '#6b778c',
    }
}));