import React from 'react';
import {Box, Grid} from "@mui/material";
import {useStyles} from "../styles";
import { NavLink, useNavigate } from 'react-router-dom';
import {RoundedButton} from "../../../components/RoundedButton/RoundedButton";
import CamerasSelect from "../../../components/CamerasSelect";
import arrowButton from "../../../assets/icons/arrowIcon.png";
import {useTranslation} from "react-i18next";

export interface ICameraNav {
  camera?: any;
  setCamera?: any;
}

export function CameraNav({ camera, setCamera }: ICameraNav) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 2, pt: 4 }}>
      <Grid className={classes.CameraNav} item xs={12}>
        <Box>
          <RoundedButton icon={arrowButton} onClick={() => navigate(-1)}/>
        </Box>
        <CamerasSelect
          camera={camera}
          chosenCamera={camera}
          setChosenCamera={setCamera}
        />
      </Grid>

      <Box className={classes.CameraDetailsLinks}>
        <NavLink to="liveView">{t("camera.liveView")}</NavLink>
        <NavLink to="recordings">{t("camera.recordings")}</NavLink>
        <NavLink to="dangerArea">{t("camera.dangerAreas")}</NavLink>
        <NavLink to="heatmap">{t("camera.heatmap")}</NavLink>
      </Box>
    </Box>
  );
}
