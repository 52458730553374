import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmButton from "../../../../../components/ConfirmButton";
import Modal from "../../../../../components/Modal";
import SettingsGroup from "../../../../../components/SettingsGroup";
import { useStyles } from "../../../styles";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import {useAppDispatch} from "../../../../../redux/hooks";
import {createUser} from "../../../../../redux/reducers/auth";

export interface IAddUserModal {
  setIsModalActive: any;
  groupID: any;
}

interface IFormAddUserValues {
  email: string;
}

export function AddUserModal({
  setIsModalActive,
  groupID,
}: IAddUserModal): JSX.Element {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("users.addUser")}
      description={t("users.addUserDesc")}
      confirmButtonText={t("users.send")}
    >
      <Formik<IFormAddUserValues>
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email format")
            .required("Required"),
        })}
        onSubmit={(values) => {
          dispatch(createUser(values, groupID));
          setIsModalActive(false);
        }}
        component={AddUserModalForm}
      />
    </Modal>
  );
}

let AddUserModalForm: (
  props: FormikProps<IFormAddUserValues>
) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <form style={{ margin: "30px 0 40px" }} onSubmit={handleSubmit}>
      <SettingsGroup type="modal" title={t("users.usersEmail")}>
        <TextField
          fullWidth
          error={!!errors.email && touched.email}
          helperText={touched.email ? errors.email : null}
          hiddenLabel
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SettingsGroup>
      <Box
        style={{ position: "absolute", right: "50px", bottom: "50px" }}
        className={classes.ButtonWrap}
      >
        <ConfirmButton confirmButtonText={t("settings.save")} />
      </Box>
    </form>
  );
};
