import React from "react";
import { Box } from '@mui/material';
import { useStyles } from './styles';

interface ISettingsPageHeader {
  title: string,
  description: string,
}

function SettingsPageHeader({title, description}: ISettingsPageHeader): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.Wrap}>
      <div className={classes.Title}>{title}</div>
      <div className={classes.Description}>{description}</div>
    </Box>
  );
}

export default SettingsPageHeader;
