import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  ListWrap: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  NavText: {
    '& span': {
      fontSize: '18px',
      color: '#636F7D',
    },
  },
  LogoWrap: {
    textAlign: 'center',
    margin: '25px',
    paddingBottom: '10px',
    borderBottom: '1px solid #DFE4EA',
    '& img': {
      width: '100%',
    },
  },
  NavItem: {
    '&.MuiListItem-root': {
      width: '250px',
      height: '52px',
      paddingLeft: '15px',
      margin: '15px 0',
      borderRadius: '10px',
    },
    '&.Mui-selected': {
      backgroundColor: '#DFE4EA !important',
      '& span': {
        color: '#000',
      },
      '& img': {
        filter: 'brightness(0)',
      },
    },
  },
  NavWrap: {
    position: 'relative',
  },
  ToggleButton: {
    position: 'absolute',
    zIndex: '100000',
    top: '90vh',
    right: '-20px',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#DFE4EA',
    cursor: 'pointer',
    transition: '.2s',
    '&:hover': {
      width: '46px',
      height: '46px',
      right: '-23px',
      top: 'calc(90vh - 3px)',
    },
  },
  NavClosed: {
    '& $ToggleButton': {
      transform: 'rotate(180deg)',
    },
    '& $NavItem': {
      '&.MuiListItem-root': {
        width: '52px',
        padding: '12px',
      },
      '& span': {
        display: 'none',
      },
    },
  },
  ProfileWidget: {
    padding: '10px',
    borderRadius: '8px !important',
    display: 'flex',
    width: 'calc(100% - 20px)',
    alignItems: 'center',
    justifyContent: 'flex-start !important',
    textDecoration: 'none !important',
    color: '#000',
    margin: '10px !important',
    '&:hover': {
      backgroundColor: '#DFE4EA',
      cursor: 'pointer',
      transition: '.2s',
    },
  },
  ProfileWrap: {
    position: 'relative',
  },
  LinkReset: {
    textDecoration: 'none',
    color: '#000',
  },
  ProfileMenuClosed: {
    display: 'none'
  },
  ProfileMenuOpen: {
    display: 'block'
  },
  ProfileMenu: {
    backgroundColor: '#fff',
    padding: '10px',
    width: '90%',
    position: 'absolute',
    bottom: '100px',
    borderRadius: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
    border: '1px solid rgba(0,0,0,0.1)',
    '& > div': {
      padding: '10px',
      transition: '.2s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#cfd5db',
      },
    },
    '& a': {
      textDecoration: 'none',
      color: '#000',
      width: '100%',
      display: 'inline-block',
    }
  },
  ProfileImage: {
    height: '50px',
    width: '50px',
    borderRadius: '10px',
    overflow: 'hidden',
    marginRight: '15px',

    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  },
  ProfileImageClosed: {
    height: '50px',
    width: '50px',
    borderRadius: '10px',
    overflow: 'hidden',
    position: 'relative',
    left: '4px',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  },
  ProfileName: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#000'
  },
  ProfileEmail: {
    color: '#636F7D',
    fontSize: '16px',
    fontWeight: '500',
  },
  Hide: {
    display: 'none',
  }
}));
