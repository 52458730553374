import React from "react";
import { Tile } from "../../../components/Tile";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import PermissionsGate from "../../../components/PermissionsGate";
import { SCOPES } from "../../../components/PermissionsGate/permission-maps";
import {useAppSelector} from "../../../redux/hooks";
import MockWrapper from '../../../components/MockWrapper';

export function SettingsNav(): JSX.Element {
  const { currentInstance } = useAppSelector((state) => state.user);
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Tile>
      <Box className={classes.List}>
        <NavLink to="/settings/profile">{t("settings.profile")}</NavLink>
        <NavLink to="/settings/password">{t("settings.password")}</NavLink>
        <NavLink to="/settings/system">{t("settings.system")}</NavLink>
        {currentInstance &&
        <PermissionsGate scopes={[SCOPES.admin]}>
          <NavLink to="/settings/users">{t("settings.users")}</NavLink>
          <NavLink to="/settings/instance">{t("settings.instance")}</NavLink>
          <NavLink to="/settings/cameras">{t("settings.cameras")}</NavLink>
          {/*<MockWrapper>*/}
          {/*  <NavLink to="/settings/reports">{t("settings.reports")}</NavLink>*/}
          {/*  <NavLink to="/settings/logs">{t("settings.logs")}</NavLink>*/}
          {/*  <NavLink to="/settings/payments">{t("settings.payments")}</NavLink>*/}
          {/*  <NavLink to="/settings/payments-history">*/}
          {/*    {t("settings.paymentsHistory")}*/}
          {/*  </NavLink>*/}
          {/*</MockWrapper>*/}
        </PermissionsGate>
        }
      </Box>
    </Tile>
  );
}
