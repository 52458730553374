import {configureStore} from '@reduxjs/toolkit';
import userReducer from "./reducers/user";
import groupUsersReducer from "./reducers/groupUsers";
import groupsReducer from "./reducers/groups";
import authReducer from "./reducers/auth";
import cameraReducer from "./reducers/camera";
import alertsReducer from "./reducers/alerts";
import storage from "redux-persist/lib/storage";
import {PERSIST, persistReducer, persistStore, REGISTER, REHYDRATE} from 'redux-persist';

const persistConfig = {
    key: 'user',
    storage,
    whitelist: ['role', 'currentInstance', 'showMocks', 'systemLang', 'showAlerts', 'playSounds']
}

const persistedUserReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
    reducer: {
        user: persistedUserReducer,
        groups: groupsReducer,
        groupUsers: groupUsersReducer,
        auth: authReducer,
        camera: cameraReducer,
        alerts: alertsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [REHYDRATE, PERSIST, REGISTER],
            },
        }),
})

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
