import React from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export function InstancesNav(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Box sx={{ marginBottom: 2, pt: 6 }} className={classes.InstancesNav}>
      <NavLink to="info">Informacje</NavLink>
      <NavLink to="users">Użytkownicy</NavLink>
      <NavLink to="cameras">Kamery</NavLink>
    </Box>
  );
}
