import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  VideoWrap: {
    backgroundColor: '#000',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  ToggleWrap: {
    margin: '5px 0 8',
    display: 'flex',
    justifyContent: 'space-between',
    '& h3': {
      fontSize: '16px',
      fontWeight: '500',
      margin: '0 0 10px',
    }
  }
}));
