import {Autocomplete, Box, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../components/SettingsPageHeader";
import ConfirmButton from "../../../components/ConfirmButton";
import SettingsGroup from "../../../components/SettingsGroup";
import { useStyles } from "../styles";
import { RemoveInstanceModal } from "../Modal/RemoveInstanceModal";
import {useLocation} from "react-router-dom";
import {editGroupAdmins, fetchGroupUsersList} from "../../../redux/reducers/groupUsers";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import Toggle from "../../../components/Toggle";
import { editGroup } from "../../../redux/reducers/groups";
import MockWrapper from "../../../components/MockWrapper";

interface IFormInfoValues {
  name: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  admins: any[];
  street: string | undefined;
  postCode: string | undefined;
  city: string | undefined;
  isActive: number | undefined;
  resolution: string;
  accuracyObject: number;
  accuracyPerson: number;
  accuracyIncident: number;
  anonymization: boolean;
}

interface ComponentProps extends FormikProps<IFormInfoValues> {
  isLoading: boolean;
  admins: any[];
  usersList: any[];
}

export function InstancesInfo(): JSX.Element {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentInstance } = useAppSelector((state) => state.user);
  const usersList = useAppSelector((state) => state.groupUsers.data) || [];
  const admins = usersList.filter((user: any) => user.is_admin);

  useEffect(() => {
    if (!usersList.length) {
      dispatch(fetchGroupUsersList(currentInstance?.id));
    }
  }, []);

  return (
    <div>
      <Box>
        <SettingsPageHeader
          title={t("settings.instance")}
          description={t("settings.instanceDesc")}
        />
        <Formik<IFormInfoValues>
          initialValues={{
            name: currentInstance?.label,
            email: currentInstance?.email,
            phone: currentInstance?.phone,
            admins,
            street: currentInstance?.street,
            postCode: currentInstance?.post_code,
            city: currentInstance?.city,
            isActive: currentInstance?.is_active ? 1 : 0,
            resolution: "1920 x 1080",
            accuracyObject: 0.6,
            accuracyPerson: 0.6,
            accuracyIncident: 0.3,
            anonymization: true,
          }}
          onSubmit={async (values) => {
            setIsLoading(true);
            await dispatch(editGroup(values, currentInstance?.id));
            const newAdminsIDs = values.admins.map(admin => admin.id)
            await dispatch(editGroupAdmins(newAdminsIDs, currentInstance?.id));
            setIsLoading(false);
          }}
          component={(props) => (
              <InstancesForm {...props} isLoading={isLoading} admins={admins} usersList={usersList} />
          )}
        />
      </Box>
    </div>
  );
}

let InstancesForm: (props: ComponentProps) => JSX.Element = ({
  handleSubmit,
  setFieldValue,
  values,
  handleChange,
  handleBlur,
  isLoading,
  admins,
  usersList
}) => {
  const [removeInstanceModal, setRemoveInstanceModal] = useState(false);
  const {currentInstance} = useAppSelector((state) => state.user);
  const classes = useStyles();
  const { t } = useTranslation("common");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const resolutions = [
    "1280 x 1024",
    "1600 x 1200",
    "1920 x 1080",
    "2048 x 1536",
  ];
  const accuracy = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        <SettingsGroup title={t("instances.name")}>
          <TextField
            fullWidth
            hiddenLabel
            placeholder={t("instances.name")}
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </SettingsGroup>
        <SettingsGroup title={t("instances.contact")}>
          <SettingsGroup noBorder title="Email">
            <TextField
              fullWidth
              hiddenLabel
              placeholder="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </SettingsGroup>
          <SettingsGroup noBorder title={t("instances.phone")}>
            <TextField
              fullWidth
              hiddenLabel
              placeholder={t("instances.phone")}
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </SettingsGroup>
        </SettingsGroup>
        <SettingsGroup title={t("instances.instanceAdmin")}>
          <Autocomplete
              multiple
              getOptionLabel={(option: any) => option.first_last_name || option.email}
              defaultValue={admins}
              onChange={(e, value) => {
                setFieldValue(
                    "admins",
                    value !== null ? value : values.admins
                );
              }}
              selectOnFocus
              blurOnSelect
              handleHomeEndKeys
              options={usersList}
              renderInput={(props) => (
                  <TextField {...props} placeholder={t("instances.instanceAdminPlaceholder")} />
              )}
          />
        </SettingsGroup>
        <SettingsGroup title={t("instances.address")}>
          <SettingsGroup noBorder title={t("instances.street")}>
            <TextField
              fullWidth
              hiddenLabel
              placeholder={t("instances.street")}
              name="street"
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </SettingsGroup>
          <SettingsGroup noBorder title={t("instances.postalCode")}>
            <TextField
              fullWidth
              hiddenLabel
              placeholder={t("instances.postalCode")}
              name="postCode"
              value={values.postCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </SettingsGroup>
          <SettingsGroup noBorder title={t("instances.city")}>
            <TextField
              fullWidth
              hiddenLabel
              placeholder={t("instances.city")}
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </SettingsGroup>
        </SettingsGroup>
        <SettingsGroup noBorder title="Status">
          <Select
              fullWidth
              name="isActive"
              value={values.isActive}
              onChange={handleChange}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
          >
            <MenuItem value={1}>{t("users.active")}</MenuItem>
            <MenuItem value={0}>{t("users.inactive")}</MenuItem>
          </Select>
        </SettingsGroup>
        {/*<MockWrapper>*/}
        {/*<SettingsGroup title={t("settings.resolution")}>*/}
        {/*  <Select*/}
        {/*      fullWidth*/}
        {/*      name="resolution"*/}
        {/*      value={values.resolution}*/}
        {/*      onChange={handleChange}*/}
        {/*      input={<OutlinedInput />}*/}
        {/*      MenuProps={MenuProps}*/}
        {/*  >*/}
        {/*    {resolutions.map((name) => (*/}
        {/*        <MenuItem key={name} value={name}>*/}
        {/*          {name}*/}
        {/*        </MenuItem>*/}
        {/*    ))}*/}
        {/*  </Select>*/}
        {/*</SettingsGroup>*/}
        {/*<SettingsGroup title={t("settings.accuracy")}>*/}
        {/*  <Box className={classes.SettingsNestedInput}>*/}
        {/*    <div className={classes.SettingsNestedInputLabel}>*/}
        {/*      {t("settings.object")}*/}
        {/*    </div>*/}
        {/*    <Select*/}
        {/*        fullWidth*/}
        {/*        name="accuracyObject"*/}
        {/*        value={values.accuracyObject}*/}
        {/*        onChange={handleChange}*/}
        {/*        input={<OutlinedInput />}*/}
        {/*        MenuProps={MenuProps}*/}
        {/*    >*/}
        {/*      {accuracy.map((name) => (*/}
        {/*          <MenuItem key={name} value={name}>*/}
        {/*            {name}*/}
        {/*          </MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </Box>*/}
        {/*  <Box className={classes.SettingsNestedInput}>*/}
        {/*    <div className={classes.SettingsNestedInputLabel}>*/}
        {/*      {t("settings.person")}*/}
        {/*    </div>*/}
        {/*    <Select*/}
        {/*        fullWidth*/}
        {/*        name="accuracyPerson"*/}
        {/*        value={values.accuracyPerson}*/}
        {/*        onChange={handleChange}*/}
        {/*        input={<OutlinedInput />}*/}
        {/*        MenuProps={MenuProps}*/}
        {/*    >*/}
        {/*      {accuracy.map((name) => (*/}
        {/*          <MenuItem key={name} value={name}>*/}
        {/*            {name}*/}
        {/*          </MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </Box>*/}
        {/*  <Box className={classes.SettingsNestedInput}>*/}
        {/*    <div className={classes.SettingsNestedInputLabel}>*/}
        {/*      {t("settings.incident")}*/}
        {/*    </div>*/}
        {/*    <Select*/}
        {/*        fullWidth*/}
        {/*        name="accuracyIncident"*/}
        {/*        value={values.accuracyIncident}*/}
        {/*        onChange={handleChange}*/}
        {/*        input={<OutlinedInput />}*/}
        {/*        MenuProps={MenuProps}*/}
        {/*    >*/}
        {/*      {accuracy.map((name) => (*/}
        {/*          <MenuItem key={name} value={name}>*/}
        {/*            {name}*/}
        {/*          </MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </Box>*/}
        {/*</SettingsGroup>*/}
        {/*<SettingsGroup title={t("settings.anonymization")}>*/}
        {/*  <Toggle*/}
        {/*      text={values.anonymization ? t("settings.yes") : t("settings.no")}*/}
        {/*      id="anonymization"*/}
        {/*      isActive={values.anonymization}*/}
        {/*      onClick={handleChange}*/}
        {/*  />*/}
        {/*</SettingsGroup>*/}
        {/*</MockWrapper>*/}
        <Box
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
            maxWidth: "1010px",
            width: "100%",
          }}
          className={classes.ButtonWrapInstanceInfo}
        >
          <ConfirmButton confirmButtonText={t("settings.save")} loading={isLoading}/>
        </Box>
      </form>

      {removeInstanceModal ? (
        <RemoveInstanceModal
          instanceID={location.state}
          setIsModalActive={setRemoveInstanceModal}
        />
      ) : null}
    </>
  );
};
