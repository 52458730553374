import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../redux/hooks';
import {fetchCurrentUser, fetchCurrentUserGroups} from "../redux/reducers/user";
import { isTokenValid } from '../common/helpers';
import { authenticated } from '../redux/reducers/auth';

export const getRole = (user: any, groups: any[], id: any) => {
  return user?.is_superuser ? 'GLOBAL_ADMIN' : user?.instances_admin?.includes(id) ? 'INSTANCE_ADMIN' : 'USER';
}

export default function useAuth() {
  const dispatch = useAppDispatch();
  const { groups, data, currentInstance } = useAppSelector((state) => state.user);
  const [user, setUser] = useState<any>(data);

  useEffect(() => {
    const getCurrentUser = async () => {
      await dispatch(fetchCurrentUserGroups());
      const currentUser = await dispatch(fetchCurrentUser());
      setUser(currentUser);
      dispatch(authenticated(currentUser));
    }
    if(!user) {
      if(isTokenValid()) {
        getCurrentUser();
      }
    }
  }, []);

  return getRole(user, groups, currentInstance?.id);
}
