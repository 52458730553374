import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import { TextField } from '@mui/material';
import { Box } from "@mui/system";
import ConfirmButton from "../../../../components/ConfirmButton";
import SettingsGroup from "../../../../components/SettingsGroup";
import { useStyles } from "../../styles";
import { Formik, FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { editInOutBoundaries } from '../../../../redux/reducers/camera';
import LoadingButton from '@mui/lab/LoadingButton';

export interface IAddInstanceModal {
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  setIsDrawingActive: Dispatch<SetStateAction<boolean>>;
  currentContour: {
    contour: number[] | undefined,
    name: string,
    index: number
  };
}

interface IFormAddInstanceValues {
  name: string;
}

interface ComponentProps extends FormikProps<IFormAddInstanceValues> {
  setIsDrawingActive: Dispatch<SetStateAction<boolean>>;
  currentContour: {
    contour: number[] | undefined,
    name: string,
    index: number
  };
  isLoading: boolean,
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
}

export function InOutForm({
                            setIsModalActive,
                            setIsDrawingActive,
                            currentContour,
                          }: IAddInstanceModal): JSX.Element {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const allLines = useAppSelector(state => state.camera.inOutBoundaries);
  const camera = useAppSelector(state => state.camera.current);

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("safetyAreas.addLine")}
      description={t("safetyAreas.lineManagement")}
      confirmButtonText={t("users.add")}
    >
      <div className={classes.borderFormWrap}>
        <Formik<IFormAddInstanceValues>
          initialValues={{
            name: currentContour.name,
          }}
          onSubmit={async (values) => {
            setIsLoading(true)
            // mock camera id
            const lines =  allLines ? [...allLines] : []
            const newLine = {
              name: values.name,
              in_out_boundary: currentContour.contour,
            }

            if(currentContour.index === undefined) {
              lines.push(newLine)
            } else {
              lines[currentContour.index] = newLine;
            }

            await dispatch(editInOutBoundaries(camera, lines))
            setIsLoading(false)
            setIsModalActive(false);
          }}
          component={(props) => <AddInstanceForm {...props}
                                                 setIsDrawingActive={setIsDrawingActive}
                                                 currentContour={currentContour}
                                                 isLoading={isLoading}
                                                 setIsModalActive={setIsModalActive} />}
        />
      </div>
    </Modal>
  );
}


let AddInstanceForm: (
  props: ComponentProps
) => JSX.Element = ({
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setIsDrawingActive,
                      currentContour,
                      setIsModalActive,
                      isLoading
                    }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const allLines = useAppSelector(state => state.camera.inOutBoundaries);
  const camera = useAppSelector(state => state.camera.current);

  const handleLineDelete = (index: number) => {
    if(window.confirm(t('safetyAreas.deleteConfirmation'))) {
      const array = [...allLines]
      array.splice(index, 1);
      dispatch(editInOutBoundaries(camera, array))
      setIsModalActive(false);
    }
  }

  return (
    <>
      <form  onSubmit={handleSubmit} style={{position: 'relative', width: '100%'}}>
        <SettingsGroup noBorder title={t("safetyAreas.lineName")}>
          <TextField
            fullWidth
            helperText={touched.name ? errors.name : null}
            hiddenLabel
            placeholder={t("safetyAreas.lineName")}
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </SettingsGroup>
        <SettingsGroup title={t("safetyAreas.lineEditor")} noBorder>

          {(currentContour.contour === undefined) &&
          <ConfirmButton
            button
            confirmButtonText={t("safetyAreas.add")}
            handleOnClick={() => setIsDrawingActive(true)}
          />
          }
          {(currentContour.contour !== undefined) &&
          <div className={classes.areaAddingActions}>
            <ConfirmButton
              button
              confirmButtonText={t("safetyAreas.line")}
              handleOnClick={() => setIsDrawingActive(true)}
            />
            <div className={classes.areaAddingActionsDelete}>
              <div onClick={() => setIsDrawingActive(true)}>{t("safetyAreas.update")}</div>
            </div>
          </div>
          }
        </SettingsGroup>

        <Box className={classes.areaActionsWrap} style={{ position: "absolute", bottom: "-80px", right: "-30px" }}>
          <ConfirmButton button disabled={isLoading} confirmButtonBackgroundColor="danger" confirmButtonText={t("settings.delete")} handleOnClick={() => handleLineDelete(currentContour.index)}/>
          <LoadingButton loading={isLoading} disabled={!values.name || !currentContour.contour} variant="contained" color="success" type="submit">
            {t("settings.save")}
          </LoadingButton>
        </Box>

      </form>
    </>
  );
};
