import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Status from "../../Status";
import ConfirmButton from "../../ConfirmButton";
import { CircularProgress, TableHead } from "@mui/material";
import { Box } from "@mui/system";
import {IInstance} from "../../../types/interfaces";
import MockWrapper from "../../MockWrapper";

export interface IInstancesList {
  tableContent: IInstance[];
  dark: boolean;
  handleRedirectButton?: any;
}
function InstancesList({ handleRedirectButton, tableContent }: IInstancesList) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Box sx={{ p: 2, pt: 0 }}>
      {tableContent ? (
        <TableContainer>
          <Table
            style={{
              borderCollapse: "separate",
              borderSpacing: "0px 10px",
              width: "98%",
              margin: "0 auto",
            }}
            sx={{ minWidth: 650 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>{t("instances.company")}</TableCell>
                <TableCell align="left">{t("instances.cameras")}</TableCell>
                <TableCell align="left">{t("instances.users")}</TableCell>
                <TableCell align="left">{t("instances.status")}</TableCell>
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContent.map((cell: any) => (
                <TableRow
                  key={cell.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                  >
                    <div>
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>
                        {cell.name}
                      </div>
                      <div style={{ color: "#636F7D", fontSize: "14px" }}>
                        {cell.email}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="left"
                  >
                    <MockWrapper>
                      {cell.camera_count || '-'}
                    </MockWrapper>
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="left"
                  >
                    {cell.user_count || '-'}
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="left"
                  >
                    {" "}
                    <Status
                      dotColor={cell.is_active ? "green" : "red"}
                      text={
                        cell.is_active ? t("users.active") : t("users.inactive")
                      }
                    />
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="right"
                  >
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <ConfirmButton
                        confirmButtonText={t("instances.logIn")}
                        confirmButtonColor="#000"
                        dark={false}
                        handleOnClick={() =>
                          handleRedirectButton(cell)
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default InstancesList;
