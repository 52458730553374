import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

import Status from "../../Status";
import { useStyles } from "../styles";
import ConfirmButton from "../../ConfirmButton";

export interface IPaymentsList {
  tableContent: Array<object>;
}
function PaymentsList({ tableContent }: IPaymentsList) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <TableContainer>
      <Table
        style={{
          borderCollapse: "separate",
          borderSpacing: "0px 10px",
          width: "98%",
          margin: "0 auto",
        }}
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>{t("payments.invoice")}</TableCell>
            <TableCell align="left">{t("payments.sum")}</TableCell>
            <TableCell align="left">{t("payments.date")}</TableCell>
            <TableCell align="left">{t("payments.status")}</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableContent.map((cell: any) => (
            <TableRow
              key={cell.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell className={classes.TableCell}>{cell.name}</TableCell>
              <TableCell className={classes.TableCell} align="left">
                {cell.sum} zł
              </TableCell>
              <TableCell className={classes.TableCell} align="left">
                {cell.date}
              </TableCell>
              <TableCell className={classes.TableCell} align="left">
                <div style={{ display: "flex" }}>
                  <Status
                    dotColor={cell.active ? "green" : "red"}
                    text={
                      cell.active ? t("payments.paid") : t("payments.unpaid")
                    }
                  />
                </div>
              </TableCell>

              <TableCell className={classes.TableCell} align="right">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ConfirmButton
                    confirmButtonText="Pobierz"
                    confirmButtonColor="#000"
                    handleOnClick={() => console.log("Pobierz")}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PaymentsList;
