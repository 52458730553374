import React, {  useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { useAppSelector } from '../../redux/hooks';

export interface IAddInstanceModal {
  updateAreaContour: any;
  imageSize: any;
  pointsLimit?: number;
}

export function CanvasDrawing({
                                updateAreaContour,
                                imageSize,
                                pointsLimit,
                              }: IAddInstanceModal): JSX.Element {

  // states
  const [drawingCanvas, setDrawingCanvas] = useState<any>(null);

  const min = 99;
  const max = 999999;
  let polygonMode = true;
  let pointArray = new Array();
  let lineArray = new Array();
  let activeLine: any;
  let activeShape: any = false;

  useEffect(() => {
    if(imageSize) {
      setDrawingCanvas(initDrawingCanvas());
    }
  }, [imageSize]);

  useEffect(() => {
    if(drawingCanvas) {
      drawingCanvas.on('mouse:down', function (options: any) {
        if(options.target && options.target.id == pointArray[0].id){
          drawingCanvas.polygon.generatePolygon(pointArray);
        }
        // close polygon when 4 points created -- todo -- delete when API supports more anchors
        if(pointArray.length === (pointsLimit ? pointsLimit - 1 : 3)) {
          drawingCanvas.polygon.addPoint(options);
          drawingCanvas.polygon.generatePolygon(pointArray);
        }
        if(polygonMode){
          drawingCanvas.polygon.addPoint(options);
        }
      });
      drawingCanvas.on('mouse:move', function (options: any) {
        if(activeLine && activeLine.class == "line"){
          var pointer = drawingCanvas.getPointer(options.e);
          activeLine.set({ x2: pointer.x, y2: pointer.y });

          var points = activeShape.get("points");
          points[pointArray.length] = {
            x:pointer.x,
            y:pointer.y
          }
          activeShape.set({
            points: points
          });
          drawingCanvas.renderAll();
        }
        drawingCanvas.renderAll();
      });

      drawingCanvas.polygon = {
        drawPolygon : function() {
          polygonMode = true;
          pointArray = new Array();
          lineArray = new Array();
          activeLine = null;
        },
        addPoint : function(options: any) {
          const random = Math.floor(Math.random() * (max - min + 1)) + min;
          const id = new Date().getTime() + random;
          const circle = new fabric.Circle({
            radius: 5,
            fill: '#ffffff',
            stroke: '#333333',
            strokeWidth: 0.5,
            left: (options.e.layerX/drawingCanvas.getZoom()),
            top: (options.e.layerY/drawingCanvas.getZoom()),
            selectable: false,
            hasBorders: false,
            hasControls: false,
            originX:'center',
            originY:'center',
            // @ts-ignore
            id:id,
            objectCaching:false
          });
          if(pointArray.length == 0){
            circle.set({
              fill:'white'
            })
          }
          var points = [(options.e.layerX/drawingCanvas.getZoom()),(options.e.layerY/drawingCanvas.getZoom()),(options.e.layerX/drawingCanvas.getZoom()),(options.e.layerY/drawingCanvas.getZoom())];
          const line = new fabric.Line(points, {
            strokeWidth: 4,
            fill: '#d81b60',
            stroke: '#d81b60',
            // @ts-ignore
            class:'line',
            originX:'center',
            originY:'center',
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false,
            objectCaching:false
          });
          if(activeShape){
            var pos = drawingCanvas.getPointer(options.e);
            // @ts-ignore
            var points = activeShape.get("points");
            // @ts-ignore
            points.push({
              x: pos.x,
              y: pos.y
            });
            // @ts-ignore
            var polygon = new fabric.Polygon(points,{
              stroke:'#333333',
              strokeWidth:2,
              fill: '#cccccc',
              opacity: 0.3,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false,
              objectCaching:false
            });
            drawingCanvas.remove(activeShape);
            drawingCanvas.add(polygon);
            activeShape = polygon;
            drawingCanvas.renderAll();
          }
          else{
            var polyPoint = [{x:(options.e.layerX/drawingCanvas.getZoom()),y:(options.e.layerY/drawingCanvas.getZoom())}];
            var polygon = new fabric.Polygon(polyPoint,{
              stroke:'#333333',
              strokeWidth:2,
              fill: '#cccccc',
              opacity: 0.3,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false,
              objectCaching:false
            });
            activeShape = polygon;
            drawingCanvas.add(polygon);
          }
          activeLine = line;

          pointArray.push(circle);
          lineArray.push(line);

          drawingCanvas.add(line);
          drawingCanvas.add(circle);
          drawingCanvas.selection = false;
        },
        generatePolygon : function(pointArray: any){
          let points = new Array();
          pointArray.forEach((point: any) => {
            points.push({
              x:point.left,
              y:point.top
            });
            drawingCanvas.remove(point);
          });
          lineArray.forEach((line: any) => {
            drawingCanvas.remove(line);
          });
          drawingCanvas.remove(activeShape).remove(activeLine);
          let polygon = new fabric.Polygon(points,{
            fill: 'rgba(216,27,96,0.5)',
            strokeWidth: 4,
            stroke: '#d81b60',
            opacity: 1,
            hasBorders: false,
            hasControls: false,
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
          });
          drawingCanvas.add(polygon);

          activeLine = null;
          activeShape = null;
          polygonMode = false;
          updateAreaContour(points)
        }
      };


    }
  }, [drawingCanvas])


  const initDrawingCanvas = () => (
    new fabric.Canvas('drawingCanvas', {
      height: imageSize.height,
      width: imageSize.width,
      selection: false,
    })
  )

  return (
    <canvas id="drawingCanvas" />
  );
}
