import React from "react";
import { Box } from "@mui/material";
import { PageTitleBar } from "../../components/PageTitleBar";
import { SettingsNav } from "./SettingsNav";
import { Tile } from "../../components/Tile";
import { Outlet } from "react-router-dom";
import { useStyles } from "./styles";
import { useTranslation } from 'react-i18next';

export function Settings(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <Box sx={{ p: 2, pt: 4 }}>
      <PageTitleBar title={t("navigation.settings")} />
      <Box className={classes.SettingsWrap}>
        <Box className={classes.SettingsNav}>
          <SettingsNav />
        </Box>
        <Box className={classes.SettingsContent}>
          <Tile>
            <Box sx={{ p: 1 }}>
              <Outlet />
            </Box>
          </Tile>
        </Box>
      </Box>
    </Box>
  );
}
