import React from "react";
import { useStyles } from "./styles";
interface IStatus {
  dotColor: string;
  text: string;
}
function Status({ dotColor, text }: IStatus) {
  const classes = useStyles();
  return (
    <div className={classes.StatusWrapper}>
      <div className={classes.Status}>
        <span
          style={{
            backgroundColor:
              dotColor === "green"
                ? "#4FF47E"
                : dotColor === "orange"
                ? "#F9963B"
                : "",
          }}
          className={classes.RedDot}
        ></span>
        {text}
      </div>
    </div>
  );
}

export default Status;
