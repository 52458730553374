import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  RoundedButton: {
    '&.MuiButton-root': {
      borderRadius: '50px',
      boxShadow: 'none',
      textTransform: 'none',
      height: '36px',
    },
  },
  RoundedIconButton: {
    '&.MuiButton-root': {
      borderRadius: '50px',
      boxShadow: 'none',
      textTransform: 'none',
      padding: '5px',
      minWidth: '36px',
      height: '36px',
    },
  },
}));
