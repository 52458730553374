import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  Wrap: {
    borderBottom: '1px solid #B8C7D8',
  },
  Title: {
    fontSize: "24px",
  },
  Description: {
    fontWeight: '300',
    opacity: '.6',
    marginBottom: '20px',
  }
}));
