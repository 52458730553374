import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

import binIcon from "../../../assets/icons/bin.png";
import Status from "../../Status";
import { useStyles } from "../styles";
import ConfirmButton from "../../ConfirmButton";

export interface ICamerasList {
  handleConfirmButton?: Function;
  handleRedirectButton?: any;
  handleRemoveButton?: Function;
  tableContent: Array<object>;
  confirmButtonText: string;
  dark: boolean;
}
function CamerasList({
  handleConfirmButton,
  handleRedirectButton,
  handleRemoveButton,
  tableContent,
  confirmButtonText,
  dark,
}: ICamerasList) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <TableContainer>
      {!tableContent &&
      <div>No cameras</div>
      }
      {tableContent &&
      <Table
        style={{
          borderCollapse: "separate",
          borderSpacing: "0px 10px",
          width: "98%",
          margin: "0 auto",
        }}
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead style={{ backgroundColor: dark ? "#fff" : "#DFE4EA" }}>
          <TableRow>
            <TableCell>{t("homepage.name")}</TableCell>
            <TableCell align="left">{t("homepage.room")}</TableCell>
            <TableCell align="left">{t("users.status")}</TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableContent.map((cell: any) => (
            <TableRow
              key={cell.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{ backgroundColor: dark ? "#DFE4EA" : "" }}
                className={classes.TableCell}
              >
                {cell.name}
              </TableCell>
              <TableCell
                style={{ backgroundColor: dark ? "#DFE4EA" : "" }}
                className={classes.TableCell}
                align="left"
              >
                {cell.description}
              </TableCell>
              <TableCell
                style={{ backgroundColor: dark ? "#DFE4EA" : "" }}
                className={classes.TableCell}
                align="left"
              >
                <div style={{ display: "flex" }}>
                  <Status
                    dotColor={cell.live_feed_enabled ? "green" : "red"}
                    text={
                      cell.live_feed_enabled ? t("homepage.live") : t("users.inactive")
                    }
                  />
                  {cell.danger !== undefined && (
                    <Status
                      dotColor={cell.danger ? "orange" : "green"}
                      text={
                        cell.danger ? t("camera.danger") : t("camera.noDanger")
                      }
                    />
                  )}
                </div>
              </TableCell>
              <TableCell
                style={{ backgroundColor: dark ? "#DFE4EA" : "" }}
                className={classes.TableCell}
                align="left"
              ></TableCell>
              <TableCell
                style={{ backgroundColor: dark ? "#DFE4EA" : "" }}
                className={classes.TableCell}
                align="right"
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* {handleRemoveButton && (
                    <button
                      onClick={() => handleRemoveButton(true)}
                      className={classes.RemoveBTN}
                    >
                      <img src={binIcon} alt="bin icon" />
                    </button>
                  )} */}
                  <ConfirmButton
                    confirmButtonText={confirmButtonText}
                    confirmButtonColor="#000"
                    handleOnClick={() =>
                      handleConfirmButton
                        ? handleConfirmButton(cell.id)
                        : handleRedirectButton(`/camera/${cell.id}/liveView`)
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      }

    </TableContainer>
  );
}

export default CamerasList;
