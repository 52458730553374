import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  ModalContainer: {
    position:'fixed',
    width: '100%',
    height: '100%',
    zIndex: '10000003',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
  },
  ModalBCG: {
    position:'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.38)',
    backdropFilter: 'blur(8px)',
    zIndex: '10000002',
    top: '0',
    left: '0',
  },
  ModalWindow: {
    backgroundColor: '#fff',
    maxHeight: '800px',
    width: '800px',
    overflowY: 'auto',
    filter: 'none',
    borderRadius: '18px',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    },
    CloseBTN: {
      justifySelf: 'flex-end',
      alignSelf: 'flex-end',
      width: '18px',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '30px',
      cursor: 'pointer',
      position: 'absolute',
      right: '20px',
    },
    ModalTitle: {
      fontSize: '28px',
      fontWeight: 'normal',
    },
    ModalDescription: {
      fontWeight: '300',
      fontSize: '16px',
      padding: '0 0 20px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
      ButtonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
      zIndex: 30,
      width: '100px',
      },
    BackBTN: {
      color: 'rgba(0, 0, 0, 0.44)',
      fontSize: '16px',

      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
    ConfirmBTN: {
      background: '#2FB299',
      borderRadius: '18px',
      color: '#fff',
      width: '100px',
      height: '30px',
      border: 'none',
      cursor: 'pointer',
    }

}));
