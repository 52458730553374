import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  StatusWrapper: {
    minWidth: '130px'
  },
    Status: {
        backgroundColor: '#7f8285',
        borderRadius: '40px',
        color: '#fff',
        padding: '2px 8px',
        border: 'none',
        fontSize: '15px',
        width: 'fit-content',
      },
      RedDot: {
        width: '11px',
        height: '11px',
        background: '#F54F4E',
        display: 'inline-block',
        margin: '0 5px 0 0',
        borderRadius: '50%',
      },


}));
