import React from "react";
import { useStyles } from "./styles";
import closeButton from "../../assets/icons/closeButton.png";
import { useTranslation } from "react-i18next";

export interface IModal {
  title: string;
  description: string;
  children: any;
  confirmButtonColor?: string;
  confirmButtonBackgroundColor?: string;
  confirmButtonText: string;
  isModalActive?: boolean;
  setIsModalActive: any;
}
function Modal({
  title,
  description,
  children,
  confirmButtonColor,
  confirmButtonBackgroundColor,
  confirmButtonText,
  setIsModalActive,
}: IModal) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <>
      <div className={classes.ModalBCG} />
      <div className={classes.ModalContainer}>
        <div className={classes.ModalWindow}>
          <button
            onClick={() => setIsModalActive(false)}
            className={classes.CloseBTN}
          >
            <img
              className={classes.CloseBTN}
              src={closeButton}
              alt="close button"
            />
          </button>
          <h3 className={classes.ModalTitle}>{title}</h3>
          <p className={classes.ModalDescription}>{description}</p>
          <div>{children}</div>
          <div className={classes.ButtonsWrapper}>
            <button
              onClick={() => setIsModalActive(false)}
              className={classes.BackBTN}
            >
              {t("users.back")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
