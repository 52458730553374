import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useTranslation} from "react-i18next";
import SettingsPageHeader from "../../../components/SettingsPageHeader";
import SettingsGroup from '../../../components/SettingsGroup';
import Toggle from '../../../components/Toggle';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {handleShowAlerts, handleShowMocks, handlePlaySounds} from '../../../redux/reducers/user';

export function SettingsSystem(): JSX.Element {
  const [showMocks, setShowMocks] = useState<boolean>(useAppSelector((state) => state.user.showMocks));
  const [showAlerts, setShowAlerts] = useState<boolean>(useAppSelector((state) => state.user.showAlerts));
  const [playSounds, setPlaySounds] = useState<boolean>(useAppSelector((state) => state.user.playSounds));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(handleShowMocks(showMocks))
  }, [showMocks])

  useEffect(() => {
    dispatch(handleShowAlerts(showAlerts))
  }, [showAlerts])

  useEffect(() => {
    dispatch(handlePlaySounds(playSounds))
  }, [playSounds])

  const { t } = useTranslation("common");

  return (
    <Box>
      <SettingsPageHeader
        title={t("settings.system")}
        description={t("settings.systemDescription")}
      />

      <SettingsGroup title={t("settings.showMocks")}>
        <Toggle
          text={showMocks ? t("settings.yes") : t("settings.no")}
          id="anonymization"
          isActive={showMocks}
          onClick={() => setShowMocks(!showMocks)}
        />
      </SettingsGroup>

      <SettingsGroup title={t("settings.playSounds")}>
        <Toggle
          text={playSounds ? t("settings.yes") : t("settings.no")}
          id="anonymization"
          isActive={playSounds}
          onClick={() => setPlaySounds(!playSounds)}
        />
      </SettingsGroup>
    </Box>
  );
}
