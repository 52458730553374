import {createSlice} from '@reduxjs/toolkit';
import { getToken } from '../../common/helpers';
import { REACT_APP_BACKEND_URL } from '../../common/config';
import { IAlertsList } from '../../types/interfaces';

interface GroupsUsersState {
    list: IAlertsList | null,
    latestAlert: any,
    currentAlert: any,
}

const initialState: GroupsUsersState = {
    list: null,
    latestAlert: null,
    currentAlert: null,
}

export const alerts = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlerts: (state, action) => {
            state.list = action.payload
        },
        addNewAlert: (state, action) => {
            state.latestAlert = action.payload
        },
        setCurrentAlert: (state, action) => {
          state.currentAlert = action.payload
        }
    },
})

export const { addNewAlert, setAlerts, setCurrentAlert } = alerts.actions

export const fetchAlertsList = () => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/alert/list`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((alertsList) => {
                                dispatch(setAlerts(alertsList));
                                return alertsList;
                            }
                        );
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const handleNewAlert = (data: any) => {
    return (dispatch: any) => {
        dispatch(addNewAlert(data))
    }
}

export const fetchAlert = (id: string) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/alert/list_between?id_from=${id}&id_to=${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
          .then((res) => {
              if (res.ok) {
                  return res
                    .json()
                    .then((data) => {
                          dispatch(setCurrentAlert(data.notifications[0]));
                          return data;
                      }
                    );
              } else {
                  return res.json().then(() => {
                      return false
                  });
              }
          });
    };
};

export const fetchAlertsBetween = (from: number, to: number) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/alert/list_between?id_from=${from}&id_to=${to}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((data) => {
                const revertedAlerts = {
                  ...data,
                  notifications: data.notifications.reverse()
                }
                dispatch(setAlerts(revertedAlerts));
                return data;
              }
            );
        } else {
          return res.json().then(() => {
            return false
          });
        }
      });
  };
};

export default alerts.reducer
