import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal";

export interface IRemoveCameraModal {
  setIsModalActive: any;
}

export function RemoveCameraModal({ setIsModalActive }: IRemoveCameraModal) {
  const { t } = useTranslation("common");
  //   const classes = useStyles();

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("cameras.removeCamera")}
      description=""
      children=""
      confirmButtonText={t("users.remove")}
      confirmButtonBackgroundColor="danger"
    />
  );
}
