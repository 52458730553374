import {createSlice} from '@reduxjs/toolkit'
import {REACT_APP_BACKEND_URL} from "../../common/config";
import {addUserToGroup, removeUserData} from "./user";
import {toast} from "react-toastify";
import {deleteToken, getToken, setToken} from "../../common/helpers";
import {fetchGroupUsersList} from './groupUsers';
import i18next from "i18next";
import jwt_decode, { JwtPayload } from 'jwt-decode';

interface AuthState {
    authChecked: boolean,
    loggedIn: boolean,
    currentUser: any,
    error: any,
}

const initialState: AuthState = {
    authChecked: false,
    loggedIn: false,
    currentUser: {},
    error: null,
}

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.authChecked = true
            state.loggedIn = true
            state.currentUser = action.payload
            state.error = null
        },
        notAuthenticated: (state, action ) => {
            state.authChecked = true
            state.loggedIn = false
            state.currentUser = {}
            state.error = action.payload
        },
    },
})

export const { authenticated, notAuthenticated } = auth.actions

const runLogoutTimer = (token: any) => {
    const decodedToken: any = jwt_decode<JwtPayload>(token);
    const timer =  decodedToken.exp * 1000 - new Date().getTime();
    setTimeout(() => {
        deleteToken()
        window.location.href = '/login'
    }, timer)
}

export const loginUser = (payload: any) => {
    const data = new FormData();
    for(const name in payload) {
        data.append(name, payload[name]);
    };
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/auth/jwt/login`, {
            method: "POST",
            body: data
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((userJson) => {
                                setToken(userJson.access_token);
                                runLogoutTimer(userJson.access_token);
                                dispatch(authenticated(userJson));
                                return true;
                            }
                        );
                } else {
                    return res.json().then((errors) => {
                        dispatch(notAuthenticated(errors.detail))
                        return false
                    });
                }
            });
    };
};

export const logoutUser = () => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/auth/jwt/logout`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        }).then((res) => {
            deleteToken()
            //toast.dismiss();
            if (res.ok) {
                return res.json()
                    .then(() => dispatch(notAuthenticated(null)))
                    .then(() => dispatch(removeUserData()))
            } else {
                return res.json().then((errors) => {
                    //dispatch(notAuthenticated(errors))
                    return Promise.reject(errors)
                })
            }
        })
    }
}

export const forgotPassword = (email: string | undefined) => {
    return () => {
        return fetch(`${REACT_APP_BACKEND_URL}/auth/forgot-password`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email
            })
        })
            .then((res) => {
                return res.ok;
            });
    };
};


export const resetPassword = (payload: any) => {
    return () => {
        return fetch(`${REACT_APP_BACKEND_URL}/auth/reset-password`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: payload.token,
                password: payload.password,
            })
        })
            .then((res) => {
                return res.ok;
            });
    };
};

export const createUser = (payload: any, groupID: any) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/auth/create`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },

            body: JSON.stringify({
                email: payload.email,
                group_id: groupID,
                is_active: true,
            })
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchGroupUsersList(groupID))
                    toast.success(i18next.t("common:toastInfo.userAdded"));
                    return res.ok
                } else if (res.status === 409) {
                    dispatch(addUserToGroup(payload.email, groupID));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.userAddedError"));
                        return false
                    });
                }
            });
    };
};

export default auth.reducer
