import { MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmButton from "../../../../../components/ConfirmButton";
import Modal from "../../../../../components/Modal";
import SettingsGroup from "../../../../../components/SettingsGroup";
import { useStyles } from "../../../styles";
import { Formik, FormikProps } from "formik";
import {decode as base64_decode} from "base-64";
import Avatar from "react-avatar";
import { editUser} from "../../../../../redux/reducers/user";
import {fetchGroupUsersList} from "../../../../../redux/reducers/groupUsers";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import MockWrapper from '../../../../../components/MockWrapper';

export interface IEditUserModal {
  setIsModalActive: any;
  user: any;
}

interface IFormEditUserValues {
  email: string;
  availableCameras: string;
  status: number;
}

export function EditUserModal({
  setIsModalActive,
  user,
}: IEditUserModal): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const {currentInstance} = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const editUserDetails = async (values: any) => {
    await dispatch(editUser(values, user.id));
    setIsModalActive(false);
    dispatch(fetchGroupUsersList(currentInstance?.id));
  }

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("users.editUser")}
      description={t("users.editUserDesc")}
      confirmButtonText={t("users.save")}
    >
      <div className={classes.RemoveUserWrapper}>
        <div className={classes.EditUserWrapper}>
          <div className={classes.UserAvatar}>
            {user.photo ? (
                <img src={base64_decode(user.photo)} alt="user avatar" />
            ) : (
                <Avatar
                    size="50"
                    color="#2FB299"
                    name={user.first_last_name}
                />
            )}
          </div>
          <div className={classes.UserName}>
            {user.first_last_name}
            <div style={{ color: "#636F7D", fontSize: "14px" }}>
              {user.email}
            </div>
          </div>
        </div>
        <Formik<IFormEditUserValues>
          initialValues={{
            email: user.email,
            availableCameras: "Wszystkie",
            status: user.is_active ? 1 : 0,
          }}
          onSubmit={(values) => editUserDetails(values)}
          component={EditUserModalForm}
        />
      </div>
    </Modal>
  );
}

let EditUserModalForm: (
  props: FormikProps<IFormEditUserValues>
) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const availableCameras = ["Wszystkie", "Magazyn 1", "Magazyn 2"];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
      <SettingsGroup noBorder title="E-mail">
        <TextField
          fullWidth
          helperText={touched.email ? errors.email : null}
          hiddenLabel
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled
        />
      </SettingsGroup>
      <MockWrapper>
        <SettingsGroup noBorder title="Dostępne kamery">
          <Select
            fullWidth
            name="availableCameras"
            value={values.availableCameras}
            onChange={handleChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            {availableCameras.map((camera) => (
              <MenuItem key={camera} value={camera}>
                {camera}
              </MenuItem>
            ))}
          </Select>
        </SettingsGroup>
      </MockWrapper>

      <SettingsGroup noBorder title="Status">
        <Select
          fullWidth
          name="status"
          value={values.status}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          <MenuItem value={1}>{t("users.active")}</MenuItem>
          <MenuItem value={0}>{t("users.inactive")}</MenuItem>
        </Select>
      </SettingsGroup>
      <Box
        style={{ position: "absolute", right: "50px", bottom: "50px" }}
        className={classes.ButtonWrap}
      >
        <ConfirmButton confirmButtonText={t("settings.save")} />
      </Box>
    </form>
  );
};
