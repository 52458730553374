import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';

export interface IPageTitleBar {
  title: string;
};

export function PageTitleBar({title}: IPageTitleBar): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.TitleBar}>{title}</Box>
  );
}
