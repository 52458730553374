import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
    AlertWrap: {
        borderRadius: '8px',
        padding: '10px',
        margin: '10px 0 0',
        display: 'flex',
        alignItems: 'center',
        color: '#000',
        transition: '.2s',
        '& *': {
            textDecoration: 'none',
        },
    },
    AlertIcon: {
        height: '60px',
        width: '60px',
        backgroundColor: '#ffd6d6',
        borderRadius: '50%',
        textAlign: 'center',
        marginRight: '20px',
        position: 'relative',
        '& img': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    },
    AlertIconWarning: {
        height: '60px',
        width: '60px',
        backgroundColor: '#f5964e38',
        borderRadius: '50%',
        textAlign: 'center',
        marginRight: '10px',
        position: 'relative',
        '& img': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    },
    AlertContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '18px',
    },
    AlertHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
    },
    AlertName: {
        color: '#f5964e',
    },
    AlertNameCritical: {
        color: '#F54F4E',
    },
    AlertNameInfo: {
        color: 'blue',
    },
    AlertButtonsWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        marginLeft: '-5px',
        '& .MuiButton-text': {
            color: 'rgba(0,0,0,0.7)',
        }
    },
    AlertInstance: {
        fontWeight: '500',
        color: '#F54F4E',
    }
}));
