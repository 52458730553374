import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  Tile: {
    backgroundColor: '#DFE4EA',
    borderRadius: '8px',
    padding: '12px',
    ['@media (max-width: 1300px)']: {
      padding: '6px',
    }
  },
}));
