import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmButton from "../../../../../components/ConfirmButton";
import Modal from "../../../../../components/Modal";
import { useStyles } from "../../../styles";
import {decode as base64_decode} from "base-64";
import Avatar from "react-avatar";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import { deleteUser } from "../../../../../redux/reducers/user";
import {fetchGroupUsersList} from "../../../../../redux/reducers/groupUsers";

export interface IRemoveUserModal {
  setIsModalActive: any;
  user: any;
}

export function RemoveUserModal({
  setIsModalActive,
  user,
}: IRemoveUserModal): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const {currentInstance} = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const removeUser = async () => {
    await dispatch(deleteUser(user.id));
    setIsModalActive(false);
    dispatch(fetchGroupUsersList(currentInstance?.id));
  }

  return (
    <Box sx={{ p: 2, pt: 4 }}>
      <Modal
        setIsModalActive={setIsModalActive}
        title={t("users.removeUser")}
        description={t("users.removeUserDesc")}
        confirmButtonText={t("users.remove")}
        confirmButtonBackgroundColor="danger"
      >
        <div
          style={{ display: "flex", flexDirection: "row", margin: "40px 0" }}
          className={classes.RemoveUserWrapper}
        >
          <div className={classes.UserAvatar}>
            {user.photo ? (
                <img src={base64_decode(user.photo)} alt="user avatar" />
            ) : (
                <Avatar
                    size="50"
                    color="#2FB299"
                    name={user.first_last_name}
                />
            )}
          </div>
          <div className={classes.UserName}>
            {user.first_last_name}
            <div style={{ color: "#636F7D", fontSize: "14px" }}>
              {user.email}
            </div>
          </div>
          <Box
            style={{ position: "absolute", right: "50px", bottom: "50px" }}
            className={classes.ButtonWrap}
          >
            <ConfirmButton
              confirmButtonText={t("users.remove")}
              confirmButtonBackgroundColor="danger"
              handleOnClick={removeUser}
            />
          </Box>
        </div>
      </Modal>
    </Box>
  );
}
