import React, { useState } from 'react';
import { Box, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsPageHeader from '../../../components/SettingsPageHeader';
import SettingsGroup from '../../../components/SettingsGroup';
import ConfirmButton from '../../../components/ConfirmButton';
import {FormikProps} from 'formik';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import CustomAlert from "../../../components/CustomAlert";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {forgotPassword} from "../../../redux/reducers/auth";

interface IFormValues {
  password: string,
  confirmPassword: string,
}

export function SettingsPassword(): JSX.Element {
    const [sendingSuccess, setSendingSuccess] = useState<boolean>(false);
    const [sendingError, setSendingError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const userProfileData = useAppSelector((state) => state.user.data);
    const {t} = useTranslation("common");
    const dispatch = useAppDispatch();

    const handleResetPassword = async () => {
        setIsLoading(true);
        const response = await dispatch(forgotPassword(userProfileData?.email));
        // @ts-ignore
        if (response) {
            setSendingSuccess(true);
        } else {
            setSendingError(true);
        }
        setIsLoading(false);
    }

    return (
        <Box>
            <SettingsPageHeader title={t("settings.password")} description={t("settings.description.password")}/>
            <SettingsGroup title={t("settings.passwordReset")} description={t("settings.passwordResetDescription")}>
                <ConfirmButton
                    confirmButtonText={t("settings.reset")}
                    handleOnClick={handleResetPassword}
                    loading={isLoading}
                />
            </SettingsGroup>
            {sendingSuccess &&
                <CustomAlert severity="success" title={t("logIn.success")}>
                    {t("logIn.forgotPasswordSuccess")}
                </CustomAlert>
            }
            {sendingError &&
                <CustomAlert severity="error" title={t("logIn.error")}>
                    {t("logIn.loginErrorUnknown")}
                </CustomAlert>
            }
        </Box>
    );
};
let SettingsProfileForm: (props: FormikProps<IFormValues>) => JSX.Element =
  ({
     handleSubmit,
     values,
     errors, touched,
     handleChange,
     handleBlur}) => {

    const { t } = useTranslation("common");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    return (
      <form onSubmit={handleSubmit}>

        <SettingsGroup title={t("settings.newPassword")} noBorder>
          <OutlinedInput
            fullWidth
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("settings.newPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="password-helper-text">{t("settings.newPasswordHelpText")}</FormHelperText>
        </SettingsGroup>

        <SettingsGroup title={t("settings.confirmNewPassword")} noBorder>
          <OutlinedInput
            fullWidth
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("settings.confirmNewPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </SettingsGroup>
      </form>
    )}
