import React, {useEffect, useState} from "react";
import {Box, Drawer, IconButton, List, ListItemIcon, Menu, MenuItem,} from "@mui/material";
import {useStyles} from "./styles";
import logoLong from "../../assets/logo/logo-aiss-long.png";
import logoShort from "../../assets/logo/logo-aiss-short.png";
import camerasIcon from "../../assets/icons/camerasIcon.png";
import dashboardIcon from "../../assets/icons/dashboardIcon.png";
import alertsIcon from "../../assets/icons/alertsIcon.png";
import settingsIcon from "../../assets/icons/settingsIcon.png";
import instancesIcon from "../../assets/icons/instancesIcon.png";
import arrowIcon from "../../assets/icons/arrowIcon.png";
import NavItem from "./NavItem";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Logout, Settings} from "@mui/icons-material";
import jwt_decode, {JwtPayload} from "jwt-decode";
import Avatar from "react-avatar";
import {decode as base64_decode} from "base-64";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {logoutUser} from "../../redux/reducers/auth";
import useAuth from "../../hooks/useAuth";
import InstanceButton from "../InstanceButton";
import ContentLoader from "../ContentLoader";
import MockWrapper from '../MockWrapper';
import useWindowDimensions from '../../hooks/getWindowDimensions';

const drawerWidth = 300;

function Navigation(): JSX.Element {
  const { width } = useWindowDimensions();
  const { data: userProfileData, role: userRole, groups: userGroups, currentInstance} = useAppSelector((state) => state.user);
  const { t } = useTranslation("common");
  const [open, setOpen] = useState<boolean>(width > 1500);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if(width < 1500) {
     setOpen(false)
    }
  }, [width])

  useAuth();

  const toggleDrawer = (): void => {
    setOpen(!open);
  };
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutUser = () => {
    dispatch(logoutUser());
    navigate("/login", { replace: true });
  };
  return (
    <div className={`${classes.NavWrap} ${!open ? classes.NavClosed : ""}`}>
      <Drawer
        sx={{
          width: open ? drawerWidth : "100px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? drawerWidth : "100px",
            boxSizing: "border-box",
            backgroundColor: "#F6F8FA",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box className={classes.LogoWrap}>
          <img src={open ? logoLong : logoShort} alt="logo" />
        </Box>
        {currentInstance &&
            <InstanceButton
                currentInstance={currentInstance}
                open={open}
                userGroups={userGroups}
                userRole={userRole}/>}
        {/*<LanguageButtonsWrapper />*/}
        <Box className={classes.ListWrap}>
          <List>
            {currentInstance ?
                <>
                  <NavItem
                      text={t("navigation.dashboard")}
                      path="/"
                      icon={dashboardIcon}
                  />
                  <MockWrapper>
                    <NavItem
                      text={t("navigation.notifications")}
                      path="/alerts/list"
                      icon={alertsIcon}
                    />
                  </MockWrapper>
                    <NavItem
                      text={t("navigation.cameras")}
                      path="/cameras"
                      icon={camerasIcon}
                    />
                </> :
                  <NavItem
                      text={t("navigation.instances")}
                      path="/instances"
                      icon={instancesIcon}
                  />
            }
            <NavItem
                text={t("navigation.settings")}
                path="/settings/profile"
                icon={settingsIcon}
            />
          </List>
        </Box>
        {userProfileData ? (
            <div className={classes.ProfileWrap}>
              <IconButton
                  className={classes.ProfileWidget}
                  onClick={handleClick}
                  aria-controls={openMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
              >
                <div
                    className={
                      open ? classes.ProfileImage : classes.ProfileImageClosed
                    }
                >
                  {userProfileData?.photo === "" ? (
                      <Avatar
                          color="#2FB299"
                          name={userProfileData?.first_last_name}
                          size="50"
                      />
                  ) : (
                      <img
                          src={base64_decode(userProfileData?.photo)}
                          alt="profile image"
                      />
                  )}
                </div>
                <div style={{textAlign: "left"}}>
                  <div className={open ? classes.ProfileName : classes.Hide}>
                    {userProfileData?.first_last_name}
                  </div>
                  <div className={open ? classes.ProfileEmail : classes.Hide}>
                    {userProfileData?.email}
                  </div>
                </div>
              </IconButton>
              <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openMenu}
                  onClose={handleClose}
                  onClick={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small"/>
                  </ListItemIcon>
                  <Link className={classes.LinkReset} to="/settings/profile">
                    {t("navigation.settings")}
                  </Link>
                </MenuItem>
                <MenuItem onClick={() => handleLogoutUser()}>
                  <ListItemIcon>
                    <Logout fontSize="small"/>
                  </ListItemIcon>
                  <div>
                    {t("navigation.logout")}
                  </div>
                </MenuItem>
              </Menu>
            </div>
        ) : <ContentLoader/>
        }
      </Drawer>
      <button
        className={classes.ToggleButton}
        onClick={toggleDrawer}
        type="button"
      >
        <img src={arrowIcon} alt="arrow icon" />
      </button>
    </div>
  );
}

export default Navigation;
