import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#FFF',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#000',
      main: '#DFE4EA',
    },
    secondary: {
      main: '#000',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
  },
  typography,
});

export default theme;
