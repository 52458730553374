import React from 'react';
import { useStyles } from './styles';
import notFound from "../../assets/images/notFound.svg";
import ConfirmButton from "../../components/ConfirmButton";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const classes = useStyles();
    const { t } = useTranslation("common");

    return (
        <div className={classes.Wrap}>
            <img src={notFound} alt="alert icon"/>
            <h1>{t("pageNotFound.heading")}</h1>
            <Link className={classes.LinkReset} to="/">
                <ConfirmButton
                    confirmButtonText={t("pageNotFound.dashboard")}
                />
            </Link>
            <a href="https://storyset.com/web" className={`${classes.Contribution} ${classes.LinkReset}`}>
                Web illustrations by Storyset</a>
        </div>
    );
};

export default NotFoundPage;