import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  InstancesNav: {
    borderBottom: '1px solid #DFE4EA',
    margin: '40px 0 0px',
    paddingBottom: '10px ',
    
    '& > a': {
      textDecoration: 'none',
      marginLeft: '-5px',
      padding: '0 30px',
      color: "#636F7D",
      '&.active': {
      borderBottom: '1px solid #00FFAA', color: '#000',   paddingBottom: '10px ',
},
    }
  }
}));
