import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  LogInPageWrapper: {
    backgroundColor: '#DFE4EA',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'fixed',
    top: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  LogInModalWrapper: {
    backgroundColor: '#fff',
    width: '500px',
    borderRadius: '8px',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column'
  },
  ResetPasswordModalWrapper: {
    backgroundColor: '#fff',
    width: '720px',
    borderRadius: '8px',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  ForgotPasswordModalWrapper: {
    backgroundColor: '#fff',
    width: '500px',
    borderRadius: '8px',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  LogoLongWrap: {
      width: '300px',
     marginLeft: '-17px'
  },
  LogoLong: {
      width: '100%',
  },
  LogInTitle: {
    fontSize: '24px',
    fontWeight: 'normal',
    marginTop: '10px',
  },
  LogInDescription: {
    fontWeight: '300',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '30px',

  },
  ForgetPasswordButton: {
    textAlign: "center",
    marginTop: '5px',
    width: "100%",
    display: "block",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#6b778c",
  },
  InputError: {
    color: 'red',
    textAlign: 'right',
  },
}));
