import React from 'react';
import { Box, Button } from '@mui/material';
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";
import { AxisOptions, Chart } from 'react-charts';
import { cameraChartData3, DailyStars } from '../../mocks/CameraChart';

export function AlertsStats() {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const primaryAxis = React.useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.hour,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.stars,
      },
    ],
    []
  );
  return (
    <Box>
      <Box className={classes.AlertsStats}>
        <Chart
          options={{
            data: cameraChartData3,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </Box>
      <div className={classes.AlertsStatsButtons}>
        <Button variant="contained">{t('alerts.save')}</Button>
        <Button variant="contained">{t('alerts.generatePDF')}</Button>
        <Button variant="contained">{t('alerts.excel')}</Button>
      </div>
    </Box>
  );
}
