import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CamerasList from "../../components/List/CamerasList";
import { PageTitleBar } from "../../components/PageTitleBar";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchCameras } from '../../redux/reducers/camera';

export function Cameras(): JSX.Element {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cameras = useAppSelector(state => state.camera.list);

  useEffect(() => {
    if(!cameras) {
      dispatch(fetchCameras())
    }
  }, cameras)

  return (
    <Box sx={{ p: 2, pt: 4 }}>
        <div>
          <PageTitleBar title={t("homepage.camerasList")} />
          <CamerasList
            tableContent={cameras}
            confirmButtonText={t("homepage.watch")}
            handleRedirectButton={navigate}
            dark={true}
          />
        </div>
    </Box>
  );
}
