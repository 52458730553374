import { cloneElement } from "react";
import { PERMISSIONS } from './permission-maps';
import useAuth from '../../hooks/useAuth';

interface IHasPermissions {
  permissions: any,
  scopes: any,
}

interface IPermissionsGate {
  children: any,
  RenderError?: any,
  errorProps?: any,
  scopes: any,
  noAccessMessage?: boolean,
}

const hasPermission = ({ permissions, scopes }: IHasPermissions) => {
  const scopesMap: any = {};
  scopes.forEach((scope: any) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission: any) => scopesMap[permission]);
};

export default function PermissionsGate({
                                          children,
                                          RenderError = () => <></>,
                                          errorProps = null,
                                          scopes = [],
                                          noAccessMessage = false
                                        }: IPermissionsGate) {
  const role = useAuth();
  const permissions = PERMISSIONS[role];
  
  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted && !errorProps) return noAccessMessage ? <p>No Access</p> : <RenderError />;

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps });

  return <>{children}</>;
}
