import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
    Wrapper: {
      height: '100%',
      backgroundColor: '#C1CDDA',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
    },
}));
