import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal";

import {
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import ConfirmButton from "../../../../../components/ConfirmButton";
import SettingsGroup from "../../../../../components/SettingsGroup";
import { useStyles } from "../../../styles";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import Toggle from "../../../../../components/Toggle";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

export interface IEditCameraModal {
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  setIsCalibratingActive: Dispatch<SetStateAction<boolean>>;
}

interface IFormEditCamera {
  name: string;
  cameraIP: string;
  type: string;
  room: string;
  resolution: string;
  accuracyObject: number;
  accuracyPerson: number;
  accuracyIncident: number;
  anonymization: boolean;
}

interface IRooms {
  inputValue?: string;
  title: string;
}

interface ComponentProps extends FormikProps<IFormEditCamera> {
  setIsCalibratingActive: Dispatch<SetStateAction<boolean>>;
}

export function EditCameraModal({ setIsModalActive, setIsCalibratingActive }: IEditCameraModal) {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("cameras.editCamera")}
      description={t("cameras.editCameraDesc")}
      confirmButtonText={t("users.send")}
    >
      <div className={classes.RemoveUserWrapper}>
        <Formik<IFormEditCamera>
          initialValues={{
            name: "Kamera 12",
            cameraIP: "123 12 3212",
            type: "CCTV",
            room: "Hala 1",
            resolution: "1920 x 1080",
            accuracyObject: 0.6,
            accuracyPerson: 0.6,
            accuracyIncident: 0.3,
            anonymization: true,
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .max(100, "100 character or less")
              .required("Required"),
          })}
          onSubmit={(values) => {
            console.log("values.rooms");
          }}
          component={(props) => <EditUserModalForm {...props} setIsCalibratingActive={setIsCalibratingActive}  />}
        />
      </div>
    </Modal>
  );
}

let EditUserModalForm: (props: ComponentProps) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setIsCalibratingActive,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const resolutions = [
    "1280 x 1024",
    "1600 x 1200",
    "1920 x 1080",
    "2048 x 1536",
  ];
  const accuracy = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0];

  const roomsArray: IRooms[] = [
    { title: "Hala 1" },
    { title: "Hala 2" },
    { title: "Magazyn 1" },
    { title: "Biuro 2" },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filter = createFilterOptions<IRooms>();
  const [value, setValue] = React.useState<IRooms | null>(roomsArray[0]);

  return (
    <form
      style={{ width: "100%", position: "relative" }}
      onSubmit={handleSubmit}
    >
      {/*<SettingsGroup noBorder title="Nazwa kamery">*/}
      {/*  <TextField*/}
      {/*    fullWidth*/}
      {/*    //   helperText={touched.email ? errors.email : null}*/}
      {/*    hiddenLabel*/}
      {/*    placeholder="Kamera 1"*/}
      {/*    name="name"*/}
      {/*    value={values.name}*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*  />*/}
      {/*</SettingsGroup>*/}

      {/*<SettingsGroup noBorder title="Adres IP">*/}
      {/*  <TextField*/}
      {/*    fullWidth*/}
      {/*    //   helperText={touched.email ? errors.email : null}*/}
      {/*    hiddenLabel*/}
      {/*    placeholder="123 745"*/}
      {/*    name="cameraIP"*/}
      {/*    value={values.cameraIP}*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*  />*/}
      {/*</SettingsGroup>*/}
      {/*<SettingsGroup title="Typ">*/}
      {/*  <TextField*/}
      {/*    fullWidth*/}
      {/*    //   helperText={touched.email ? errors.email : null}*/}
      {/*    hiddenLabel*/}
      {/*    placeholder="CCTV"*/}
      {/*    name="email"*/}
      {/*    value={values.type}*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*  />*/}
      {/*</SettingsGroup>*/}
      {/*<SettingsGroup noBorder title="Pomieszczenie">*/}
      {/*  <div style={{ display: "flex", alignItems: "center" }}>*/}
      {/*    <Autocomplete*/}
      {/*      value={value}*/}
      {/*      onChange={(event, newValue) => {*/}
      {/*        if (typeof newValue === "string") {*/}
      {/*          setValue({*/}
      {/*            title: newValue,*/}
      {/*          });*/}
      {/*        } else if (newValue && newValue.inputValue) {*/}
      {/*          // Create a new value from the user input*/}
      {/*          setValue({*/}
      {/*            title: newValue.inputValue,*/}
      {/*          });*/}
      {/*        } else {*/}
      {/*          setValue(newValue);*/}
      {/*        }*/}
      {/*      }}*/}
      {/*      filterOptions={(options, params) => {*/}
      {/*        const filtered = filter(options, params);*/}

      {/*        const { inputValue } = params;*/}
      {/*        // Suggest the creation of a new value*/}
      {/*        const isExisting = options.some(*/}
      {/*          (option) => inputValue === option.title*/}
      {/*        );*/}
      {/*        if (inputValue !== "" && !isExisting) {*/}
      {/*          filtered.push({*/}
      {/*            inputValue,*/}
      {/*            title: `Dodaj "${inputValue}"`,*/}
      {/*          });*/}
      {/*        }*/}

      {/*        return filtered;*/}
      {/*      }}*/}
      {/*      selectOnFocus*/}
      {/*      clearOnBlur*/}
      {/*      handleHomeEndKeys*/}
      {/*      id="free-solo-with-text-demo"*/}
      {/*      options={roomsArray}*/}
      {/*      getOptionLabel={(option) => {*/}
      {/*        // Value selected with enter, right from the input*/}
      {/*        if (typeof option === "string") {*/}
      {/*          return option;*/}
      {/*        }*/}
      {/*        // Add "xxx" option created dynamically*/}
      {/*        if (option.inputValue) {*/}
      {/*          return option.inputValue;*/}
      {/*        }*/}
      {/*        // Regular option*/}
      {/*        return option.title;*/}
      {/*      }}*/}
      {/*      renderOption={(props, option) => <li {...props}>{option.title}</li>}*/}
      {/*      sx={{ width: 300 }}*/}
      {/*      freeSolo*/}
      {/*      renderInput={(params) => <TextField {...params} />}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <FormHelperText>{t("cameras.addRoomHelper")}</FormHelperText>*/}
      {/*</SettingsGroup>*/}

      {/*<SettingsGroup noBorder title={t("settings.resolution")}>*/}
      {/*  <Select*/}
      {/*    fullWidth*/}
      {/*    name="resolution"*/}
      {/*    value={values.resolution}*/}
      {/*    onChange={handleChange}*/}
      {/*    input={<OutlinedInput />}*/}
      {/*    MenuProps={MenuProps}*/}
      {/*  >*/}
      {/*    {resolutions.map((name) => (*/}
      {/*      <MenuItem key={name} value={name}>*/}
      {/*        {name}*/}
      {/*      </MenuItem>*/}
      {/*    ))}*/}
      {/*  </Select>*/}
      {/*</SettingsGroup>*/}
      {/*<SettingsGroup noBorder title={t("settings.anonymization")}>*/}
      {/*  <Toggle*/}
      {/*    text={values.anonymization ? t("settings.yes") : t("settings.no")}*/}
      {/*    id="anonymization"*/}
      {/*    isActive={values.anonymization}*/}
      {/*    onClick={handleChange}*/}
      {/*  />*/}
      {/*</SettingsGroup>*/}

      {/*<SettingsGroup noBorder title={t("settings.accuracy")}>*/}
      {/*  <Box className={classes.SettingsNestedInput}>*/}
      {/*    <div className={classes.SettingsNestedInputLabel}>*/}
      {/*      {t("settings.object")}*/}
      {/*    </div>*/}
      {/*    <Select*/}
      {/*      fullWidth*/}
      {/*      name="accuracyObject"*/}
      {/*      value={values.accuracyObject}*/}
      {/*      onChange={handleChange}*/}
      {/*      input={<OutlinedInput />}*/}
      {/*      MenuProps={MenuProps}*/}
      {/*    >*/}
      {/*      {accuracy.map((name) => (*/}
      {/*        <MenuItem key={name} value={name}>*/}
      {/*          {name}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </Select>*/}
      {/*  </Box>*/}
      {/*  <Box className={classes.SettingsNestedInput}>*/}
      {/*    <div className={classes.SettingsNestedInputLabel}>*/}
      {/*      {t("settings.person")}*/}
      {/*    </div>*/}
      {/*    <Select*/}
      {/*      fullWidth*/}
      {/*      name="accuracyPerson"*/}
      {/*      value={values.accuracyPerson}*/}
      {/*      onChange={handleChange}*/}
      {/*      input={<OutlinedInput />}*/}
      {/*      MenuProps={MenuProps}*/}
      {/*    >*/}
      {/*      {accuracy.map((name) => (*/}
      {/*        <MenuItem key={name} value={name}>*/}
      {/*          {name}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </Select>*/}
      {/*  </Box>*/}
      {/*  <Box className={classes.SettingsNestedInput}>*/}
      {/*    <div className={classes.SettingsNestedInputLabel}>*/}
      {/*      {t("settings.incident")}*/}
      {/*    </div>*/}
      {/*    <Select*/}
      {/*      fullWidth*/}
      {/*      name="accuracyIncident"*/}
      {/*      value={values.accuracyIncident}*/}
      {/*      onChange={handleChange}*/}
      {/*      input={<OutlinedInput />}*/}
      {/*      MenuProps={MenuProps}*/}
      {/*    >*/}
      {/*      {accuracy.map((name) => (*/}
      {/*        <MenuItem key={name} value={name}>*/}
      {/*          {name}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </Select>*/}
      {/*  </Box>*/}
      {/*</SettingsGroup>*/}

      <SettingsGroup noBorder title={t("settings.calibration")}>
        <ConfirmButton
          button
          confirmButtonText={t("settings.calibrate")}
          handleOnClick={() => setIsCalibratingActive(true)}
        />
      </SettingsGroup>

      <Box
        style={{
          position: "absolute",
          bottom: "-80px",
          right: "-30px",
        }}
        className={classes.ButtonWrap}
      >
        <ConfirmButton confirmButtonText={t("settings.save")} />
      </Box>
    </form>
  );
};
