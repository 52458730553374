import React from "react";
import { useStyles } from "./styles";
import { useAppSelector } from '../../redux/hooks';
import { Box } from '@mui/system';

function MockWrapper({children, sx }: any) {
  const classes = useStyles();

  const showMocks = useAppSelector((state) => state.user.showMocks);
  return (
      <Box className={showMocks ? classes.MockIndicator : ''} sx={sx}>
        {children}
      </Box>
  );
}

export default MockWrapper;
