import {createSlice} from '@reduxjs/toolkit'
import {REACT_APP_BACKEND_URL} from "../../common/config";
import {toast} from "react-toastify";
import {getToken} from "../../common/helpers";
import {IInstance} from "../../types/interfaces";
import {saveCurrentInstance} from './user';
import i18next from "i18next";

interface GroupsState {
    data: IInstance[] | [],
}

const initialState: GroupsState = {
    data: [],
}

export const groups = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        saveGroupsList: (state, action) => {
            state.data = action.payload
        },
    },
})

export const { saveGroupsList } = groups.actions

export const fetchGroupsList = () => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/list`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((groupsList) => {
                                dispatch(saveGroupsList(groupsList));
                                return groupsList;
                            }
                        );
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const createGroup = (values: any) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/create`, {
            method: "POST",
            body:
                JSON.stringify({
                    name: values.name,
                    email: values.email
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    toast.success(i18next.t("common:toastInfo.instanceAdded"));
                    dispatch(fetchGroupsList())

                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.instanceAddedError"));
                        return false
                    });
                }
            });
    };
};

export const fetchGroup = (id: number | undefined) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((group) => {
                                dispatch(saveCurrentInstance(group));
                                return group;
                            }
                        );
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const deleteGroup = (id: number | null) => {
    return () => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/delete`, {
            method: "DELETE",
            body:
                JSON.stringify({
                    id: id
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    toast.success(i18next.t("common:toastInfo.instanceRemoved"));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.instanceRemovalError"));
                        return false
                    });
                }
            });
    };
};

export const editGroup = (values: any, id: number | undefined) => {
    const {name, email, phone, street, postCode, city, isActive} = values;
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/${id}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                    name,
                    email,
                    phone,
                    street,
                    post_code: postCode,
                    city,
                    is_active: !!isActive,
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchGroup(id));
                    toast.success(i18next.t("common:toastInfo.instanceEdited"));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.instanceEditError"));
                        return false
                    });
                }
            });
    };
};

export default groups.reducer