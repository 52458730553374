import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useStyles } from '../styles';

interface INavItem {
  text: string,
  path: string,
  icon: string,
}

export default function NavItem({ text, path, icon }: INavItem): JSX.Element {
  const location = useLocation();
  const classes = useStyles();

  const checkIfSelected = (path: string) => {
    if (location.pathname.includes('/settings/') && path.includes('/settings')) {
      return true
    } else {
      return location.pathname === path
    }
  }

  return (
    <ListItem
      button
      className={classes.NavItem}
      component={Link}
      to={path}
      selected={checkIfSelected(path)}
    >
      <ListItemIcon>
        <img src={icon} alt="nav icon" />
      </ListItemIcon>
      <ListItemText className={classes.NavText} primary={text} />
    </ListItem>
  );
}
