import {createSlice} from '@reduxjs/toolkit'
import { REACT_APP_BACKEND_URL } from '../../common/config';
import {toast} from "react-toastify";
import {getToken} from '../../common/helpers';
import i18next from 'i18next';
import { cameraThumbnail } from '../../mocks/CamerasListMock';

interface CameraState {
  list: any,
  current: any,
  roi: any,
  inOutBoundaries: any,
  inOutLive: any;
  inOutHourly: any;
}

const initialState: CameraState = {
  list: null,
  current: null,
  roi: null,
  inOutBoundaries: null,
  inOutLive: null,
  inOutHourly: null,
}

export const camera = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    getCameras: (state, action) => {
      state.list = action.payload
    },
    getCurrentCamera: (state, action) => {
      state.current = action.payload
    },
    getCameraRoi: (state, action) => {
      state.roi = action.payload
    },
    getCameraInOutBoundaries: (state, action) => {
      state.inOutBoundaries = action.payload
    },
    deleteCameraRoi: (state) => {
      state.roi = null
    },
    getInOutLive: (state, action) => {
      state.inOutLive = action.payload
    },
    getInOutHourly: (state, action) => {
      state.inOutHourly = action.payload
    }
  },
})

export const { getCameras, getCameraRoi, deleteCameraRoi, getCameraInOutBoundaries, getInOutLive, getInOutHourly, getCurrentCamera } = camera.actions

export const fetchCameras = () => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(async (data) => {
                dispatch(getCameras(data));
                return data
              }
            );
        } else {
          return res.json().then(() => {
            return false
          });
        }
      });
  };
}

export const fetchCamerasWithImages = () => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(async (data) => {
                // remove when backend add image to cameras list
              dispatch(getCameras(data));
              const fetchImages = await data.map(async(camera: any) => {
                  return {
                    ...camera,
                    // todo change to fetch when after API optimization
                    //image: await dispatch(fetchCameraImage(camera.id))
                    image: cameraThumbnail
                  }
                })
                const dataWithImages = await Promise.all(fetchImages)
                dispatch(getCameras(dataWithImages));
                return data
              }
            );
        } else {
          return res.json().then(() => {
            return false
          });
        }
      });
  };
}


export const fetchCameraRoi = (id: number) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/roi_contour`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((roiData) => {
                dispatch(getCameraRoi(roiData.contour_roi));
                return roiData;
              }
            );
        } else {
          console.log('error')
          return res.json().then(() => {
            dispatch(getCameraRoi(undefined));
            return false
          });
        }
      });
  };
};

export const fetchCameraInOutBoundaries = (id: number) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/in_out_boundaries`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((data) => {
                dispatch(getCameraInOutBoundaries(data.in_out_boundaries));
                return data.in_out_boundaries;
              }
            );
        } else {
          return res.json().then(() => {
            dispatch(getCameraInOutBoundaries(null));
            return false
          });
        }
      })
      .catch(err => {
      console.log(err)
    });
  };
};

export const editInOutBoundaries = (id: number, data: any) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/in_out_boundaries`, {
      method: "PUT",
      body:
        JSON.stringify({
          in_out_boundaries: data,
        }),

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          dispatch(fetchCameraInOutBoundaries(id))
          // @ts-ignore
          toast.success(i18next.t("common:toastInfo.safetyAreaAdded"));

        } else {
          return res.json().then(() => {
            // @ts-ignore
            toast.error(i18next.t("common:toastInfo.safetyAreaError"));

            return false
          });
        }
      });
  };
};

export const editCameraRoi = (id: number, data: any) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/roi_contour`, {
      method: "PUT",
      body:
        JSON.stringify({
          contour_roi: data,
        }),

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          dispatch(fetchCameraRoi(id))
          toast.success(i18next.t("common:toastInfo.safetyAreaAdded"));

        } else {
          return res.json().then(() => {
            toast.error(i18next.t("common:toastInfo.safetyAreaError"));

            return false
          });
        }
      });
  };
};

export const editCameraCalibration = (id: number, data: any) => {
  return () => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/homographic_matrix`, {
      method: "POST",
      body:
        JSON.stringify({
          pts_destination: data,
        }),

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          toast.success(i18next.t("common:toastInfo.calibrationAdded"));
        } else {
          return res.json().then(() => {
            toast.error(i18next.t("common:toastInfo.calibrationError"));
            return false
          });
        }
      });
  };
};

export const removeCameraRoi = (id: number) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/roi_contour`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          dispatch(deleteCameraRoi())
          toast.success(i18next.t("common:toastInfo.areaRemoved"));
        } else {
          return res.json().then(() => {
            toast.error(i18next.t("common:toastInfo.areaRemovalError"));
            return false
          });
        }
      });
  };
}

export const fetchCameraInOutLive = (id: number) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/metrics/in-out/live?cameras=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((data) => {
                dispatch(getInOutLive(data));
                return data;
              }
            );
        } else {
          return res.json().then(() => {
            console.log('error')
            return false
          });
        }
      });
  };
};

export const fetchCameraInOutHourly = (id: number, date: string) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/metrics/in-out/hourly?cameras=${id}&date=${date}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((data) => {
                dispatch(getInOutHourly(data));
                return data;
              }
            );
        } else {
          return res.json().then(() => {
            return false
          });
        }
      });
  };
};

export const fetchHeatmap = (id: any, payload: any) => {
  return (dispatch: any) => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/metrics/heatmap?report_type=${payload.type}&from_date=${payload.fromDate}&to_date=${payload.toDate}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
        .then((res) => {
          if (res.ok) {
            return res
                .json()
                .then((heatmap) => {
                      return heatmap;
                    }
                );
          } else {
            return res.json().then(() => {
              return false
            });
          }
        });
  };
};

export const fetchCameraImage = (id: number) => {
  return () => {
    return cameraThumbnail;
    //todo change after API optimization
    // return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/image`, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${getToken()}`,
    //   },
    // })
    //   .then((res) => {
    //     if (res.ok) {
    //       return res
    //         .json()
    //         .then((data) => {
    //             return data.image;
    //           }
    //         );
    //     } else {
    //       return res.json().then(() => {
    //         return false
    //       });
    //     }
    //   });
  };
}
export const setCurrentCamera = (camera: any) => {
  return (dispatch: any) => {
    dispatch(getCurrentCamera(camera));
  };
};

export const fetchCameraFeedUrl = (id: number) => {
  return () => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/gstreamer/playlist`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((data) => {
                return data.playlist_url;
              }
            );
        } else {
          return res.json().then(() => {
            return false
          });
        }
      });
  };
}

export const fetchCameraBirdseyeUrl = (id: number) => {
  return () => {
    return fetch(`${REACT_APP_BACKEND_URL}/cameras/${id}/gstreamer/birdseye`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((data) => {
                return data.playlist_url;
              }
            );
        } else {
          return res.json().then(() => {
            return false
          });
        }
      });
  };
}

export default camera.reducer
