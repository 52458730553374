import React, { useRef } from 'react';
import { useStyles } from "./styles";
import { Tile } from "../Tile";
import { useTranslation } from "react-i18next";
import ReactHlsPlayer from 'react-hls-player';

export interface IBirdsView {
  birdsView: any;
  loading: boolean;
}

function BirdsView({ birdsView, loading }: IBirdsView) {
  const birdseyePlayerRef = useRef<HTMLVideoElement>(null);
  const classes = useStyles();
  const { t } = useTranslation("common");
  function playVideo() {
    if(birdseyePlayerRef.current) {
      birdseyePlayerRef.current.play();
    }
  }

  function pauseVideo() {
    if(birdseyePlayerRef.current) {
      birdseyePlayerRef.current.pause();
    }
  }

  function toggleControls() {
    if(birdseyePlayerRef.current) {
      birdseyePlayerRef.current.controls = !birdseyePlayerRef.current.controls;
    }
  }


  React.useEffect(() => {
    function fireOnVideoEnd() {
      playVideo();
    }
    if(birdseyePlayerRef.current) {
      birdseyePlayerRef.current.addEventListener('ended', fireOnVideoEnd);
    }

  }, []);



  return (
    <Tile>
      <h3 className={classes.Title}>{t("camera.birdsView")}</h3>
      <div className={classes.VideoWrap}>
        {!loading &&
        <ReactHlsPlayer
          muted={true}
          src="https://stream.safetysolutions.app/static/gstreamer/0-birdseye/playlist.m3u8"
          autoPlay={true}
          controls={true}
          width="100%"
          height={396}
          playerRef={birdseyePlayerRef}
        />
        }
      </div>
    </Tile>
  );
}

export default BirdsView;
