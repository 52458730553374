import React from "react";
import {useNavigate} from "react-router-dom";
import Select, { components, DropdownIndicatorProps } from "react-select";
import {saveCurrentInstance, saveUserRole} from "../../redux/reducers/user";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useStyles } from "./styles";
import {useTranslation} from "react-i18next";
import {getRole} from "../../hooks/useAuth";

export interface IInstancesSelect {
  chosenInstance: any;
  groupsList: any[];
  disabledMenu: boolean,
}

function InstancesSelect({chosenInstance,
                             groupsList,
                             disabledMenu,
                         }: IInstancesSelect): JSX.Element {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const options = groupsList && groupsList.map((o: any) => o);
    const {currentInstance, groups: currentUserGroups, data: userProfileData } = useAppSelector((state) => state.user);

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: "1px solid #DFE4EA",
            backgroundColor: "#fff",
            color: "#000",
            "&:hover": {
                backgroundColor: state.isFocused ? "#DFE4EA" : "#fff",
            },
            cursor: "pointer",
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: "10000001",
            backgroundColor: "#fff",
            borderRadius: "8px",
        }),

        control: (provided: any) => ({
            ...provided,
            paddingLeft: "5px",
            height: "30px",
            backgroundColor: "transparent",
            width: "250px",
            border: "none",
            outline: "none",
            boxShadow: "none",
            cursor: "pointer",
            pointerEvents: disabledMenu ? "none" : 'all',
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            marginTop: '-17px',
            pointerEvents: !disabledMenu ? "none" : 'all',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#181818',
        }),
    };

    const handleInstanceChange = (e: any) => {
        navigate(`/`);
        dispatch(saveCurrentInstance(e));
        dispatch(saveUserRole(getRole(userProfileData, currentUserGroups, currentInstance?.id)));
    };

    const handleInstanceLogout = () => {
        navigate(`/instances`);
        dispatch(saveCurrentInstance(null));
    }

    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                {groupsList.length > 1 && <KeyboardArrowDownIcon sx={{color: '#919396'}}/>}
                {disabledMenu && <ExitToAppIcon className={classes.InstanceExitIcon} onClick={handleInstanceLogout}/>}
            </components.DropdownIndicator>
        );
    };

    return (
        <div className={classes.InstanceSelectWrap}>
            <span>{t("settings.instance")}</span>
            <Select
                options={!disabledMenu ? options : options[0]}
                styles={customStyles}
                isDisabled={options.length === 1}
                onChange={(e) => handleInstanceChange(e)}
                value={chosenInstance}
                isSearchable={false}
                components={{ DropdownIndicator }}
            />
        </div>

    );
}

export default InstancesSelect;
