import React, {useState} from "react";
import { useStyles } from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import binIcon from "../../../assets/icons/bin.png";
import Status from "../../Status";
import ConfirmButton from "../../ConfirmButton";
import { decode as base64_decode } from "base-64";
import Avatar from "react-avatar";
import { CircularProgress } from "@mui/material";
import {EditUserModal} from "../../../pages/Settings/Pages/Users/Modals/EditUserModal";
import {RemoveUserModal} from "../../../pages/Settings/Pages/Users/Modals/RemoveUserModal";

export interface IList {
  tableContent: Array<object>;
  confirmButtonText: string;
}
function UsersList({
  tableContent,
  confirmButtonText,
}: IList) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [isActiveRemoveUserModal, setIsActiveRemoveUserModal] = useState(false);
  const [isActiveEditUserModal, setIsActiveEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState()

  const handleRemoveUser = (user: any) => {
    setIsActiveRemoveUserModal(true);
    setSelectedUser(user);
  }

  const handleEditUser = (user: any) => {
    setIsActiveEditUserModal(true);
    setSelectedUser(user);
  }

  return (
   <>
    <TableContainer>
      {tableContent ? (
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0px 10px",
            width: "98%",
            margin: "0 auto",
          }}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("users.user")}</TableCell>
              <TableCell align="left">{t("users.role")}</TableCell>
              <TableCell align="left">{t("users.status")}</TableCell>
              <TableCell align="left"/>
              <TableCell align="left"/>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableContent.map((user: any) => (
              <TableRow
                key={user.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={classes.TableCell}
                >
                  <div className={classes.UserAvatar}>
                    {user.photo ? (
                      <img src={base64_decode(user.photo)} alt="user avatar" />
                    ) : (
                      <Avatar
                        size="50"
                        color="#2FB299"
                        name={user.first_last_name}
                      />
                    )}
                  </div>
                  <div>
                    {user.first_last_name}
                    <div style={{ color: "#636F7D", fontSize: "14px" }}>
                      {user.email}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.TableCell} align="left">
                  {user.is_admin ? "Admin" : t("users.user")}
                </TableCell>
                <TableCell className={classes.TableCell} align="left">
                  <div style={{ display: "flex" }}>
                    <Status
                      dotColor={user.is_active ? "green" : "red"}
                      text={
                        user.is_active
                          ? t("users.active")
                          : t("users.inactive")
                      }
                    />
                  </div>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  align="left"
                />
                <TableCell className={classes.TableCell} align="right">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      onClick={() => handleRemoveUser(user)}
                      className={classes.RemoveBTN}
                    >
                      <img src={binIcon} alt="bin icon" />
                    </button>

                    <ConfirmButton
                      confirmButtonText={confirmButtonText}
                      confirmButtonColor="#000"
                      handleOnClick={() => handleEditUser(user)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <CircularProgress />
      )}
    </TableContainer>
        {isActiveEditUserModal && (
            <EditUserModal
                user={selectedUser}
                setIsModalActive={setIsActiveEditUserModal}
            />
        )}

        {isActiveRemoveUserModal && (
            <RemoveUserModal
                user={selectedUser}
                setIsModalActive={setIsActiveRemoveUserModal}
            />
        )}
     </>
  );
}

export default UsersList;
