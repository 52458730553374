import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchCameras, setCurrentCamera } from '../../redux/reducers/camera';

export interface ICamerasSelect {
  camera: any;
  chosenCamera: any;
  setChosenCamera: any;
}

function CamerasSelect({
  camera,
  chosenCamera,
  setChosenCamera,
}: ICamerasSelect): JSX.Element {

  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cameras = useAppSelector(state => state.camera.list)

  useEffect(() => {
    dispatch(fetchCameras());
  }, [])

  const handleCameraChange = (e: any) => {
    if (e.value === chosenCamera.id) {
      return;
    }
    setChosenCamera(e.value);
    dispatch(setCurrentCamera(e.value));
    navigate(`/camera/${e.value}/liveView`);
  };
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: "1px solid #DFE4EA",
      backgroundColor: "#fff",
      color: "#000",
      "&:hover": {
        backgroundColor: state.isFocused ? "#DFE4EA" : "#fff",
      },
      cursor: "pointer",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "200px",
      zIndex: "10000001",
      backgroundColor: "#fff",
      borderRadius: "8px",
    }),

    control: (provided: any) => ({
      ...provided,
      borderRadius: "20px",
      backgroundColor: "#DFE4EA",
      width: "200px",
      border: "none",
      outline: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
  };

  const options = cameras ? cameras.map((option: any) => {
    return {
      label: option.name,
      value: option.id,
    }
  }) : [];

  return (
    <Select
      options={options}
      styles={customStyles}
      onChange={(e) => handleCameraChange(e)}
      value={{
        label: chosenCamera ? chosenCamera.name : '-',
        value: chosenCamera ? chosenCamera.id : null,
      }}
    />
  );
}

export default CamerasSelect;
