import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../../components/ConfirmButton";
import InstancesList from "../../components/List/InstancesList";
import {PageTitleBar} from "../../components/PageTitleBar";
import {AddInstanceModal} from "./Modal/AddInstanceModal";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {fetchGroupsList} from "../../redux/reducers/groups";
import {fetchCurrentUserGroups, saveCurrentInstance, saveUserRole} from "../../redux/reducers/user";
import {getRole} from "../../hooks/useAuth";

export interface IInstances {
  title?: string;
}

export function Instances(): JSX.Element {
  const groupsList = useAppSelector((state) => state.groups.data);
  const {role, groups: currentUserGroups, data: userProfileData } = useAppSelector((state) => state.user);
  const [isActiveAddInstancesModal, setIsActiveAddInstancesModal] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(saveUserRole(getRole(userProfileData, currentUserGroups, null)));
        if (role === "GLOBAL_ADMIN" && groupsList.length === 0) {
            dispatch(fetchGroupsList());
        } else if ((role === "INSTANCE_ADMIN" || role === "USER") && currentUserGroups.length === 0) {
            dispatch(fetchCurrentUserGroups());
        }
    }, [role, userProfileData]);

  const handleLoginBtn = (value: any) => {
    navigate(`/`);
    dispatch(saveUserRole(getRole(userProfileData, currentUserGroups, value?.id)));
    dispatch(saveCurrentInstance(value));
  }

  return (
      <Box sx={{ p: 2, pt: 4 }}>
        <div style={{ position: "absolute", right: "50px" }}>
          <ConfirmButton
            handleOnClick={setIsActiveAddInstancesModal}
            confirmButtonText={t("users.add")}
          />
        </div>

        <Box sx={{ p: 4, pt: 0 }}>
          <PageTitleBar title={t("navigation.instances")} />
        </Box>

        <InstancesList
          tableContent={role === "GLOBAL_ADMIN" ? groupsList : currentUserGroups}
          dark={true}
          handleRedirectButton={handleLoginBtn}
        />

        {isActiveAddInstancesModal && (
          <AddInstanceModal setIsModalActive={setIsActiveAddInstancesModal} />
        )}
      </Box>
  );
}
