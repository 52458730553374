import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({

  HomeLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  HomeAlerts: {
    width: '350px',
    height: '100vh',
    '& > .MuiBox-root': {
      borderRadius: '0',
    },
    ['@media (max-width: 1200px)']: {
      width: '300px',
    }
  },
HomeCameras: {
    width: '100%',
    flex: '1',
  },
// Cameras list view
CamerasListWrapper: {
    padding: '18px 20px 20px',
    width: '100%',
    display: 'flex',

    // background: 'pink',
},
  cameraHeatmapLoading: {
    minHeight: '500px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      margin: '20px 0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    }
  },
CamerasListHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '0 20px 0 6px'
},
CamerasListTitle: {
    fontSize: '20px',
    fontWeight: '400',
},
CamerasData: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: '20px',
},
CamerasListRows: {
  width: 'calc(100% - 300px)',
  minHeight: 'calc(100vh - 100px)',

  padding: '20px',
},
  ListPosition: {
    width: '100%',
    height: '47px',
    background: '#DFE4EA',
    borderRadius: '8px',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  ListPositionPartWrapper: {
    display: 'flex',
},
  ListPositionPart: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#636F7D',
  },
  ListPositionPartDesc: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    fontWeight: '400',
  },
  Status: {
    backgroundColor: '#7f8285',
    borderRadius: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    paddingRight: '5px',
    border: 'none',
    height: '25px',
    fontSize: '16px',
    marginLeft: '10px',
    minWidth: '100px',
  },
  StatusesWrapper: {
display: 'flex',
  },
  RedDot: {
    width: '11px',
    height: '11px',
    background: '#F54F4E',
    display: 'block',
    margin: '0 5px',
    borderRadius: '50%',
  },
  ButtonWatch: {
    width: '113px',
    height: '30px',
    background: '#F6F8FA',
    borderRadius: '18px',
    border: 'none',
    transition: 'all 0.3s',
    '&:hover': {
    backgroundColor: '#7f8285',
    color: '#fff',
    cursor: 'pointer',
      },
  },
  ButtonWatchLink: {
    textDecoration: 'none',
    color: '#000'
  },
  ToggleView: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '130px',
  },
  ToggleViewTitle: {
      fontSize: '16px'
  },
  ToggleViewButton: {
    backgroundColor: '#DFE4EA',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

  },
  ToggleViewButtonDisactiveList: {
  backgroundColor: "#F1F4F6",
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s',
  '& span': {
    backgroundColor: '#BDC8D6',
  },
  },
  ToggleViewButtonDisactive: {
    backgroundColor: "#F1F4F6",
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '& span': {
    borderColor: '#BDC8D6'    },
    },
  ButttonLinesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  ButttonSquaresWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '12px',
  },
  ButtonLine: {
    width: '13px',
    height: '1px',
    display: 'block',
    backgroundColor: '#000',
    margin: "1px 0",
    borderRadius: '8px'
  },
  ButtonSquare: {
    width: '5px',
    height: '5px',
    border: "1px solid #000",
    margin: '0.5px',
    ' &:nth-child(1)': {
      borderTopLeftRadius: '1.5px',
    },
    ' &:nth-child(2)': {
      borderTopRightRadius: '1.5px',
    },
    ' &:nth-child(3)': {
      borderBottomLeftRadius: '1.5px',
    },
    ' &:nth-child(4)': {
      borderBottomRightRadius: '1.5px',
    },
  },
  // Cameras tiles view
  ListTilesTitle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    fontWeight: '400',
  },
  TilesWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  Tile: {
    width: 'calc(100%/3 - 10px)',
    minWidth: '300px',
    maxWidth: '400px',
    background: '#DFE4EA',
    borderRadius: '8px',
    margin: '10px 10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
    transition: '.2s',
    '&:hover': {
      backgroundColor: "#cfd5db",
    },
    ['@media (max-width: 1500px)']: {
      minWidth: '250px',
    },
    ['@media (max-width: 1300px)']: {
      width: 'calc(100%/2 - 10px)',
    },
    ['@media (max-width: 1050px)']: {
      width: '100%',
    },
},
CameraViewWrapper: {
    height: '250px',
    width: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '10px',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  ['@media (max-width: 1500px)']: {
    height: '200px',
  },
},
CameraNoImage: {
    backgroundColor: '#F6F8FA',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: '100px',
      width: '100px',
    }
  },
  CameraViewIMG: {
    width: '100%',

  },
  CameraViewDescWrapper: {
    width: '99%',
    display: 'flex',
  },
  CameraViewIcon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: '21px',
    height: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    '& img': {
      width: '15px',
    },
  },
  CameraViewDesc: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
      },
      StatusTile: {
        backgroundColor: 'rgba(0,0,0,0.43)',
        borderRadius: '40px',
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        paddingRight: '5px',
        border: 'none',
        height: '25px',
        fontSize: '16px',
        marginLeft: '10px',
        position: 'absolute',
        right: '20px',
        top: '20px',
      },
}));
