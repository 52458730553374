import React from "react";
import { Button } from "@mui/material";
import { useStyles } from "./styles";

export interface IRoundedButton {
  text?: string;
  onClick?: any;
  icon?: string;
  white?: boolean;
}

export function RoundedButton({ text, onClick, icon, white }: IRoundedButton) {
  const classes = useStyles();
  return (
    <Button
      style={{ backgroundColor: white ? "#F6F8FA" : "" }}
      className={icon ? classes.RoundedIconButton : classes.RoundedButton}
      variant="contained"
      aria-label={text}
      size="large"
      onClick={onClick}
    >
      {text}
      {icon && <img src={icon} alt="button icon" />}
    </Button>
  );
}
