import React from "react";
import { Link } from 'react-router-dom';
import { useStyles } from "./styles";
import { Box } from "@mui/material";
import alertIcon from "../../assets/icons/alert.png";
import fireIcon from '../../assets/icons/fire.svg';
import { useTranslation } from "react-i18next";

interface IAlertLink {
  alertId: number;
  time: string;
  name: string;
  pathname: string;
  cameraName: string;
  type: string;
  cameraId: number;
}

function AlertLink({ cameraId, alertId, time, name, pathname, cameraName, type }: IAlertLink) {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <Link to={`/camera/${cameraId}/recordings/${alertId}`} style={{ textDecoration: "none" }}>
      <Box className={classes.AlertWrap}>
        <Box className={type === 'fire' ? classes.AlertIconFire : classes.AlertIcon}>
          <img src={type === 'fire' ? fireIcon : alertIcon} alt="alert icon" />
        </Box>
        <Box className={classes.AlertContent}>
          <Box className={classes.AlertHeader}>
            <div>{t("camera.dangerDetected")} </div>
            <div>{time}</div>
          </Box>
          <Box className={type === 'fire' ? classes.AlertNameFire : classes.AlertName}>{name}</Box>
          {pathname === "dashboard" ? <Box>{cameraName}</Box> : null}
        </Box>
      </Box>
    </Link>
  );
}

export default AlertLink;
