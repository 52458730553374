import {createSlice} from '@reduxjs/toolkit'
import {REACT_APP_BACKEND_URL} from "../../common/config";
import i18next from "i18next";
import {toast} from "react-toastify";
import {getToken} from "../../common/helpers";
import {IInstance, IUser} from "../../types/interfaces";
import {fetchGroupUsersList} from "./groupUsers";

interface UserState {
    data: IUser | null,
    groups: any[],
    role: string,
    currentInstance: IInstance | null,
    showMocks: boolean,
    showAlerts: boolean,
    playSounds: boolean,
    systemLang: string,
}

const initialState: UserState = {
  data: null,
  groups: [],
  role: "",
  currentInstance: null,
  showMocks: false,
  showAlerts: false,
  playSounds: false,
  systemLang: 'en',
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.data = action.payload
      state.systemLang = action.payload.language
    },
    removeUserData: (state) => {
      state.data = null
      state.groups = []
      state.role = ""
      state.currentInstance = null
    },
    saveUserGroups: (state, action) => {
      state.groups = action.payload
    },
    saveUserRole: (state, action) => {
      state.role = action.payload
    },
    saveCurrentInstance: (state, action) => {
      state.currentInstance = action.payload
    },
    toggleShowMocks: (state, action) => {
      state.showMocks = action.payload
    },
    toggleShowAlerts: (state, action) => {
      state.showAlerts = action.payload
    },
    togglePlaySounds: (state, action) => {
      state.playSounds = action.payload
    }
  },
})

export const { saveUserData, removeUserData, saveUserGroups, saveUserRole, saveCurrentInstance, toggleShowMocks, toggleShowAlerts, togglePlaySounds } = user.actions

export const fetchCurrentUser = () => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/users/me`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((userData) => {
                                dispatch(saveUserData(userData));
                                i18next.changeLanguage(userData.language);
                                return userData;
                            }
                        );
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.userFetchError"));
                        return false
                    });
                }
            });
    };
};

export const editCurrentUser = (values: any) => {
    const {description, position, nameAndSurname, files, lang, department, phone} = values;
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/users/me`, {
            method: "PATCH",
            body:
                JSON.stringify({
                    first_last_name: nameAndSurname,
                    job: position,
                    details: description,
                    photo: files,
                    language: lang,
                    department,
                    phone_no: phone,
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchCurrentUser());
                    toast.success(i18next.t("common:toastInfo.dataEdited"));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.dataEditedError"));
                        return false
                    });
                }
            });
    };
};

export const fetchCurrentUserGroups = () => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/me`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((userGroupsData) => {
                                dispatch(saveUserGroups(userGroupsData));
                                return userGroupsData;
                            }
                        );
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const editUser = (values: any, id: number) => {
    const {status} = values;
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/users/${id}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                    is_active: !!status
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchCurrentUser());
                    toast.success(i18next.t("common:toastInfo.dataEdited"));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.dataEditedError"));
                        return false
                    });
                }
            });
    };
};

export const deleteUser = (id: number | null) => {
    return () => {
        return fetch(`${REACT_APP_BACKEND_URL}/users/${id}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    toast.success(i18next.t("common:toastInfo.userRemoved"));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.userRemovalError"));
                        return false
                    });
                }
            });
    };
};

export const addUserToGroup = (email: string, groupId: number) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/user`, {
            method: "POST",
            body:
                JSON.stringify({
                    group_id: groupId,
                    email,
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    toast.success("Pomyślnie dodano użytkownika");
                    dispatch(fetchGroupUsersList(groupId))
                } else if (res.status === 409) {
                    res.text().then(text => {
                            if (text.includes('User is already in provided group')) {
                                toast.error("Użytkownik już istnieje w tej instancji");
                            }
                        }
                    )
                }
                else {
                    return res.json().then(() => {
                        toast.error("Wystąpił błąd. Nie udało się dodać użytkownika");
                        return false
                    });
                }
            });
    };
};

export const handleShowMocks = (isVisible: boolean) => {
  return (dispatch: any) => {
    dispatch(toggleShowMocks(isVisible));
  };
};

export const handleShowAlerts = (isVisible: boolean) => {
    return (dispatch: any) => {
        dispatch(toggleShowAlerts(isVisible));
    };
};

export const handlePlaySounds = (state: boolean) => {
  return (dispatch: any) => {
    dispatch(togglePlaySounds(state));
  };
};

export default user.reducer
