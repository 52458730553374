import React from 'react';
import {CircularProgress} from "@mui/material";
import {useStyles} from "./styles";

const ContentLoader = () => {
    const classes = useStyles();
    return (
        <div className={classes.Wrapper}>
            <CircularProgress />
        </div>
    );
};

export default ContentLoader;