import React, { useState } from "react";
import { useStyles } from "./style";
import logoLong from "../../assets/logo/logo-aiss-long.png";
import SettingsGroup from "../../components/SettingsGroup";
import {
  Box,
  OutlinedInput,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import ConfirmButton from "../../components/ConfirmButton";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../components/CustomAlert";
import {useAppDispatch} from "../../redux/hooks";
import {forgotPassword} from "../../redux/reducers/auth";

interface IFormValues {
  email: string;
}

interface ComponentProps extends FormikProps<IFormValues> {
  isLoading: boolean;
}

export function ForgotPassword(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendingSuccess, setSendingSuccess] = useState<boolean>(false);
  const [sendingError, setSendingError] = useState<boolean>(false);

  return (
    <div className={classes.LogInPageWrapper}>
      <Box className={classes.ForgotPasswordModalWrapper}>
        <Box className={classes.LogoLongWrap}>
          <img className={classes.LogoLong} src={logoLong} alt="logo" />
        </Box>
        <h3 className={classes.LogInTitle}>
          {t("resetPassword.restPassword")}
        </h3>
        <p className={classes.LogInDescription}>
          {t("resetPassword.resetDescription")}
        </p>
          {sendingSuccess &&
              <CustomAlert severity="success" title={t("logIn.success")}>
                  {t("logIn.forgotPasswordSuccess")}
              </CustomAlert>
          }
          {sendingError &&
              <CustomAlert severity="error" title={t("logIn.error")}>
                  {t("logIn.loginErrorUnknown")}
              </CustomAlert>
          }

          <Formik<IFormValues>
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("logIn.emailValidation"))
              .required(t("logIn.requiredValidation")),
          })}
          onSubmit={ async (values) => {
            setIsLoading(true)
            const response = await dispatch(forgotPassword(values.email));
            // @ts-ignore
            if(response) {
              setSendingSuccess(true)
              setSendingError(false)
            } else {
              setSendingError(true)
              setSendingSuccess(false)
            }
            setIsLoading(false)
          }}
          component={(props) => <ForgotPasswordForm {...props} isLoading={isLoading} />}
        />
        <button
          style={{ marginTop: "10px" }}
          className={classes.ForgetPasswordButton}
          onClick={() => navigate("/login")}
        >
          {" "}
          {t("resetPassword.backToLogIn")}
        </button>
      </Box>
    </div>
  );
}

let ForgotPasswordForm: (props: ComponentProps) => JSX.Element = ({
                                                                    handleSubmit,
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    isLoading
                                                                  }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <SettingsGroup type="modal" title={t("resetPassword.email")} noBorder>
        <OutlinedInput
          fullWidth
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("resetPassword.enterEmail")}
          style={{ marginBottom: "48px" }}
          error={!!errors.email && touched.email}
        />
        {touched.email && errors.email &&
        <div className={classes.InputError} style={{ marginBottom: "20px", marginTop: '-35px' }}>
          {errors.email}
        </div>
        }
      </SettingsGroup>

      <ConfirmButton
        confirmButtonText={t("users.send")}
        width="100%"
        height="50px"
        loading={isLoading}
      />
    </form>
  );
};
