import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Status from "../../Status";
import ConfirmButton from "../../ConfirmButton";
import { Box } from "@mui/system";
import { useAppSelector } from '../../../redux/hooks';

export interface ICamerasListDashboard {
  handleConfirmButton?: Function;
  handleRedirectButton?: any;
  handleRemoveButton?: Function;
  confirmButtonText: string;
  dark: boolean;
}
function CamerasListDashboard({
  handleRedirectButton,
  confirmButtonText,
  dark,
}: ICamerasListDashboard) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const cameras = useAppSelector(state => state.camera.list);

  return (
    <Box>
      <TableContainer>
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0px 10px",
            width: "98%",
            margin: "0 auto",
          }}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableBody>
            {cameras && cameras.map((cell: any) => {
              return (
                <TableRow
                  key={cell.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                  >
                        <span className={classes.CellName}>
                          {t("homepage.name")}:
                        </span>{" "}
                    {cell.name}
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="left"
                  >
                        <span className={classes.CellName}>
                          {t("homepage.room")}
                        </span>{" "}
                    {cell.description}
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="left"
                  >
                    <div style={{ display: "flex" }}>
                          <span className={classes.CellName}>
                            {t("users.status")}:
                          </span>
                      <Status
                        dotColor={cell.live_feed_enabled ? "green" : "red"}
                        text={
                          cell.live_feed_enabled
                            ? t("homepage.live")
                            : t("users.inactive")
                        }
                      />
                      {cell.danger !== undefined && (
                        <Status
                          dotColor={cell.danger ? "orange" : "green"}
                          text={
                            cell.danger
                              ? t("camera.danger")
                              : t("camera.noDanger")
                          }
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="left"
                  ></TableCell>
                  <TableCell
                    style={{ backgroundColor: "#DFE4EA" }}
                    className={classes.TableCell}
                    align="right"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ConfirmButton
                        confirmButtonText={confirmButtonText}
                        confirmButtonColor="#000"
                        dark={false}
                        handleOnClick={() =>
                          handleRedirectButton(
                            `/camera/${cell.id}/liveView`
                          )
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CamerasListDashboard;
