import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../components/SettingsPageHeader";
import SettingsGroup from "../../../components/SettingsGroup";
import ConfirmButton from "../../../components/ConfirmButton";
import { Reports } from "../../../mocks/Reports";
import MockWrapper from '../../../components/MockWrapper';

export function SettingsReports(): JSX.Element {
  const { t } = useTranslation("common");
  return (
    <Box>
      <MockWrapper>
        <SettingsPageHeader
          title={t("settings.reports")}
          description={t("reports.reportDescription")}
        />

        {Reports.map((report: any) => (
          <SettingsGroup title={report.title}>
            <ConfirmButton confirmButtonText={t("reports.generate")} />
          </SettingsGroup>
        ))}
      </MockWrapper>
    </Box>
  );
}
