import React, { useEffect, useRef, useState } from 'react';
import {useStyles} from "../styles";
import {Tile} from "../../../components/Tile";
import {useTranslation} from "react-i18next";
import { Box, CircularProgress, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchCameraImage, fetchHeatmap } from '../../../redux/reducers/camera';
import HeatmapForm from '../components/HeatmapForm';
import { format, sub } from 'date-fns';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import { Alert } from '@mui/lab';


const HeatmapLayer = ({imgDimensions, rowNumber, data}: any) => {
//divide score range into 5 chunks

  const filteredData = data.heatmap.flat().filter((score: number) => score > 0);
  filteredData.sort(function(a: number,b: number) {return b-a})

  if(filteredData[0] > (filteredData[1] * 3)) {
    filteredData.shift();
  }

  const lowestScore = Math.min(...filteredData)
  const highestScore = Math.max(...filteredData)
  const range = highestScore - lowestScore
  const chunk = Math.ceil(range / 5)


    const classes = useStyles();
    //assign color based on score
    const color = (score: number) => {
        if (score > lowestScore && score < chunk) {
            return 'rgba(86,127,204,0.57)'
        }  else if (score > chunk + 1 && score < chunk * 2) {
            return '#4590b5'
        } else if (score > chunk + 1 && score < chunk * 3) {
            return '#f5b905'
        } else if (score > chunk + 1 && score < chunk * 4) {
            return '#f6840b'
        } else if (score > chunk + 1 && score < chunk * 5) {
            return '#f62322'
        } else return 'transparent'
    }

    return (
        //render points for heatmap, point size is based on camera img size and heatmap resolution (defaults to 150x150 on BE)
        data.heatmap.map((row:any, index:number) =>
            <Box key={index} className={classes.heatmapRowWrap}>{row.map((score:any, i:number) =>
                <Box key={i} style={{
                    'backgroundColor': `${color(score)}`,
                    'width': imgDimensions.width / row.length,
                    'height': imgDimensions.height / rowNumber,
                }}/>
            )}
            </Box>
        ))
}

const Heatmap = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {t} = useTranslation("common");
  const [imgDimensions, setImgDimentions] = useState({height: 0, width: 0})
  const [loading, setLoading] = useState<boolean>(true);
  const [isResizing, setIsResizing] = useState<boolean>(true);
  const [cameraImg, setCameraImg] = useState<string>('');
  const [heatmap, setHeatmap] = useState<any>(null);
  const ref = useRef<any>(null);
  const {width} = useWindowDimensions();

  const camera = useAppSelector(state => state.camera.current);

  let rowNumber = 0

  if(heatmap) {
    const filteredData = heatmap.heatmap.flat().filter((score: number) => score > 0);
    filteredData.sort(function(a: number,b: number) {return b-a})

    if(filteredData[0] > (filteredData[1] * 3)) {
      filteredData.shift();
    }

    rowNumber = heatmap ? heatmap.heatmap.length : 0
  }

  useEffect(() => {
    if(camera) {
      const fetchCamera = async () => {
        const image = await dispatch(fetchCameraImage(camera));
        setCameraImg(image)
        const defaultHeatmapOptions = {
          fromDate: format(sub(new Date(), {months:1}), 'yyyy-MM-dd'),
          toDate: format(new Date(), 'yyyy-MM-dd'),
          type: 'violations'
        }
        const heatmapData = await dispatch(fetchHeatmap(camera, defaultHeatmapOptions))
        setHeatmap(heatmapData)
        setIsResizing(false)
        setTimeout(() => setLoading(false), 500)
      }
      fetchCamera();
    }
  },[camera, dispatch])

  const reloadHeatmap = async (id: any, payload: any) => {
    await setIsResizing(true);
    await setLoading(true);
    const heatmapData = await dispatch(fetchHeatmap(id, payload))
    setHeatmap(heatmapData)
    setIsResizing(false)
    setTimeout(() => setLoading(false), 500)
  }


    //set new img size on window resize, added timeout to lessen rerenders
    //correct img size is needed for heatmap rendering
    function resizeWindow() {
        setImgDimentions({
            height: ref.current ? ref.current.offsetHeight : 0,
            width: ref.current ? ref.current.offsetWidth : 0
        });
    }

    window.onresize = function () {
        if(!isResizing) {
            setIsResizing(true)
            setLoading(true)
        } else {
            setTimeout(() => setIsResizing(false), 200)
            setTimeout(() => setLoading(false), 500)
        }
        resizeWindow()
    };

    // @ts-ignore
    const onImgLoad = ({target: img}) => {
        setImgDimentions({
            height: img.offsetHeight,
            width: img.offsetWidth
        });
    }

    return (
            <Grid container spacing={2}>
                <Grid item xs={width < 1100 ? 12 : 9} style={{'position': 'relative'}}>
                  {(!heatmap && !loading) &&
                    <Alert severity="error">Błąd połączenia z kamerą</Alert>
                  }
                  {heatmap &&
                  <Box className={classes.colorScale}>
                    <Box>Low</Box>
                    <Box style={{'width': '300px','background': 'linear-gradient(90deg, #567fcc, #4590b5, #f5b905, #f6840b, #f62322)'}}></Box>
                    <Box>High</Box>
                  </Box>
                  }
                    <Tile>
                      <Box className={classes.TileHeader}>
                        <h3>{t("camera.heatmap")}</h3>
                      </Box>
                      <Box className={classes.heatmapWrap}>
                        <Box className={classes.heatmapImage}>
                          <img
                            src={`data:image/jpeg;base64,${cameraImg}`}
                            alt="heatmap"
                            onLoad={onImgLoad}
                            ref={ref}
                          />
                        </Box>
                        <div className={classes.heatmap} style={{"height": imgDimensions.height}}>
                          {!isResizing &&
                            <>
                              {/* @ts-ignore*/}
                              {heatmap &&
                                <div className={classes.heatmapBlurWrap}>
                                  <HeatmapLayer data={heatmap} imgDimensions={imgDimensions} rowNumber={rowNumber}/>
                                </div>
                              }
                            </>
                          }
                        </div>

                        {loading &&
                          <div className={classes.cameraHeatmapLoading}>
                            <Box>
                              <CircularProgress size={80}/>
                            </Box>
                          </div>
                        }
                      </Box>
                    </Tile>

                </Grid>
                <Grid item xs={width < 1100 ? 12 : 3}>
                    <Tile>
                        <Box className='heatmap-filters'>
                          <HeatmapForm reloadHeatmap={reloadHeatmap}/>

                        </Box>
                    </Tile>
                </Grid>
            </Grid>
    );
};

export default Heatmap;
