export const Payments = [
    {
      id: 1,
     name: '01/2022',
     sum: '101',
    date: '31/01/2022',
    active: true,
    },
    {
        id: 2,
       name: '01/2022',
      sum: '2002',
      date: '28/02/2022',
      active: true,
      },
      {
        id: 3,
       name: '01/2022',
       sum: '213',
      date: '31/03/2022',
      active: false,
      },
  ]
  