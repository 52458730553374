import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MenuItem, Select, TextField } from '@mui/material';
import { useStyles } from '../styles';
import { Formik, FormikProps } from 'formik';
import { format, sub } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector } from '../../../redux/hooks';

interface IFormAddInstanceValues {
  fromDate: Date;
  toDate: Date;
  type: string;
}

interface ComponentProps extends FormikProps<IFormAddInstanceValues> {
  isLoading: boolean;
}

function HeatmapForm({reloadHeatmap}: {reloadHeatmap: any}) {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const camera = useAppSelector(state => state.camera.current);

  const handleSubmit = async (id: any, payload: any) => {
    await reloadHeatmap(id, payload)
    setIsLoading(false)
  }

  return (
    <div>
      <div className={classes.TileHeader}>
        <h3>{t("camera.filters")}</h3>
      </div>
      <Formik<IFormAddInstanceValues>
        initialValues={{
          fromDate: sub(new Date(), {months:1}),
          toDate: new Date(),
          type: 'detections',
        }}
        onSubmit={async (values) => {
          await setIsLoading(true);
          const formatValues = {
            ...values,
            fromDate: format(values.fromDate, 'yyyy-MM-dd'),
            toDate: format(values.toDate, 'yyyy-MM-dd')
          }
          handleSubmit(camera, formatValues)
        }}
        component={(props) => <FilterHeatmapForm {...props} isLoading={isLoading}/>}
      />
    </div>
  );
}

export default HeatmapForm;

let FilterHeatmapForm: (
  props: ComponentProps
) => JSX.Element = ({
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      isLoading,
                      handleBlur,
                    }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  return (
    <>
      <form  onSubmit={handleSubmit} style={{position: 'relative', width: '100%'}}>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={classes.filterHeader}>
            <h3>{t("camera.from")}</h3>
          </div>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={values.fromDate}
            onChange={(value) => setFieldValue("fromDate", value, true)}
            renderInput={(params) => <TextField name="fromDate" {...params} />}
            className="datepicker"
          />
          <div className={classes.filterHeader}>
            <h3>{t("camera.to")}</h3>
          </div>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={values.toDate}
            onChange={(value) => setFieldValue("toDate", value, true)}
            renderInput={(params) => <TextField name="toDate" {...params} />}
            className="datepicker"
          />
          <div className={classes.filterHeader}>
            <h3>{t("camera.type")}</h3>
          </div>
          <Select
            fullWidth
            name="type"
            value={values.type}
            onChange={handleChange}
            placeholder='Wybierz'
          >
            <MenuItem value="detections">Wykrycie</MenuItem>
            <MenuItem value="violations">Naruszenie</MenuItem>
          </Select>
          <div className={classes.filterButtonWrap}>
            <LoadingButton fullWidth loading={isLoading} variant="contained" color="success" type="submit">Filtruj </LoadingButton>
          </div>
        </LocalizationProvider>

      </form>
    </>
  );
};
