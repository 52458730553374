import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  AlertsFilters: {
    padding: '20px',
    margin: '0 30px 0',
    backgroundColor: '#dfe4ea',
    borderRadius: '10px',
  },
  FormsWrap: {
    display: 'flex',
    '& > div': {
      flex: '1',
      marginRight: '20px',
    }
  },
  FiltersButton: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  CameraDetailsLinks: {
    backgroundColor: '#dfe4ea',
    borderBottom: '1px solid #DFE4EA',
    margin: '0 15px',
    padding: '10px ',
    borderRadius: '8px',
    '& a': {
      textDecoration: 'none',
      marginLeft: '-5px',
      padding: '0 30px',
      color: "#636F7D",
      '&.active': {
        borderBottom: '1px solid #00FFAA', color: '#000',   paddingBottom: '10px ',
      },
    }
  },
  AlertsStats: {
    height: '500px',
    margin: '0 25px',
  },
  AlertsStatsButtons: {
    margin: '20px 25px 0',
    textAlign: 'right',
    '& button': {
      margin: '0 5px',
    }
  }

}));
