import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Alert } from '@mui/lab';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { REACT_APP_BACKEND_URL } from '../../../../../common/config';
import { editCameraCalibration } from '../../../../../redux/reducers/camera';
import { useStyles } from '../../../styles';
import { CanvasDrawing } from '../../../../../components/CanvasDrawing';
import { CanvasEditing } from '../../../../../components/CanvasEditing';
import { getToken } from '../../../../../common/helpers';

export interface IAddInstanceModal {
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
}

export function CalibrateCamera({
                                    setIsModalActive,
                                  }: IAddInstanceModal): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isBoxVisible, setIsBoxVisible] = useState<boolean>(false);
  const [cameraImage, setCameraImage] = useState<string>('');
  const [areaContour, setAreaContour] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState<{height: number | undefined, width: number | undefined}>({height: undefined, width: undefined});

  const cameraImageRef = useRef<HTMLImageElement>(null);
  const canvasEditingRef = useRef(null);

  const camera = useAppSelector(state => state.camera.current);

  const { t } = useTranslation("common");

  useEffect(() => {
    async function getImage() {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/cameras/${camera}/calibration_image`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      let actualData = await response.json();
      setCameraImage(actualData.image)
    }
    if(isBoxVisible) {
      getImage()
    }
  }, [isBoxVisible])

  useEffect(() => {
    if(cameraImage) {
      setImageSize({
        height: cameraImageRef.current!.height,
        width: cameraImageRef.current!.width,
      })
    }
  }, [cameraImage])

  const saveCalibration = async function() {
    setIsLoading(true)

    // @ts-ignore
    const contours = canvasEditingRef.current!.getContours();
    const points = contours.getObjects()[0].points;
    const mapPoints = points.map((point: any) => { return [point.x, point.y]})
    await dispatch(editCameraCalibration(camera, mapPoints))
    setIsLoading(false)
    setIsModalActive(false)
  }

  const handleCanvasEdit = () => {
    if(canvasEditingRef){
      // @ts-ignore
      canvasEditingRef.current!.edit();
    }
  }

  return (
    <div className={classes.drawingWrap}>
      <div>
        {cameraImage &&
        <div className={classes.cameraImageWrap}>
            <div className={classes.cameraCanvasWrap} style={{"display": `${areaContour ? 'none' : 'block'}`}}>
              <CanvasDrawing imageSize={imageSize} pointsLimit={4} updateAreaContour={setAreaContour}/>
            </div>
            <div className={classes.cameraCanvasWrap} style={{"display": `${areaContour ? 'block' : 'none'}`}}>
              <CanvasEditing ref={canvasEditingRef} imageSize={imageSize} contours={areaContour} />
            </div>
            <img ref={cameraImageRef} src={`data:image/jpeg;base64,${cameraImage}`} />
          </div>
          }
          {!cameraImage &&
          <div className={classes.cameraImageLoading}>
            <div>
              <h3>{t("safetyAreas.cameraCalibration")}</h3>
              <br/>
              <ol>
                <li>{t("safetyAreas.calibrationPoint1")}</li>
                <li>{t("safetyAreas.calibrationPoint2")}</li>
                <li>{t("safetyAreas.calibrationPoint3")}</li>
                <li>{t("safetyAreas.calibrationPoint4")}</li>
            </ol>
              <br/>

              <Button variant="contained" color="info" disabled={isBoxVisible} onClick={() => setIsBoxVisible(true)}>{t("safetyAreas.calibrationPoint2")}</Button>
            </div>
          </div>
          }
        <div className={classes.drawingToolbar}>
          <Button color="secondary" onClick={() => setIsModalActive(false)}>{t("safetyAreas.return")}</Button>
          <div>
            {areaContour &&
            <Button variant="outlined" color="secondary" onClick={() => handleCanvasEdit()}>{t("safetyAreas.editSelection")}</Button>
            }
            {(cameraImage && !areaContour) &&
            <Alert severity="info" variant="filled">
              {t("safetyAreas.calibrationInstruction")}
            </Alert>
            }
          </div>
          <LoadingButton loading={isLoading} disabled={!areaContour} variant="contained" color="success" onClick={() => saveCalibration()}>{t("safetyAreas.save")}</LoadingButton>
        </div>
      </div>
    </div>
  );
}
