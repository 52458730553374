import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "./index.scss";
import { Provider } from "react-redux";
import App from "./App";
import {persistor, store} from "./redux/store"
import i18next from "i18next";
import common_pl from "../src/translations/pl.json";
import common_en from "../src/translations/en.json";
import { PersistGate } from 'redux-persist/integration/react';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    pl: {
      common: common_pl,
    },
    en: {
      common: common_en,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
