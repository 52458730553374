import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import ConfirmButton from "../../../components/ConfirmButton";
import SettingsGroup from "../../../components/SettingsGroup";
import { useStyles } from "../styles";
import { Formik, FormikProps } from "formik";
import {useAppDispatch} from "../../../redux/hooks";
import {createGroup} from "../../../redux/reducers/groups";

export interface IAddInstanceModal {
  setIsModalActive: any;
}

interface IFormAddInstanceValues {
  name: string;
  email: string;
}

export function AddInstanceModal({
  setIsModalActive,
}: IAddInstanceModal): JSX.Element {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("instances.addInstance")}
      description={t("instances.createInstance")}
      confirmButtonText={t("users.add")}
    >
      <Formik<IFormAddInstanceValues>
        initialValues={{
          name: "",
          email: "",
        }}
        onSubmit={(values) => {
          dispatch(createGroup(values));
          setIsModalActive(false);
        }}
        component={AddInstanceForm}
      />
    </Modal>
  );
}

let AddInstanceForm: (
  props: FormikProps<IFormAddInstanceValues>
) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <form className={classes.FormWrapper} onSubmit={handleSubmit}>
      <SettingsGroup noBorder title={t("instances.companyName")}>
        <TextField
          fullWidth
          helperText={touched.email ? errors.email : null}
          hiddenLabel
          placeholder="Nazwa firmy"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SettingsGroup>
      <SettingsGroup noBorder title={t("instances.contactEmail")}>
        <TextField
          fullWidth
          error={!!errors.email && touched.email}
          helperText={touched.email ? errors.email : null}
          hiddenLabel
          placeholder="firma@contact.pl"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SettingsGroup>
      <Box style={{ position: "absolute", right: "50px", bottom: "50px" }}>
        <ConfirmButton confirmButtonText={t("settings.save")} />
      </Box>
    </form>
  );
};
