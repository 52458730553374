import React, { useEffect, useState } from 'react';
import CamerasListViewToggle from "../components/CamerasList/CamerasListViewToggle";
import { useStyles } from "../components/CamerasList/styles";
import { useTranslation } from "react-i18next";
import CamerasListTiles from "../components/CamerasList/CamerasListTiles";
import CameraAlerts from "../components/CameraAlerts";
import CamerasListDashboard from "../components/List/CamerasListDashboard";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchCamerasWithImages } from '../redux/reducers/camera';
import { CircularProgress } from '@mui/material';
import { fetchAlertsList } from '../redux/reducers/alerts';

export function Home(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeView, setActiveView] = useState<string>("tiles");
  const [loadingCameras, setLoadingCameras] = useState<boolean>(false);
  const cameras = useAppSelector(state => state.camera.list);
  const alerts = useAppSelector(state => state.alerts.list);

  useEffect(() => {
    if(!cameras){
      const fetchCameras = async () => {
        setLoadingCameras(true)
        await dispatch(fetchCamerasWithImages())
        setLoadingCameras(false)
      }

      fetchCameras()
    }
    if(!alerts){
      const fetchAlerts = async () => {
        setLoadingCameras(true)
        await dispatch(fetchAlertsList())
        setLoadingCameras(false)
      }

      fetchAlerts()
    }
  }, []);

  return (
    <Box className={classes.HomeLayout}>
      <Box className={classes.HomeCameras}>
        <div className={classes.CamerasListWrapper}>
          <div className={classes.CamerasData}>
            <div className={classes.CamerasListHeader}>
              <h1 className={classes.CamerasListTitle}>
                {t("homepage.allCameras")}
              </h1>
              <CamerasListViewToggle
                activeView={activeView}
                setActiveView={setActiveView}
              />
            </div>
            {loadingCameras &&
            <div className={classes.cameraHeatmapLoading}>
              <Box>
                <CircularProgress size={80}/>
              </Box>
            </div>
            }
            {(!loadingCameras && activeView === "list") && (
              <CamerasListDashboard
                confirmButtonText={t("homepage.watch")}
                dark={true}
                handleRedirectButton={navigate}
              />
            )}
            {(!loadingCameras && activeView === "tiles") && <CamerasListTiles />}
          </div>
        </div>

      </Box>
      <Box className={classes.HomeAlerts}>
        <CameraAlerts pathname="dashboard" />
      </Box>
    </Box>
  );
}
