import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  Title: {
    fontSize: '14px',
    fontWeight: '500',
    margin: '0 0 10px',
  },
  VideoWrap: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '8px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  },
}));
