import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  TileWrap: {
    height: '100%',
    backgroundColor: '#DFE4EA',
    borderRadius: '8px',
    padding: '12px',
    ['@media (max-width: 1300px)']: {
      padding: '6px',
    }
  },
  Title: {
    fontSize: '20px',
    fontWeight: '400',
    margin: '8px 0 20px',
  },
  AlertsWrap: {
  },
  ToggleSwitch: {
    display: 'flex',
    backgroundColor: '#C1CDDA',
    borderRadius: '5px',
    padding: '5px',
    '& > div': {
      flex: 1,
      textAlign: 'center',
      color: '#636F7D',
      padding: '5px 0',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: '.2s',
    },
    '& > div:hover': {
      color: '#000',
    },
    '& $FilterActive': {
      color: '#000',
    },
  },
  FilterActive: {
    backgroundColor: '#F6F8FA',
  }
}));
