import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    InstanceSelectWrap: {
        backgroundColor: '#DFE4EA',
        width: '250px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '10px',
        '& > span': {
           marginLeft: '15px',
           marginTop: '7px',
           marginBottom: '-8px',
           fontSize: '13px',
           color: '#636F7D',
        },
    },
    InstanceExitIcon: {
        color: '#575859',
        '&:hover': {
            color: '#8b8c8d',
        }
    }
}));