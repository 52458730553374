import React from "react";
import { useTranslation } from "react-i18next";
import CamerasList from "../../../components/List/CamerasList";
import { CamerasListMock } from "../../../mocks/CamerasListMock";
import { useNavigate } from "react-router-dom";

export function InstancesCameras(): JSX.Element {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <CamerasList
      tableContent={CamerasListMock}
      confirmButtonText={t("homepage.watch")}
      handleRedirectButton={navigate}
      dark={false}
    />
  );
}
