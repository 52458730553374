import React from "react";
import { useStyles } from "./styles";

interface ISettingsGroup {
  title: string;
  description?: string;
  children: any;
  noBorder?: boolean;
  type?: string;
}

function SettingsGroup({
  title,
  description,
  children,
  noBorder,
  type,
}: ISettingsGroup) {
  const classes = useStyles();
  return (
    <div
      className={
        type === "modal"
          ? ""
          : noBorder
          ? classes.GroupWrapNoBorder
          : classes.GroupWrap
      }
    >
      <div
        className={type === "modal" ? classes.TextWrapModal : classes.TextWrap}
      >
        <div className={classes.Title}>{title}</div>
        {description && (
          <div className={classes.Description}>{description}</div>
        )}
      </div>
      <div className={classes.ContentWrap}>{children}</div>
    </div>
  );
}

export default SettingsGroup;
