import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";
interface ICamerasListViewToggle {
  activeView: string;
  setActiveView: any;
}
function CamerasListViewToggle({
  activeView,
  setActiveView,
}: ICamerasListViewToggle): JSX.Element {
  const { t } = useTranslation("common");

  const classes = useStyles();
  return (
    <div className={classes.ToggleView}>
      <p className={classes.ToggleViewTitle}>{t("homepage.view")}</p>
      <button
        onClick={() => setActiveView("list")}
        className={
          activeView === "list"
            ? classes.ToggleViewButton
            : classes.ToggleViewButtonDisactiveList
        }
      >
        <div className={classes.ButttonLinesWrapper}>
          <span className={classes.ButtonLine}></span>
          <span className={classes.ButtonLine}></span>
          <span className={classes.ButtonLine}></span>
        </div>
      </button>
      <button
        onClick={() => setActiveView("tiles")}
        className={
          activeView === "tiles"
            ? classes.ToggleViewButton
            : classes.ToggleViewButtonDisactive
        }
      >
        <div className={classes.ButttonSquaresWrapper}>
          <span className={classes.ButtonSquare}></span>
          <span className={classes.ButtonSquare}></span>
          <span className={classes.ButtonSquare}></span>
          <span className={classes.ButtonSquare}></span>
        </div>
      </button>
    </div>
  );
}

export default CamerasListViewToggle;
