export const Logs = [
    {
      id: 1,
      time: '2012-02-13 12:34:08 (096)',
      content: 'glide.quota.manager SYSTEM URL= /incident_list.do?sysparm_userpref_module=b55fbec4c0a80009008e83d7ff500de&active=true&sysparm_query=active=true^EQ, THREAD= http-bio-8080-exec-3, FG= true, TYPE= 1, STATE= 2, USER= null, TIME= 8,807, MEM= 0, ATTRIBUTES= {}',
    },
    {
        id: 2,
        time: '2012-02-13 13:25:31 (900)',
        content: 'glide.quota.manager SYSTEM QuotaFinder: Assigning quota "TEST PROBLEM FORM with filter:type=form^urlLIKEsys_id=46fb9e31a9fe198101492060c2a4f8cb^EQ to transaction: URL= /problem.do?sys_id=46fb9e31a9fe198101492060c2a4f8cb, THREAD= http-bio-8080-exec-1, FG= true, TYPE= 1, STATE= 4, USER= null, TIME= 1,121, MEM= 0, ATTRIBUTES= {}',
      },
      {
        id: 3,
        time: '2012-02-13 13:25:33 (930)',
        content: 'glide.quota.manager SYSTEM WARNING *** WARNING *** Transaction: Cancelling transaction /problem.do (maximum execution time exceeded): Thread http-bio-8080-exec-1',
      },
  ]
