import React, { useEffect, useRef, useState } from "react";
import { Tile } from "../Tile";
import { useStyles } from "./styles";
import Toggle from "../Toggle";
import useWindowDimensions from "../../hooks/getWindowDimensions";
import { useTranslation } from "react-i18next";
import ReactHlsPlayer from 'react-hls-player';
import { useAppDispatch } from '../../redux/hooks';

export interface ILiveFeed {
  video: any;
  noHeader?: boolean;
  loading: boolean;
}

function LiveFeed({ video, noHeader, loading }: ILiveFeed) {
  const classes = useStyles();
  const [isAnonimized, setIsAnonimized] = useState<boolean>(true);
  const [playerHeight, setPlayerHeight] = useState<number>(500);

  const { t } = useTranslation("common");

  const { height } = useWindowDimensions();
  useEffect(() => {
    // TODO for now its fixed, in the future change to dynamic value
    const bottomRowHeight = 400;
    if (playerRef.current) {
      const offset = playerRef.current.offsetTop;
      const maxPlayerHeight = height - offset - bottomRowHeight;
      if (maxPlayerHeight > 500) {
        setPlayerHeight(maxPlayerHeight);
      } else {
        setPlayerHeight(500);
      }
    }
  }, [height]);


  const playerRef = useRef<HTMLVideoElement>(null);

  function playVideo() {
    if(playerRef.current) {
      playerRef.current.play();
    }
  }

  useEffect(() => {
    if(playerRef.current) {
      playerRef.current.play();
    }
    function fireOnVideoEnd() {
      playVideo();
    }
    if(playerRef.current) {
      playerRef.current.addEventListener('ended', fireOnVideoEnd);
    }

  }, []);

  return (
    <Tile>
      {!noHeader &&
      <div className={classes.ToggleWrap}>
        <h3>{t("camera.liveView")}</h3>
        {/*<Toggle*/}
        {/*  isActive={isAnonimized}*/}
        {/*  text={t("camera.anonymization")}*/}
        {/*  onClick={() => setIsAnonimized(!isAnonimized)}*/}
        {/*/>*/}
      </div>
      }
      <div style={{"backgroundColor": 'rgba(0,0,0,0.8)'}}>
        {!loading &&
        <ReactHlsPlayer
          muted={true}
          src="https://stream.safetysolutions.app/static/gstreamer/0/playlist.m3u8"
          autoPlay={true}
          controls={true}
          width='100%'
          height={playerHeight}
          playerRef={playerRef}
        />
        }
      </div>
    </Tile>
  );
}

export default LiveFeed;
