import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import { CircularProgress } from '@mui/material';
import { Box } from "@mui/system";
import ConfirmButton from "../../../../components/ConfirmButton";
import SettingsGroup from "../../../../components/SettingsGroup";
import { useStyles } from "../../styles";
import { Formik, FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { removeCameraRoi } from '../../../../redux/reducers/camera';

export interface IAddInstanceModal {
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  setIsDrawingActive: Dispatch<SetStateAction<boolean>>;
  currentRoi: number[][] | null;
}

interface IFormAddInstanceValues {
  name: string;
  status: string;
  color: string;
  fullView: boolean;
  peopleLimit: boolean;
  maxPeople: number;
  minPeople: number;
  securityMeasures: string;
  type: string;
  violationType: string;
}

interface ComponentProps extends FormikProps<IFormAddInstanceValues> {
  setIsDrawingActive: Dispatch<SetStateAction<boolean>>;
  currentRoi: number[][] | null;
}

export function DangerZoneForm({
                                 setIsModalActive,
                                 setIsDrawingActive,
                                 currentRoi,
                               }: IAddInstanceModal): JSX.Element {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("safetyAreas.addArea")}
      description={t("safetyAreas.areasManagement")}
      confirmButtonText={t("users.add")}
    >
      <div className={classes.borderFormWrap}>
        <div className={classes.borderFormHeader}>
          <div>Strefa 1</div>
        </div>
        <Formik<IFormAddInstanceValues>
          initialValues={{
            name: "",
            status: "Active",
            color: "Red",
            fullView: false,
            maxPeople: 0,
            minPeople: 0,
            peopleLimit: false,
            securityMeasures: "",
            type: 'critical',
            violationType: ''
          }}
          onSubmit={(values) => {
            console.log(values)
            //setIsModalActive(false);
          }}
          component={(props) => <AddInstanceForm {...props} setIsDrawingActive={setIsDrawingActive} currentRoi={currentRoi} />}
        />
      </div>
    </Modal>
  );
}

let AddInstanceForm: (
  props: ComponentProps
) => JSX.Element = ({
                                           handleSubmit,
                                           setIsDrawingActive,
                                           currentRoi
                    }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const camera = useAppSelector(state => state.camera.current);

  const handleRemoveCameraRoi = async () => {
    setIsLoading(true)
    await dispatch(removeCameraRoi(camera))
    setIsLoading(false)
  }

  return (
    <>
      <form  onSubmit={handleSubmit} style={{position: 'relative', width: '100%'}}>
        <SettingsGroup title={t("safetyAreas.areaEditor")} noBorder>

          {!currentRoi &&
          <ConfirmButton
            button
            confirmButtonText={t("safetyAreas.add")}
            handleOnClick={() => setIsDrawingActive(true)}
          />
          }
          {currentRoi &&
          <div className={classes.areaAddingActions}>
            {!isLoading &&
              <>
                <ConfirmButton
                  button
                  confirmButtonText={t("safetyAreas.area")}
                  handleOnClick={() => setIsDrawingActive(true)}
                />
                <div className={classes.areaAddingActionsDelete}>
                  <div onClick={() => handleRemoveCameraRoi()}>{t("safetyAreas.remove")}</div>
                </div>
                <div onClick={() => setIsDrawingActive(true)}>{t("safetyAreas.update")}</div>
              </>
            }
            {isLoading &&
            <div className={classes.areaRemoving}>
              <CircularProgress />
              <div>{t("safetyAreas.removing")}</div>
            </div>
            }
          </div>
          }
        </SettingsGroup>

        <Box style={{ position: "absolute", bottom: "-80px", right: "-30px" }}>
          <ConfirmButton confirmButtonText={t("settings.save")} />
        </Box>

      </form>
    </>
  );
};
