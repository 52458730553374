import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../components/SettingsPageHeader";
import { useStyles } from "../styles";
import { Logs } from "../../../mocks/Logs";
import MockWrapper from '../../../components/MockWrapper';

export function SettingsLogs(): JSX.Element {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <Box>
      <MockWrapper>
        <SettingsPageHeader
          title={t("settings.logs")}
          description={t("settings.description.logs")}
        />
        <Box className={classes.LogsWrapper}>
          {Logs.map((log: any) => (
            <p className={classes.Log}>
              {log.time} {log.content}
            </p>
          ))}
        </Box>
      </MockWrapper>
    </Box>
  );
}
