import React from 'react';
import ContentLoader from '../ContentLoader';
import { useStyles } from './styles';

interface ComponentLoaderType {
  children: React.ReactNode,
  isLoading: boolean,
}

const ComponentLoader = (props: ComponentLoaderType) => {
  const classes = useStyles();
  return ( props.isLoading ? <div className={classes.Wrapper}><ContentLoader /></div> : <>{props.children}</> );
};

export default ComponentLoader;
