import {createSlice} from '@reduxjs/toolkit'
import {REACT_APP_BACKEND_URL} from "../../common/config";
import {getToken} from "../../common/helpers";
import {IInstance} from "../../types/interfaces";
import {toast} from "react-toastify";
import i18next from "i18next";

interface GroupsUsersState {
    data: IInstance[],
}

const initialState: GroupsUsersState = {
    data: [],
}

export const groupUsers = createSlice({
    name: 'groupUsers',
    initialState,
    reducers: {
        saveGroupUsersList: (state, action) => {
            state.data = action.payload
        },
    },
})

export const { saveGroupUsersList } = groupUsers.actions

export const fetchGroupUsersList = (id: any) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/${id}/list-users`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((usersList) => {
                                dispatch(saveGroupUsersList(usersList));
                                return usersList;
                            }
                        );
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const editGroupAdmins = ( newAdminsIDs: number[] | undefined, id: number | undefined) => {
    return (dispatch: any) => {
        return fetch(`${REACT_APP_BACKEND_URL}/groups/${id}/set-admins`, {
            method: "PATCH",
            body:
                JSON.stringify({
                    user_ids : newAdminsIDs,
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchGroupUsersList(id));
                } else {
                    return res.json().then(() => {
                        toast.error(i18next.t("common:toastInfo.editInstanceAdmins"));
                        return false
                    });
                }
            });
    };
};

export default groupUsers.reducer