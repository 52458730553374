import React from 'react';
import { Grid } from "@mui/material";
import LiveFeed from "../../../components/LiveFeed";
import CameraAlerts from "../../../components/CameraAlerts";
import BirdsView from "../../../components/BirdsView";
import { useCamera } from "../CameraDetails";
import {  useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import { useStyles } from '../styles';

function LiveView() {
  const { t } = useTranslation("common");
  const { camera, cameraFeedUrl, cameraBirdseyeUrl, loading } = useCamera();
  const inOutHourly = useAppSelector(state => state.camera.inOutHourly)
  const mappedChartData = [];
  const {width} = useWindowDimensions();
  const classes = useStyles();

  if (inOutHourly && inOutHourly.in.length > 0) {
    mappedChartData.push({
      label: t("camera.entering"),
      data: inOutHourly.in.map((item: number, index: number) => {
        return {
          hour: index,
          value: item,
        }
      })
    })
    mappedChartData.push({
      label: t("camera.outgoing"),
      data: inOutHourly.out.map((item: number, index: number) => {
        return {
          hour: index,
          value: item,
        }
      })
    })
  }

  return (
      <Grid container spacing={2}>
        <Grid item xs={width < 1100 ? 12 : 9}>
          <LiveFeed video={cameraFeedUrl} loading={loading} />
        </Grid>
        <Grid item xs={width < 1100 ? 12 : 3}>
          <Grid item sx={{height: 'calc(100% - 185px)', marginBottom: '15px'}}>
            <CameraAlerts pathname="cameras" camera={camera} />
          </Grid>
          <Grid item>
            <div className={classes.GraphanaTile}>
              <iframe
                title="graphana-1"
                src="https://grafana.devel.safetysolutions.app/d-solo/m43DZpWVz/aiss-dev?orgId=1&panelId=35&refresh=1s"
                width="100%" height="150" frameBorder="0"></iframe>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item>
            <div className={classes.GraphanaTile}>
              <iframe
                title="graphana-2"
                src="https://grafana.devel.safetysolutions.app/d-solo/m43DZpWVz/aiss-dev?orgId=1&panelId=34&refresh=5s"
                width="100%" height="200" frameBorder="0"></iframe>
            </div>
          </Grid>

          <Grid item>
            <div className={classes.GraphanaTile}>
              <iframe
                title="graphana-3"
                src="https://grafana.devel.safetysolutions.app/d-solo/m43DZpWVz/aiss-dev?orgId=1&panelId=26&refresh=5s&showLegend=false"
                width="100%" height="200" frameBorder="0"></iframe>
            </div>
          </Grid>
        </Grid>

          {/*<CameraChart data={mappedChartData} loading={loadingChart}/>*/}
          {/*<Grid item>*/}
          {/*  <div className={classes.GraphanaTile}>*/}
          {/*    <iframe*/}
          {/*      src="https://grafana.devel.safetysolutions.app/d-solo/m43DZpWVz/aiss-dev?orgId=1&panelId=29&refresh=5s"*/}
          {/*      width="100%" height="180" frameBorder="0"></iframe>*/}
          {/*  </div>*/}
          {/*</Grid>*/}
          {/*<Grid item>*/}
          {/*  <div className={classes.GraphanaTile}>*/}
          {/*    <iframe*/}
          {/*      src="https://grafana.devel.safetysolutions.app/d-solo/m43DZpWVz/aiss-dev?orgId=1&panelId=30&refresh=5s"*/}
          {/*      width="100%" height="180" frameBorder="0"></iframe>*/}
          {/*  </div>*/}
          {/*</Grid>*/}

          {/*<CameraDataTiles cameraData={inOutLive} loading={loadingDataTiles}/>*/}
        <Grid item xs={width < 1100 ? 12 : 6}>
          <BirdsView birdsView={cameraBirdseyeUrl} loading={loading}/>
        </Grid>
      </Grid>
  );
}

export default LiveView;
