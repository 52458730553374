import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  Tile: {
    backgroundColor: '#DFE4EA',
    borderRadius: '8px',
    padding: '12px',
  },
  ConfirmButtonWrap: {
    textAlign: 'center',
    marginTop: '30px'
  },
  FormWrapper: {
    margin: "30px 0 40px",
        border: "1px solid #BCCADA",
        padding: "20px",
        borderRadius: "6px",
  },
  ButtonWrapInstanceInfo: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '1010px',
    width: '100%',
  },
  SettingsNestedInput: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  SettingsNestedInputLabel: {
    width: '250px',
    fontSize: '18px',
  },
}));
