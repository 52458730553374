import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
  AlertWrap: {
    backgroundColor: '#F6F8FA',
    borderRadius: '8px',
    padding: '10px',
    margin: '10px 0 0',
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    transition: '.2s',
    '& *': {
      textDecoration: 'none',
    },
    '&:hover': {
      backgroundColor: '#cfd5db',
    },
      ['@media (max-width: 1300px)']: {
      padding: '6px',
      }
  },
  AlertIcon: {
    height: '42px',
    width: '42px',
    backgroundColor: '#FFEBEB',
    borderRadius: '50%',
    textAlign: 'center',
    marginRight: '10px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    ['@media (max-width: 1300px)']: {
      height: '28px',
      width: '28px',
      marginRight: '5px',
      '& img': {
        height: '16px',
        width: '16px',
      }
}
},
AlertIconFire: {
    height: '42px',
    width: '42px',
    backgroundColor: '#f5964e38',
    borderRadius: '50%',
    textAlign: 'center',
    marginRight: '10px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    ['@media (max-width: 1300px)']: {
      height: '28px',
      width: '28px',
      marginRight: '5px',
      '& img': {
        height: '16px',
        width: '16px',
      }
    }
  },
  AlertContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  AlertHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    ['@media (max-width: 1300px)']: {
      fontSize: '12px',
    }
  },
  AlertName: {
    color: '#F54F4E',
    ['@media (max-width: 1300px)']: {
      fontSize: '14px',
    }
  },
  AlertNameFire: {
    color: '#f5964e',
    ['@media (max-width: 1300px)']: {
      fontSize: '14px',
    }
  },
}));
