import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../styles';
import { REACT_APP_BACKEND_URL } from '../../../../common/config';
import { Alert } from '@mui/lab';
import { Button, LinearProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/system';
import { CanvasDrawing } from '../../../../components/CanvasDrawing';
import { CanvasEditing } from '../../../../components/CanvasEditing';
import { useAppSelector } from '../../../../redux/hooks';

export interface IAddInstanceModal {
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  currentContour: {
    contour: number[],
    name: string,
    index: number,
  };
  setCurrentContour: Dispatch<SetStateAction<{
    contour: number[],
    name: string,
    index: number,
  }>>;
}

export function InOutDrawing({
                               setIsModalActive,
                               currentContour,
                               setCurrentContour
                             }: IAddInstanceModal): JSX.Element {
  const classes = useStyles();

  const [cameraImage, setCameraImage] = useState<string>('');
  const [areaContour, setAreaContour] = useState<{x: number, y: number}[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState<{height: number | undefined, width: number | undefined}>({height: undefined, width: undefined});

  const cameraImageRef = useRef<HTMLImageElement>(null);
  const canvasEditingRef = useRef(null);

  const camera = useAppSelector(state => state.camera.current);

  const { t } = useTranslation("common");

  useEffect(() => {
    async function getImage() {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/cameras/${camera}/image`
      )
      let actualData = await response.json();
      setCameraImage(actualData.image)
    }
    getImage()
  }, [camera])

  useEffect(() => {
    if(cameraImage) {
      setImageSize({
        height: cameraImageRef.current!.height,
        width: cameraImageRef.current!.width,
      })
    }
  }, [cameraImage])

  useEffect(() => {
    if(currentContour.contour !== undefined) {
      const mapPoints = currentContour.contour.map((data: any) => {return {x: data[0], y: data[1]}})
      setAreaContour(mapPoints)
    } else {
      setAreaContour(null)
    }
  }, [currentContour.contour])

  const saveArea = async function() {
    setIsLoading(true)
    // @ts-ignore
    const contours = canvasEditingRef.current!.getContours();
    const points = contours.getObjects()[0].points;
    const mapPoints = points.map((point: any) => { return [point.x, point.y]})

    setCurrentContour({
      ...currentContour,
      contour: mapPoints
    })

    setIsLoading(false)
    setIsModalActive(false)
  }

  const handleCanvasEdit = () => {
    if(canvasEditingRef){
      // @ts-ignore
      canvasEditingRef.current!.edit();
    }
  }

  return (
    <div className={classes.drawingWrap}>
      <div>
        <div className={classes.cameraImageWrap}>
          {cameraImage &&
            <>
              <div className={classes.cameraCanvasWrap} style={{"display": `${areaContour ? 'none' : 'block'}`}}>
                <CanvasDrawing imageSize={imageSize} pointsLimit={2} updateAreaContour={setAreaContour}/>
              </div>
              <div className={classes.cameraCanvasWrap} style={{"display": `${areaContour ? 'block' : 'none'}`}}>
                <CanvasEditing ref={canvasEditingRef} imageSize={imageSize} contours={areaContour} />
              </div>
              <img ref={cameraImageRef} src={`data:image/jpeg;base64,${cameraImage}`} alt="camera" />
            </>
          }
          {!cameraImage &&
          <div className={classes.cameraImageLoading}>
            <div>
              {t("safetyAreas.imageLoading")}
            </div>
            <Box sx={{ width: '30%' }}>
              <LinearProgress />
            </Box>
          </div>
          }
        </div>
        <div className={classes.drawingToolbar}>
          <Button color="secondary" onClick={() => setIsModalActive(false)}>{t("safetyAreas.return")}</Button>
          <div>
            {areaContour &&
            <Button variant="outlined" color="secondary" onClick={() => handleCanvasEdit()}>{t("safetyAreas.editArea")}</Button>
            }
            {!areaContour &&
            <Alert severity="info" variant="filled">{t("safetyAreas.lineDrawingInstruction")}</Alert>
            }
          </div>
          <LoadingButton loading={isLoading} disabled={!areaContour} variant="contained" color="success" onClick={() => saveArea()}>{t("safetyAreas.save")}</LoadingButton>
        </div>
      </div>
    </div>
  );
}
