import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  SettingsWrap: {
    display: 'flex',
    ['@media (max-width: 1000px)']: {
      flexDirection: 'column',
    }
  },
  SettingsNav: {
    width: '250px',
    ['@media (max-width: 1000px)']: {
       width: '100%',
      marginBottom: '20px',
    }
  },
  Loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
  },
  SettingsContent: {
    flex: '1',
    marginLeft: '20px',
    ['@media (max-width: 1000px)']: {
      marginLeft: '0',
    }
  },
  SettingsImageWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  SettingsImage: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  },
  SettingsImageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0 10px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  ButtonWrap: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '1010px',
    width: '100%',
  },
  LogsWrapper: {
    backgroundColor: '#141414',
    minHeight: '65vh',
    borderRadius: '10px',
    padding: '20px',
  },
  Log: {
    color: '#fff',
    marginBottom: '20px',
    fontSize: '16px',
  },
  UserAvatar: {
    marginRight: '20px',
  },
  UserName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  RemoveBTN: {
    backgroundColor: '#DFE4EA',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10px',
  },
  TableCell: {
    backgroundColor: "#F6F8FA",
    ' &:nth-child(1)': {
      borderTopLeftRadius: '18px',
      borderBottomLeftRadius: '18px',
      height: '100%',

    },
    ' &:nth-child(5)': {
      borderTopRightRadius: '18px',
      borderBottomRightRadius: '18px',
    },
  },
  SettingsNestedInput: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  SettingsNestedInputLabel: {
    width: '250px',
    fontSize: '18px',
  },
  RemoveUserWrapper: {
    border: '1px solid #BCCADA',
    borderRadius: '6px',
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '30px',
    flexDirection: 'column'

  },
  EditUserWrapper: {
    borderBottom: '1px dashed #BCCADA',
    paddingBottom: '20px',
    width: '100%',
    display: 'flex',
  },
  RadioWrap: {
    display: 'flex',
    marginBottom: '20px',
  },
  RadioInputWrap: {
    width: '100%',
  },
  RadioCurrent: {
    opacity: '.6',
  },
  RadioName: {
    fontSize: '18px',
  },
  PaymentCard: {
    border: '1px solid #2FB299',
    padding: '20px 10px',
    backgroundColor: '#CADEE0',
    borderRadius: '8px',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  PaymentCardText: {
    flex: '1',
    marginLeft: '20px',
  },
  PaymentCardImage: {
    backgroundColor: '#fff',
    padding: '5px 5px 0',
  },
  PaymentCardName: {
    fontWeight: '500',
    fontSize: '20px',
  },
  PaymentCardDate: {
    fontSize: '14px',
  },
  PaymentCardIcon: {
    position: 'relative',
    top: '4px',
  },
  PaymentCardAdd: {
    marginTop: '10px',
    cursor: 'pointer',
    opacity: '.5',
    transition: '.2s',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px',
    },
    '&:hover': {
      opacity: '1',
    }
  },
  cameraImageWrap: {
    maxWidth: '100vw',
    maxHeight: '85vh',
    position: 'relative',
    overflow: 'scroll',
  },
  drawingToolbar: {
    marginTop: "10px",
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1)'
  },
  cameraCanvasWrap: {
    position: 'absolute',
  },
  areaAddingActions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      cursor: 'pointer',
    }
  },
  areaAddingActionsDelete: {
    color: '#818D98',
    flex: 1,
    textAlign: 'right',
    marginRight: '20px',
  },
  areaRemoving: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& div': {
      marginLeft: '10px',
    }
  },
  cameraImageLoading: {
    width: '1000px',
    height: '600px',
    backgroundColor: 'rgba(0,0,0,0.1)',
    marginBottom: '20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'center',
    '& > div': {
      margin: '20px 0',
    },
    '& li': {
      margin: '6px 0',
    }
  },
  drawingWrap: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    minHeight: '100vh',
    zIndex: '20000000000000000000',
    backgroundColor: '#DFE4EA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  drawingLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 30000000000000000000000000000,
    backgroundColor: 'rgba(255,255,255,.7)',
    height: '100vh',
    width: '100vw',
  },
}));
