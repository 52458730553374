import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  GroupWrap: {
    borderBottom: '1px solid #B8C7D8',
    padding: '30px 0',
    display: 'flex',
    alignItems: 'center',
    ['@media (max-width: 1300px)']: {
      flexDirection: 'column',
    }
  },
  GroupWrapNoBorder: {
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  TextWrap: {
    maxWidth: '400px',
    width: '100%',
    marginRight: '10px',
    ['@media (max-width: 1500px)']: {
      maxWidth: '250px',
    },
    ['@media (max-width: 1300px)']: {
      maxWidth: '100%',
      marginBottom: '10px',
      marginLeft: '10px',
    }
  },
  TextWrapModal: {
    marginBottom: '10px',
    marginLeft: '2px',
  },
  ContentWrap: {
    width: '100%',
    maxWidth: '600px',

    ['@media (max-width: 1300px)']: {
      maxWidth: '100%',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #BCCADA',
    },
    '& .MuiInputBase-input': {
      fontWeight: '600',
      borderRadius: '8px',
      '&.Mui-disabled': {
        backgroundColor: '#EBEEF2',
      }
    },
    '& .Mui-checked': {
      color: 'inherit !important',
    },
    '& .Mui-error': {
    borderColor: 'red',
  },
},
  Title: {
    fontSize: '18px',
    fontWeight: '500',
  },
  Description: {
    fontWeight: '300',
    marginTop: '5px',
    opacity: '.6',
    width: '80%',
  }
}));
