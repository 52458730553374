import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import ConfirmButton from "../../../components/ConfirmButton";
import { useNavigate } from "react-router-dom";
import {useAppDispatch} from "../../../redux/hooks";
import {deleteGroup} from "../../../redux/reducers/groups";

export interface IAddInstanceModal {
  setIsModalActive: any;
  instanceID?: any;
}

export function RemoveInstanceModal({
  setIsModalActive,
  instanceID,
}: IAddInstanceModal): JSX.Element {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRemoveInstanceButton = (id: number | null) => {
    dispatch(deleteGroup(id));
    setIsModalActive(false);
    navigate("/instances");
  };

  return (
    <Modal
      setIsModalActive={setIsModalActive}
      title={t("instances.removeInstance")}
      description=""
      confirmButtonText="Usuń"
    >
      <p>{t("instances.areYouSure")}</p>
      <div style={{ position: "absolute", right: "40px", bottom: "50px" }}>
        <ConfirmButton
          handleOnClick={() => handleRemoveInstanceButton(instanceID)}
          confirmButtonText={t("users.remove")}
          confirmButtonBackgroundColor="danger"
        />
      </div>
    </Modal>
  );
}
