import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SettingsPageHeader from "../../../components/SettingsPageHeader";
import SettingsGroup from "../../../components/SettingsGroup";
import { useStyles } from "../styles";
import ConfirmButton from "../../../components/ConfirmButton";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Avatar from "react-avatar";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {editCurrentUser} from "../../../redux/reducers/user";
import { phoneRegExp } from "../../../common/helpers";

interface IFormValues {
  email: string;
  nameAndSurname: string;
  photo: any;
  description: string;
  position: string;
  lang: string;
  files: string;
  department: string;
  phone: string | number;
}

interface ComponentProps extends FormikProps<IFormValues> {
  isLoading: boolean;
}
export function SettingsProfile(): JSX.Element {
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userProfileData = useAppSelector((state) => state.user.data)
  return (
    <Box>
      <SettingsPageHeader
        title={t("settings.profile")}
        description={t("settings.profileDescription")}
      />
      {!userProfileData ? (
        <CircularProgress color="inherit" />
      ) : (
        <Formik<IFormValues>
          initialValues={{
            email: userProfileData.email,
            nameAndSurname: userProfileData.first_last_name,
            position: userProfileData?.job,
            description: userProfileData?.details,
            photo: base64_decode(userProfileData?.photo),
            lang: userProfileData?.language ? userProfileData?.language : "pl",
            files: userProfileData?.photo,
            department: userProfileData?.department,
            phone: userProfileData?.phone_no,
          }}
          validationSchema={Yup.object({
            nameAndSurname: Yup.string()
              .nullable()
              .max(100, t("inputValidations.max100"))
              .required(t("inputValidations.fieldRequired"))
              .matches(/^[\s\p{L}]+$/u,
                  t("inputValidations.textOnly")),
            phone: Yup.string()
                .nullable()
                .matches(phoneRegExp, t("inputValidations.invalidPhoneNo"))
                .max(22, t("inputValidations.max22"))
          })}
          onSubmit={async (values) => {
            setIsLoading(true);
            await dispatch(editCurrentUser(values));

            setIsLoading(false);
            i18n.changeLanguage(values.lang);
          }}
          component={(props) => (
            <SettingsProfileForm {...props} isLoading={isLoading} />
          )}
        />
      )}
    </Box>
  );
}

let SettingsProfileForm: (props: ComponentProps) => JSX.Element = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  isLoading,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");

  function onFileChosen(e: any) {
    const reader = new FileReader();
    reader.onload = handleReaderLoad;
    reader.readAsDataURL(e.target.files[0]);

    function handleReaderLoad(e: any) {
      setFieldValue("files", base64_encode(e.target.result));
    }
  }
//dział i telefon
  return (
    <form onSubmit={handleSubmit}>
      <SettingsGroup title="Email">
        <TextField
          disabled
          fullWidth
          hiddenLabel
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SettingsGroup>

      <SettingsGroup title={t("settings.nameAndSurname")}>
        <TextField
          fullWidth
          error={!!errors.nameAndSurname && touched.nameAndSurname}
          helperText={touched.nameAndSurname ? errors.nameAndSurname : null}
          hiddenLabel
          placeholder={t("settings.nameAndSurname")}
          name="nameAndSurname"
          value={values.nameAndSurname}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SettingsGroup>

      <SettingsGroup title={t("settings.phone")}>
        <TextField
            fullWidth
            error={!!errors.phone && touched.phone}
            helperText={touched.phone ? errors.phone : null}
            hiddenLabel
            placeholder={t("settings.phone")}
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
        />
      </SettingsGroup>

      <SettingsGroup title={t("settings.department")}>
        <TextField
            fullWidth
            hiddenLabel
            name="department"
            value={values.department}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("settings.department")}
        />
      </SettingsGroup>

      <SettingsGroup title={t("settings.position")}>
        <TextField
          fullWidth
          hiddenLabel
          name="position"
          value={values.position}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("settings.position")}
        />
      </SettingsGroup>

      <SettingsGroup
          title={t("settings.photo")}
          description={t("settings.photoDescription")}
      >
        <div className={classes.SettingsImageWrap}>
          <div className={classes.SettingsImage}>
            {values.files === "" ? (
                <Avatar color="#2FB299" name={values.nameAndSurname} />
            ) : (
                <img src={base64_decode(values.files)} alt="profile avatar"></img>
            )}
          </div>
          <div>
            <button
                className={classes.SettingsImageButton}
                style={{ opacity: ".5" }}
                onClick={(e) => setFieldValue("files", "")}
            >
              {t("settings.delete")}
            </button>

            <label className={classes.SettingsImageButton}>
              <input
                  style={{ display: "none" }}
                  type="file"
                  id="files"
                  className="inputFile"
                  name="files[]"
                  onChange={(e) => onFileChosen(e)}
              />
              {t("settings.update")}
            </label>
          </div>
        </div>
      </SettingsGroup>

      <SettingsGroup title={t("settings.descriptionLabel")}>
        <TextField
          fullWidth
          hiddenLabel
          placeholder={t("settings.descriptionLabel")}
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          multiline
          rows={4}
        />
      </SettingsGroup>

      <SettingsGroup title={t("settings.appLanguage")}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="lang"
          value={values.lang}
          onChange={handleChange}
        >
          <FormControlLabel
            value="pl"
            control={<Radio />}
            label={t("settings.polish")}
          />
          <FormControlLabel
            value="en"
            control={<Radio />}
            label={t("settings.english")}
          />
        </RadioGroup>
      </SettingsGroup>

      <Box className={classes.ButtonWrap}>
        <ConfirmButton
          loading={isLoading}
          confirmButtonText={t("settings.save")}
        />
      </Box>
    </form>
  );
};
