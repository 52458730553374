import jwt_decode, { JwtPayload } from 'jwt-decode';

export const setToken = (token: string) => {
    localStorage.setItem("aiss-token", token);
};

export const getToken = () => {
    return localStorage.getItem("aiss-token");
};

export const deleteToken = () => {
    localStorage.removeItem("aiss-token");
}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const isTokenValid = () => {
    const token = localStorage.getItem("aiss-token");
    if (token) {
        const decodedToken: any = jwt_decode<JwtPayload>(token);
        return decodedToken.exp * 1000 >= new Date().getTime();
    }
    return false
}
