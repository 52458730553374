import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, MenuItem } from '@mui/material';
import { Tile } from '../../../components/Tile';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import { Box } from '@mui/system';
import area from '../../../assets/icons/area.svg';
import line from '../../../assets/icons/line.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Status from '../../../components/Status';
import { fetchCameraInOutBoundaries, fetchCameraRoi } from '../../../redux/reducers/camera';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import LiveFeed from '../../../components/LiveFeed';
import { useCamera } from '../CameraDetails';
import { Dropdown } from '../../../components/Dropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ComponentLoader from '../../../components/ComponentLoader';
import { DangerZoneForm } from '../components/Modals/dangerZoneForm';
import { DangerZoneDrawing } from '../components/Modals/dangerZoneDrawing';
import { InOutForm } from '../components/Modals/inOutForm';
import { InOutDrawing } from '../components/Modals/inOutDrawing';

function DangerZones() {

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isInOutModalActive, setIsInOutModalActive] = useState<boolean>(false);
  const [currentRoi, setCurrentRoi] = useState<number[][] | null>(null);
  const [currentLine, setCurrentLine] = useState<any>(undefined);
  const [isDrawingActive, setIsDrawingActive] = useState<boolean>(false);
  const [isInOutDrawingActive, setIsInOutDrawingActive] = useState<boolean>(false);
  const [areaType, setAreaType] = useState<string>('roi');

  const { camera, cameraFeedUrl, loading } = useCamera();
  const playerRef = useRef<HTMLInputElement>(null);
  const cameraRoi = useAppSelector((state) => state.camera.roi);
  const cameraInOutBoundaries = useAppSelector((state) => state.camera.inOutBoundaries);
  const { t } = useTranslation("common");
  const { width } = useWindowDimensions();

  useEffect(() => {
    if(camera) {
      dispatch(fetchCameraRoi(camera.id));
      dispatch(fetchCameraInOutBoundaries(camera.id));
    }
  }, [camera, dispatch])

  const handleAreaModal = (open: boolean, roi: number | null) => {
    setIsModalActive(open)
    setCurrentRoi(cameraRoi)
    closeAddDropdown()
  }

  const handleLineModal = (open: boolean, line: any, index?: number) => {
    if(line) {
      setCurrentLine({
        index: (index !== undefined) ? index : undefined,
        name: line.name,
        contour: line.in_out_boundary
      })
    } else {
      setCurrentLine({
        index: (index !== undefined) ? index : undefined,
        name: "" ,
        contour: undefined
      })
    }
    setIsInOutModalActive(open)
    closeAddDropdown()
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openAddDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeAddDropdown = () => {
    setAnchorEl(null);
  };

  return (
      <Grid container spacing={2}>
        <Grid item xs={width < 1200 ? 12 : 8}>
          <Tile>
            <div className={classes.TileHeader}>
              <h3>{t("safetyAreas.safetyAreas")}</h3>
            </div>
            <div className={classes.VideoWrap} ref={playerRef}>
            <LiveFeed noHeader video={cameraFeedUrl} loading={loading}/>
            </div>
          </Tile>
        </Grid>
        <Grid item xs={width < 1200 ? 12 : 4}>
          <Box className={classes.TileWrap}>
            <div className={classes.TileHeader}>
              <h3>{t("safetyAreas.createdAreas")}</h3>
              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  color="success"
                  disableElevation
                  onClick={openAddDropdown}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {t("safetyAreas.add")}
                </Button>
                <Dropdown
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={closeAddDropdown}
                >
                  <MenuItem onClick={() => handleAreaModal(true, 1)} disableRipple>
                    {t("safetyAreas.area")}
                  </MenuItem>
                  <MenuItem onClick={() => handleLineModal(true, undefined)} disableRipple>
                    {t("safetyAreas.line")}
                  </MenuItem>
                </Dropdown>
              </div>
            </div>
            <div>
              <Box className={classes.ToggleSwitch}>
                <div
                  className={areaType === 'roi' ? classes.FilterActive : ""}
                  onClick={() => setAreaType('roi')}
                >
                  {t("safetyAreas.areas")}
                </div>
                <div
                  className={areaType === 'inOut' ? classes.FilterActive : ""}
                  onClick={() => setAreaType('inOut')}
                >
                  {t("safetyAreas.lines")}
                </div>
              </Box>

              { areaType === 'roi' &&
              <>
                {/*todo handle multiple cameraRois if API provides*/}
                <ComponentLoader isLoading={!cameraRoi && cameraRoi !== undefined}>
                  {cameraRoi &&
                  <Box className={classes.AreaTile} onClick={() => handleAreaModal(true, 1)}>
                    <div className={classes.AreaTileHeader}>
                      <div className={classes.AreaTileHeaderIcon}>
                        <img src={area} alt=""/>
                      </div>
                      <div className={classes.AreaTileHeaderName}>
                        <span>Strefa 1</span>
                        <div>Lorem ipsum dolor sit</div>
                      </div>
                      <div className={classes.AreaTileHeaderOptions}>
                        <MoreVertIcon/>
                      </div>
                    </div>
                    <div className={classes.AreaTileInfo}>
                      <div>
                        <span>Status: </span>
                        <Status
                          dotColor="green"
                          text={t("safetyAreas.active")}
                        />
                      </div>
                      <div><span>{t("safetyAreas.peopleLimit")}: </span> 0</div>
                    </div>
                  </Box>
                  }

                </ComponentLoader>

                {cameraRoi === undefined &&
                <Box className={classes.AreaNoAreas}>
                  {t("safetyAreas.noAreasInfo")}
                </Box>
                }
              </>

              }

            {areaType === 'inOut' &&
              <>
                {cameraInOutBoundaries &&
                  <>
                    {cameraInOutBoundaries.map((item: any, index: number) => (
                      <Box className={classes.AreaTile} onClick={() => handleLineModal(true, item, index)}>
                        <div className={classes.AreaTileHeader}>
                          <div className={classes.AreaTileHeaderIcon}>
                            <img src={line} alt=""/>
                          </div>
                          <div className={classes.AreaTileHeaderName}>
                            <span>{t("safetyAreas.line")} {index + 1}</span>
                            <div>{item.name}</div>
                          </div>
                        </div>
                      </Box>
                    ))}
                  </>
                }

                {!cameraInOutBoundaries &&
                <Box className={classes.AreaNoAreas}>
                  {t("safetyAreas.noInOutInfo")}
                </Box>
                }
              </>
            }

            </div>
          </Box>
        </Grid>
        {isModalActive &&
        <DangerZoneForm setIsModalActive={setIsModalActive} setIsDrawingActive={setIsDrawingActive} currentRoi={cameraRoi}/>
        }

        {isDrawingActive &&
        <DangerZoneDrawing setIsModalActive={setIsDrawingActive} currentContour={currentRoi} setCurrentContour={setCurrentRoi}/>
        }

        {isInOutModalActive &&
        <InOutForm setIsModalActive={setIsInOutModalActive} setIsDrawingActive={setIsInOutDrawingActive} currentContour={currentLine} />
        }

        {isInOutDrawingActive &&
        <InOutDrawing setIsModalActive={setIsInOutDrawingActive} currentContour={currentLine} setCurrentContour={setCurrentLine}/>
        }
      </Grid>
  );
}

export default DangerZones;
