import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  ToggleWrap: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#F6F8FA',
    padding: '6px 16px 6px 6px',
    cursor: 'pointer',
    borderRadius: '40px',
    position: 'relative',
    '& span': {
      marginRight: '8px',
      display: 'block',
      height: '22px',
      width: '40px',
      borderRadius: '40px',
      position: 'relative',
      transition: '.3s',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '16px',
        width: '16px',
        backgroundColor: '#fff',
        top: '3px',
        left: '3px',
        borderRadius: '40px',
        transition: '.3s',
      }
    },
    '& input':{
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: '0',
      zIndex: '2',
      '&:after': {
        content: '""',
        display: "block",
        height: '100%',
        width: "100%",
        border: "2px solid red",
        cursor: "pointer",
      }
    },
  },
  InActive: {
    backgroundColor: '#C1CDDA',
    '&:after': {
      transform: 'translateX(0)',
    }
  },
  Active: {
    backgroundColor: '#00FFAA',
    '&:after': {
      transform: 'translateX(18px)',
    }
  }
}));
