import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import {useAppDispatch} from "../redux/hooks";
import { handleNewAlert } from '../redux/reducers/alerts';
import {getToken} from "../common/helpers";
import {REACT_APP_BACKEND_URL} from "../common/config";

const socket = io(
    `${REACT_APP_BACKEND_URL}`,
    {
        transports: ['websocket', 'polling', 'flashsocket'],
        auth: {
            token: getToken()
        },
    },
)

export default function UseWebSocket() {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const dispatch = useAppDispatch();

    useEffect(() => {
        socket.on('connect', () => {
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
        });

        socket.on('notify', (data) => {
            dispatch(handleNewAlert(data));
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
        };
    }, []);
}
