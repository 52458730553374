import React, { useEffect } from 'react';
import {fetchAlertsList} from "../../redux/reducers/alerts";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useTranslation} from "react-i18next";
import { Outlet,  useOutletContext } from 'react-router-dom';
import {PageTitleBar} from '../../components/PageTitleBar';
import {Box} from "@mui/material";
import MockWrapper from '../../components/MockWrapper';
import { useStyles } from './styles';

export function Alerts(): JSX.Element {
    const alerts = useAppSelector((state) => state.alerts.list);
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchAlertsList());
    },[])

    return (
      <Box sx={{ p: 2, pt: 4 }}>
        <Box sx={{ p: 4, pt: 0 }}>
          <PageTitleBar title={t("alerts.heading")} />
        </Box>
        {/*<Box className={classes.AlertsFilters}>*/}
        {/*  <AlertsFilters />*/}
        {/*</Box>*/}
        {/*<AlertsNav />*/}
        <MockWrapper>
          <Outlet context={{alerts}}/>
        </MockWrapper>
      </Box>
    );
}

type ContextType = {
  alerts: any | null;
};

export function useAlert() {
  return useOutletContext<ContextType>();
}
