import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import theme from "./theme";
import Routing from "./components/Routing";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from './redux/hooks';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import UseWebSocket from "./hooks/useWebSockets";
import AlertNotification from "./components/AlertNotification";
import { useLocation, useNavigate } from 'react-router-dom';
import { isTokenValid } from './common/helpers';
import { ToastContainer } from 'react-toastify';
import { auth, logoutUser, notAuthenticated } from './redux/reducers/auth';

function App(): JSX.Element {
  const { i18n } = useTranslation("common");
  const currentLang = useAppSelector((state) => state.user.systemLang);
  const {loggedIn} = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(currentLang !== i18n.language) {
      i18n.changeLanguage(currentLang);
    }
  }, [])

  useEffect(() => {
    if (!isTokenValid()) {
      dispatch(auth.actions.notAuthenticated(null))
      if(location.pathname !== '/login' && location.pathname !== '/login/forgot-password' && location.pathname !== '/reset-password') {
        navigate('/login', {replace: true})
      }
    }
  }, [location]);

  UseWebSocket();

  return (
    <ThemeProvider theme={theme}>
      {loggedIn &&
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      }
      <CssBaseline />
      <AlertNotification/>
      <Routing />
    </ThemeProvider>
  );
}
export default App;
