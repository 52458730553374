import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  MockIndicator: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: '10',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255,122,9,.35)',
      border: '1px solid #fb7703',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    }
  }
}));
