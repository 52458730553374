import React, {useState} from "react";
import {useStyles} from "./style";
import logoLong from "../../assets/logo/logo-aiss-long.png";
import SettingsGroup from "../../components/SettingsGroup";
import {Box, FormHelperText, IconButton, InputAdornment, OutlinedInput,} from '@mui/material';
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Formik, FormikProps} from "formik";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import ConfirmButton from "../../components/ConfirmButton";
import {useNavigate} from 'react-router-dom';
import CustomAlert from '../../components/CustomAlert';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {loginUser} from "../../redux/reducers/auth";
import {fetchCurrentUser, fetchCurrentUserGroups, saveCurrentInstance, saveUserRole} from "../../redux/reducers/user";
import {fetchGroupsList} from "../../redux/reducers/groups";

interface IFormValues {
  password: string;
  username: string;
}

interface ComponentProps extends FormikProps<IFormValues> {
  isLoading: boolean;
}

export function LogIn(): JSX.Element {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const authorization = useAppSelector((state) => state.auth)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate()

    const handleErrorMessage = (error: string) => {
        switch (error) {
            case "LOGIN_BAD_CREDENTIALS":
                return t("logIn.loginErrorCredentials");
            case "LOGIN_USER_NOT_VERIFIED":
                return t("logIn.loginErrorNotVerified");
            case "LOGIN_USER_NOT_ACTIVE":
                return t("toastInfo.userNotActive");
            default:
                return t("logIn.loginErrorUnknown");
        }
    }

  return (
    <div className={classes.LogInPageWrapper}>
      <Box className={classes.LogInModalWrapper}>
        <Box className={classes.LogoLongWrap}>
          <img className={classes.LogoLong} src={logoLong} alt="logo" />
        </Box>
        <h3 className={classes.LogInTitle}>{t("logIn.logIn")}</h3>
        <p className={classes.LogInDescription}>
          {t("logIn.logInDescription")}
        </p>

        {authorization?.error &&
            <CustomAlert severity="error" title={t("logIn.loginError")}>
                {handleErrorMessage(authorization.error)}
            </CustomAlert>
        }

        <Formik<IFormValues>
          initialValues={{
            password: "",
            username: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string()
              .email(t("logIn.emailValidation"))
              .required(t("logIn.requiredValidation")),
            password: Yup.string()
              .required(t("logIn.requiredValidation")),
          })}
          onSubmit={async (values) => {
              localStorage.removeItem('persist:user');
              setIsLoading(true)
              const isAuth = await dispatch(loginUser(values));
              const redirect = (groups: any) => {
                  if (groups.length === 1) {
                      dispatch(saveCurrentInstance(groups[0]));
                      navigate('/', {replace: true});
                  } else {
                      navigate('/instances', {replace: true});
                  }
              }
              if (isAuth) {
                  const currentUser = await dispatch(fetchCurrentUser());
                  if (currentUser?.is_superuser) {
                      dispatch(saveUserRole('GLOBAL_ADMIN'));
                      const groups = await dispatch(fetchGroupsList());
                      redirect(groups);
                  } else if (currentUser?.is_superuser === false) {
                      const groups = await dispatch(fetchCurrentUserGroups());
                      redirect(groups);
                  }
              }
              setIsLoading(false)
          }}
          component={(props) => <LogInForm {...props} isLoading={isLoading} />}
        />
      </Box>
    </div>
  );
}

let LogInForm: (props: ComponentProps) => JSX.Element = ({
                                                           handleSubmit,
                                                           values,
                                                           errors,
                                                           touched,
                                                           handleChange,
                                                           handleBlur,
                                                           isLoading,
                                                         }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <SettingsGroup type="modal" title={t("logIn.login")} noBorder>
        <OutlinedInput
          fullWidth
          name="username"
          type="text"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("logIn.enterLogin")}
          style={{ marginBottom: "10px" }}
          error={!!errors.username && touched.username}
        />
        {touched.username && errors.username &&
        <div className={classes.InputError}>
          {errors.username}
        </div>
        }
      </SettingsGroup>

      <SettingsGroup type="modal" title={t("logIn.password")} noBorder>
        <OutlinedInput
          fullWidth
          name="password"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("logIn.enterPassword")}
          error={!!errors.password && touched.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {touched.password && errors.password &&
        <div className={classes.InputError}>
          {errors.password}
        </div>
        }
      </SettingsGroup>
      <br/>
      <ConfirmButton
        confirmButtonText={t("logIn.logIn")}
        width="100%"
        height="50px"
        loading={isLoading}
      />
      <FormHelperText
        id="password-helper-text"
      >
        <button
          onClick={() => navigate(`/login/forgot-password`)}
          className={classes.ForgetPasswordButton}
        >
          {t("logIn.forgetPassword")}
        </button>
      </FormHelperText>
    </form>
  );
};
